import { useState, useMemo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Pagination,
  IconButton,
  Snackbar,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  getAdjustmentStock,
  getAdjustmentDetail,
} from "../../../../services/InventoryAPI";

import DetailAdjustmentModal from "./DetailAdjustmentModal";
import BreadcrumbInventory from "../BreadcrumbInventory";

const FETCH_LIMIT = 10;

const toDate = (date) => moment(date).format("DD/MM/YY");
const toApiDate = (date) => moment(date).format("YYYY-MM-DD");

const StockAdjustment = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [status, setStatus] = useState(0);
  const [isDetailAdjustmentModalOpen, setIsDetailAdjustmentModalOpen] =
    useState(false);
  const [productId, setProductId] = useState(0);
  const [paId, setPaId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [period, setPeriod] = useState(0);
  const [periodBuffer, setPeriodBuffer] = useState(0);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);

  const {
    data: adjustmentStockData,
    isLoading: isStockAdjustmentStockLoading,
    refetch: refetchAdjustmentStockData,
  } = useQuery({
    queryKey: ["stock-adjustment", offset, status, period],
    queryFn: () =>
      getAdjustmentStock(
        offset,
        FETCH_LIMIT,
        period,
        toApiDate(startDate) === "Invalid date" ? "" : toApiDate(startDate),
        toApiDate(endDate) === "Invalid date" ? "" : toApiDate(endDate),
        status,
        isWarehouse ? isWarehouse : false
      ),
    enabled: period === 7 || startDate === "" || endDate === "",
  });

  const { data: adjustmentStockDetail } = useQuery({
    queryKey: ["stock-adjustment-detail", productId, paId],
    queryFn: () =>
      getAdjustmentDetail(paId, productId, isWarehouse ? isWarehouse : false),
    enabled: paId !== "" && productId !== 0,
  });

  const adjustmentTypeConvert = (typeAdjustment) => {
    if (typeAdjustment === 1) return "Stock Rusak";
    else if (typeAdjustment === 2) return "Stock Hilang";
    else if (typeAdjustment === 3) return "Stock Expired";
  };

  const pageCount = useMemo(() => {
    if (adjustmentStockData && adjustmentStockData !== undefined)
      return Math.ceil(adjustmentStockData.count / FETCH_LIMIT);
    else return 0;
  }, [adjustmentStockData]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <BreadcrumbInventory isWarehouse={isWarehouse} />
        <div>
          <button
            className="bg-[#007AF1] font-bold py-2 px-4 rounded-md text-white"
            onClick={() =>
              navigate(
                `${
                  isWarehouse ? "/central-warehouse" : ""
                }/inventory/stock-adjustment/create-adjustment`
              )
            }
          >
            Buat Penyesuaian Baru
          </button>
        </div>
      </div>
      {isStockAdjustmentStockLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full my-3 ">
            <FormControl fullWidth sx={{ marginRight: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <MenuItem value={0}>Semua Status</MenuItem>
                <MenuItem value={1}>Stock Rusak</MenuItem>
                <MenuItem value={2}>Stock Hilang</MenuItem>
                <MenuItem value={3}>Stock Expired</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Periode</InputLabel>
              <Select
                label="Periode"
                value={periodBuffer}
                onChange={(e) => {
                  if (e.target.value === 7) {
                    setIsDateModalOpen((prevBool) => !prevBool);
                  } else if (e.target.value !== 7) {
                    setIsDateModalOpen(false);
                    setStartDate("");
                    setEndDate("");
                  } else if (e.target.value === 10) {
                    return;
                  }
                  setPeriod(e.target.value);
                  setPeriodBuffer(e.target.value);
                }}
              >
                <MenuItem value={0}>Semua Tanggal</MenuItem>
                <MenuItem value={1}>Minggu Berjalan</MenuItem>
                <MenuItem value={2}>Minggu Lalu</MenuItem>
                <MenuItem value={3}>Bulan Berjalan</MenuItem>
                <MenuItem value={4}>Bulan Lalu</MenuItem>
                <MenuItem value={5}>Tahun Berjalan</MenuItem>
                <MenuItem value={6}>Tahun Lalu</MenuItem>
                <MenuItem value={7}>Pilih Tanggal</MenuItem>
                <MenuItem value={8}>Hari ini</MenuItem>
                <MenuItem value={9}>Kemarin</MenuItem>
                <MenuItem value={10} sx={{ display: "none" }} disabled>
                  {toDate(startDate) + " - " + toDate(endDate)}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {adjustmentStockData &&
          adjustmentStockData !== undefined &&
          adjustmentStockData?.data !== null ? (
            <div>
              <TableContainer component={Paper} fullWidth>
                <Table aria-label="simple table">
                  <TableHead
                    sx={{
                      backgroundColor: "#007AF1",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        ID
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Tanggal Penyesuaian
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Nama Produk
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Total Awal Stock
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Total Adjustment Stock
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Total Stock Akhir
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adjustmentStockData.data.map((data, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ color: "#007AF1" }} align="center">
                          <p
                            className="hover:cursor-pointer hover:underline"
                            onClick={() => {
                              setIsDetailAdjustmentModalOpen(true);
                              setProductId(data.product_id);
                              setPaId(data.pa_id);
                            }}
                          >
                            {data.pa_id}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          {toDate(data.date_adjustment)}
                        </TableCell>
                        <TableCell align="center">
                          {data.product_name}
                        </TableCell>
                        <TableCell align="center">{data.adjustment}</TableCell>
                        <TableCell align="center">{data.total_stock}</TableCell>
                        <TableCell align="center">
                          {data.total_stock_adjustment}
                        </TableCell>
                        <TableCell align="center">
                          {data.total_stock_adjustment}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="flex justify-center mt-[10px]">
                <Pagination
                  count={pageCount}
                  page={offset}
                  onChange={(_, value) => setOffset(value)}
                  shape="rounded"
                  size="large"
                  color="primary"
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center w-full mt-3">
              <p>Tidak ditemukan</p>
            </div>
          )}
        </>
      )}
      <Dialog
        open={isDetailAdjustmentModalOpen}
        onClose={() => {
          setIsDetailAdjustmentModalOpen(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <p>Adjustment Stock Detail</p>
          <p
            className="hover:cursor-pointer"
            onClick={() => {
              setIsDetailAdjustmentModalOpen(false);
            }}
          >
            X
          </p>
        </DialogTitle>
        <DialogContent>
          <DetailAdjustmentModal
            adjustmentStockDetail={adjustmentStockDetail}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDateModalOpen}
        onClose={() => {
          setIsDateModalOpen(false);
          setPeriodBuffer(0);
          setPeriod(0);
        }}
      >
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <DatePicker
            className="border-2 border rounded-md p-2"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(dates) => {
              const [start, end] = dates;
              setStartDate(start);
              setEndDate(end);
            }}
            inline
          />
          <button
            className="bg-red-500 text-white rounded-md p-2"
            onClick={() => {
              refetchAdjustmentStockData();
              setIsDateModalOpen(false);
              setPeriodBuffer(10);
            }}
          >
            OK
          </button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={3000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default StockAdjustment;
