import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  TextField,
  InputAdornment,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import { RxDoubleArrowDown, RxDoubleArrowUp } from "react-icons/rx";
import { FaHandHoldingUsd } from "react-icons/fa";
import { BiDollarCircle } from "react-icons/bi";
import { SlArrowRight } from "react-icons/sl";
import { FiBell } from "react-icons/fi";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import Trash from "../../../assets/icons/trash.svg";
import {
  // fetchData,
  fetchMultipleOutlet,
  getTransactionStatistics,
  getTransactionStatisticsNew,
  getMultipleOutlet,
  createDisbursment,
  sendOtpDisbursment,
} from "../../../services/dashboardApi";
import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CircularLoading from "../../common/particles.jsx/CircularLoading";
import DatePicker from "react-datepicker";
import NotificationAlertStatusCode from "./NotificationAlertStatusCode";
import ModalSIPA from "../../common/particles.jsx/ModalSIPA";
import ModalSIA from "../../common/particles.jsx/ModalSIA";
import { getUserProfile } from "../../../services/authApi";
import { checkStatusByEmail } from "../../../services/authApi";
import NotificationAlertStatusUserCode from "./NotificationAlertStatusUserCode";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";

const toApiDate = (date) => moment(date).format("YYYY-MM-DD");
const toDate = (date) => moment(date).format("DD-MM-YYYY");

const FETCH_LIMIT = 10;

const Dashboard = () => {
  const navigate = useNavigate();
  const name = localStorage.getItem("name");
  const company_id = localStorage.getItem("company_id");
  const titleId = localStorage.getItem("title");
  const queryClient = useQueryClient();
  const [metode, setMetode] = useState(3);
  const [outlet, setOutlet] = useState(
    Number(company_id) !== null ? [Number(company_id)] : []
  );
  const [outletBuffer, setOutletBuffer] = useState(
    Number(company_id) !== null ? [Number(company_id)] : 0
  );
  const [companyName, setCompanyName] = useState("");
  const [periode, setPeriode] = useState(8);
  const [periodSales, setPeriodSales] = useState(8);
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [tanggalAkhir, setTanggalAkhir] = useState("");
  const [openSIA, setOpenSIA] = useState(false);
  const [apiMessage, setApiMessage] = useState("");

  const [openSIPA, setOpenSIPA] = useState(false);
  const [openDisburstmentModal, setOpenDisburstmentModal] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [createDisbursmentBody, setCreateBodyDisbursmentBody] = useState({
    total_amount: 0,
    otp: "",
  });

  const [date, setDate] = useState(false);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
  );

  const {
    mutate,
    data: penjualan,
    isLoading: isDashboardLoading,
  } = useMutation({
    mutationFn: fetchMultipleOutlet,
    onSuccess: () => {
      queryClient.invalidateQueries("dashboard", tanggalMulai, tanggalAkhir);
    },
  });

  const { data: profile } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getUserProfile(),
  });

  const { data: multipleOutlet } = useQuery({
    queryKey: ["multiple-outlet", companyName],
    queryFn: () => getMultipleOutlet(companyName, FETCH_LIMIT, 1),
    // onSuccess: (outlets) =>
    //   setOutlet(outlets.data.map((data) => data.company_id)),
  });

  // const { data: transactionStats } = useQuery({
  //   queryKey: ["transaction-stats"],
  //   queryFn: () => getTransactionStatistics(),
  // });

  const { data: transactionStats } = useQuery({
    queryKey: ["transaction-stats"],
    queryFn: () => getTransactionStatisticsNew(),
  });

  const {
    mutate: mutateCreateDisburstment,
    isLoading: isCreateDisbursmentLoading,
  } = useMutation({
    mutationFn: createDisbursment,
    onSuccess: (data) => {
      setApiMessage(data.message.id);
      setOpenDisburstmentModal(false);
      setCreateBodyDisbursmentBody({
        total_amount: 0,
        otp: "",
      });
    },
    onError: (err) => setApiMessage(err.message.id),
  });

  const {
    mutate: mutateSendOtpDisburstment,
    data: otpDisbursmentData,
    isLoading: isOtpDisbursmentLoading,
  } = useMutation({
    mutationFn: sendOtpDisbursment,
    onSuccess: () => setOpenDisburstmentModal(true),
  });

  const { mutate: mutateCheckStatusByEmail, data: statusUser } = useMutation({
    mutationFn: checkStatusByEmail,
  });

  let offlineData = penjualan?.chart?.offline_order
    ? penjualan?.chart?.offline_order.map((data) => data.total_order)
    : [];
  let onlineData = [];
  let offlineLabels = penjualan?.chart?.offline_order
    ? penjualan?.chart?.offline_order.map((data) => data.date)
    : [];
  let onlineLabels = [];

  if (penjualan?.chart?.online_order) {
    onlineData = penjualan?.chart?.online_order
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((data) => data.total_order);
    onlineLabels = penjualan?.chart?.online_order.map((data) => data.date);
  }

  const labels = [].concat(onlineLabels, offlineLabels);

  const datas = {
    labels: labels,

    datasets: [
      {
        label: "Penjualan Offline",
        data: offlineData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const onChange = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    },
    [startDate, endDate]
  );

  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
  };

  useEffect(() => {
    if (profile === undefined) return;
    mutateCheckStatusByEmail({ email: profile?.email });
  }, [profile]);

  useEffect(() => {
    mutate({
      periode_sales: periode,
      method_sales: metode,
      start_date: tanggalMulai,
      end_date: tanggalAkhir,
      company_id:
        outlet.length === 0
          ? multipleOutlet?.data?.map((data) => data.company_id)
          : outlet,
    });
  }, [metode, periode, tanggalMulai, tanggalAkhir, outlet, multipleOutlet]);

  useEffect(() => {
    if (
      toApiDate(startDate) === "Invalid date" ||
      toApiDate(endDate) === "Invalid date"
    ) {
      setTanggalMulai("");
      setTanggalAkhir("");
    } else {
      setTanggalMulai(toApiDate(startDate));
      setTanggalAkhir(toApiDate(endDate));
    }
  }, [startDate, endDate]);

  if (isDashboardLoading) {
    return (
      <div>
        <CircularLoading />
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-16 lg:mt-0">
      <LoadingBackdrop isLoading={isOtpDisbursmentLoading} />
      <ModalSIA open={openSIA} setOpen={setOpenSIA} />
      <ModalSIPA open={openSIPA} setOpen={setOpenSIPA} />
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex flex-col text-[14px] lg:text-base">
          <p className="font-semibold">
            Hi,
            <span className="font-semibold"> {name}</span>
          </p>
          <p>Welcome to Your OLIN dashboard</p>
        </div>
        <FiBell className="text-[18px] lg:text-[30px] hover:bg-sky-200 hover:rounded-full hover:p-1" />
      </div>
      {statusUser !== undefined ? (
        <>
          <NotificationAlertStatusUserCode
            statusUserCode={statusUser.status_user_code}
          />
          <NotificationAlertStatusCode
            statusCode={statusUser.status_code}
            openSIA={openSIA}
            setOpenSIA={setOpenSIA}
            openSIPA={openSIPA}
            setOpenSIPA={setOpenSIPA}
          />
        </>
      ) : null}
      {transactionStats && transactionStats !== undefined ? (
        <div className="bg-[#EBEDF180] w-full rounded-md mt-3 p-2 flex items-center justify-between ">
          <div className="flex items-center hover:cursor-pointer">
            <BiDollarCircle className="text-[#00CD52] text-[40px] mr-2" />
            <div>
              <p>Saldo Anda</p>
              {transactionStats.response_dashboard &&
              transactionStats.response_dashboard !== undefined ? (
                <div className="flex">
                  <p className="font-bold">
                    {transactionStats.response_dashboard.total_saldo.toLocaleString(
                      "id-ID",
                      {
                        style: "currency",
                        currency: "IDR",
                      }
                    )}
                  </p>
                  {/*transactionStats.response_dashboard.increasing !== 0 && (
                    <>
                      <RxDoubleArrowUp className="text-[#007AF1]" />
                      <p>
                        {transactionStats.response_dashboard.increasing}%
                        Peningkatan
                      </p>
                    </>
                  )*/}
                  {/*transactionStats.response_dashboard.decreasing !== 0 && (
                    <>
                      <RxDoubleArrowDown className="text-red-500" />
                      <p>
                        {transactionStats.response_dashboard.decreasing}%
                        Penurunan
                      </p>
                    </>
                  )*/}
                </div>
              ) : null}
              <p>
                Olin Point: {transactionStats.response_dashboard.loyalty_point}
              </p>
            </div>
            <button
              className="p-3 bg-blue-500 text-white font-white font-bold rounded-md ml-3"
              onClick={() => {
                mutateSendOtpDisburstment();
              }}
            >
              Tarik Saldo
            </button>
          </div>
          <div
            className="mr-5 hover:cursor-pointer p-3 bg-gray-300 rounded-md"
            onClick={() => navigate("/history-dashboard")}
          >
            <SlArrowRight className="text-[25px]" />
          </div>
        </div>
      ) : null}
      <div className="border shadow-md p-3 rounded-lg mt-5">
        <h1 className="text-blue-500 font-bold text-[20px]">Selling Report</h1>
        <hr className="border-black border-2 my-3" />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[12px] lg:gap-[234px]">
          <div className="flex items-center justify-around">
            <p className="font-bold">Metode Penjualan</p>
            <FormControl className="mt-3" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Semua Penjualan
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="metode"
                label="Semua Penjualan"
                value={metode}
                onChange={(e) => setMetode(e.target.value)}
              >
                <MenuItem value={3}>Semua Penjualan</MenuItem>
                <MenuItem value={1}>Order Online</MenuItem>
                <MenuItem value={2}>Order Offline</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="flex justify-between items-center gap-3">
            <p className="font-bold">Periode Penjualan</p>
            <FormControl className="mt-3" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Semua Periode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="periode"
                label="Semua Periode"
                value={periodSales}
                onChange={(e) => {
                  if (e.target.value === 7) {
                    setDate(!date);
                  } else if (e.target.value !== 7) {
                    setDate(false);
                    setStartDate(null);
                    setEndDate(null);
                  } else if (e.target.value === 10) {
                    return setPeriode(9);
                  }
                  setPeriode(e.target.value);
                  setPeriodSales(e.target.value);
                }}
              >
                <MenuItem value={8}>Hari ini</MenuItem>
                <MenuItem value={9}>Kemarin</MenuItem>
                <MenuItem value={1}>Minggu Berjalan</MenuItem>
                <MenuItem value={2}>Minggu Lalu</MenuItem>
                <MenuItem value={3}>Bulan Berjalan</MenuItem>
                <MenuItem value={4}>Bulan Lalu</MenuItem>
                <MenuItem value={5}>Tahun Berjalan</MenuItem>
                <MenuItem value={6}>Tahun Lalu</MenuItem>
                <MenuItem value={7}>Pilih Tanggal</MenuItem>
                <MenuItem value={10} disabled sx={{ display: "none" }}>
                  {toDate(tanggalMulai) + " - " + toDate(tanggalAkhir)}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {Number(titleId) === 1 ? (
          <div className="flex items-center justify-around w-[40%]">
            <p className="font-bold mr-3">Outlet</p>
            <FormControl className="mt-3" fullWidth>
              <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="metode"
                label="Outlet"
                value={outletBuffer}
                onChange={(e) => setOutletBuffer(e.target.value)}
              >
                <MenuItem
                  value={0}
                  onClick={() => {
                    multipleOutlet && multipleOutlet !== undefined
                      ? setOutlet(
                          multipleOutlet.data?.map((data) => data.company_id)
                        )
                      : setOutlet([]);
                  }}
                >
                  Semua Outlet
                </MenuItem>
                {multipleOutlet && multipleOutlet !== undefined ? (
                  multipleOutlet.data?.map((data, index) => (
                    <MenuItem
                      value={data.company_id}
                      key={index}
                      onClick={() => setOutlet([data.company_id])}
                    >
                      {data.company_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    value={0}
                    disabled
                    sx={{ display: "none" }}
                  ></MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
        ) : null}

        <div className="grid grid-cols-1 lg:grid-cols-3 justify-center gap-4 mt-3 ">
          <div className="p-3 border rounded-lg">
            <p>Pendapatan</p>
            <p className="mt-4 font-bold text-[25px]">
              Rp{" "}
              {penjualan?.total_income?.total.toLocaleString("id-ID", {
                useGrouping: true,
              })}
            </p>

            <div className="flex flex-wrap gap-3 mt-2">
              {penjualan?.total_income?.decreasing >
              penjualan?.total_income?.increasing ? (
                <div className="flex flex-row items-center ">
                  <RxDoubleArrowDown className="text-red-500" />
                  <p>
                    {Math.floor(penjualan?.total_income?.decreasing)}% Penurunan
                  </p>
                </div>
              ) : (
                <div className="flex flex-row items-center ">
                  <RxDoubleArrowUp className="text-green-500" />
                  <p>
                    {Math.floor(penjualan?.total_income?.increasing)}%
                    Peningkatan
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="p-3 border rounded-lg">
            <p>Rata - rata per transaksi</p>
            <p className="mt-4 font-bold text-[25px]">
              Rp{" "}
              {penjualan?.average_transaction?.total === null || undefined
                ? 0
                : penjualan?.average_transaction?.total.toLocaleString(
                    "id-ID",
                    { useGrouping: true }
                  )}
            </p>

            <div className="flex flex-wrap gap-3 mt-2">
              {penjualan?.average_transaction?.decreasing >
              penjualan?.average_transaction?.increasing ? (
                <div className="flex flex-row items-center ">
                  <RxDoubleArrowDown className="text-red-500" />
                  <p>
                    {Math.floor(penjualan?.average_transaction?.decreasing)}%
                    Penurunan
                  </p>
                </div>
              ) : (
                <div className="flex flex-row items-center ">
                  <RxDoubleArrowUp className="text-green-500" />
                  <p>
                    {Math.floor(penjualan?.average_transaction?.increasing)}%
                    Peningkatan
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="p-3 border rounded-lg">
            <p>Transaksi</p>
            <p className="mt-4 font-bold text-[25px]">{`${
              penjualan?.count_transaction?.total === undefined
                ? "Tidak ada Transaksi"
                : penjualan?.count_transaction?.total.toLocaleString("id-ID", {
                    useGrouping: true,
                  })
            }`}</p>

            <div className="flex flex-wrap gap-3 mt-2">
              {penjualan?.count_transaction?.decreasing >
              penjualan?.count_transaction?.increasing ? (
                <div className="flex flex-row items-center ">
                  <RxDoubleArrowDown className="text-red-500" />
                  <p>
                    {Math.floor(penjualan?.count_transaction?.decreasing)}%
                    Penurunan
                  </p>
                </div>
              ) : (
                <div className="flex flex-row items-center ">
                  <RxDoubleArrowUp className="text-green-500" />
                  <p>
                    {Math.floor(penjualan?.count_transaction?.increasing)}%
                    Peningkatan
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* <div className="p-3 border rounded-lg flex justify-around items-center">
            <FaReceipt className="text-green-600 text-[50px]" />
            <div className="flex flex-col gap-3 font-bold">
              <p className="">Online</p>
              <p>50</p>
            </div>
          </div> */}

          {/* <div className="p-3 border rounded-lg flex justify-around items-center">
            <FaHandHoldingUsd className="text-blue-600 text-[50px]" />
            <div className="flex flex-col gap-3 font-bold">
              <p className="">Offline</p>
              <p>{penjualan?.sales_total?.offline_order}</p>
            </div>
          </div> */}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 items-start justify-evenly gap-3">
          <div className="border shadow-md rounded-lg mt-3 col-span-1 lg:col-span-3 ">
            <h1 className="font-bold text-[20px] text-center mt-5">
              Grafik Penjualan
            </h1>
            <div className="col-span-1 lg:col-span-3 overflow-auto p-2 ">
              <div className="w-[100vh] lg:w-full h-full">
                <Line data={datas} options={options} />
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-3 justify-between gap-3">
            <div className="p-3 border rounded-lg flex justify-between">
              <FaHandHoldingUsd className="text-blue-600 text-[60px]" />
              <div className="flex flex-col gap-3 font-bold">
                <p className="">Offline</p>
                <p>{penjualan?.sales_total?.offline_order}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openDisburstmentModal}
        onClose={() => setOpenDisburstmentModal(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <div className="flex flex-row justify-between p-6">
            <p>Pencairan Saldo</p>
            <p
              className="text-gray-500 text-[20px] cursor-pointer"
              onClick={() => setOpenDisburstmentModal(false)}
            >
              X
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          {otpDisbursmentData && otpDisbursmentData !== undefined ? (
            <div>
              <p>Rekening penarikan</p>
              <div className="flex justify-between my-3">
                <div>
                  <p>Nama</p>
                  <p>Nomor Rekening</p>
                  <p>Nama Bank</p>
                </div>
                <div>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>
                <div>
                  <p>{otpDisbursmentData.name}</p>
                  <p>{otpDisbursmentData.nomor_rekening}</p>
                  <p>{otpDisbursmentData.name_bank}</p>
                </div>
              </div>
              <TextField
                label="Jumlah Pencairan"
                fullWidth
                value={createDisbursmentBody.total_amount}
                onChange={(e) => {
                  let numericValue = e.target.value;
                  if (numericValue === "") {
                    numericValue = "0";
                  }
                  setCreateBodyDisbursmentBody((prevValue) => ({
                    ...prevValue,
                    total_amount: parseInt(
                      numericValue.replace(/[^0-9]/g, ""),
                      10
                    ),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp. </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="OTP"
                fullWidth
                sx={{ marginY: 2 }}
                value={createDisbursmentBody.otp}
                inputProps={{ maxLength: 4 }}
                onChange={(e) => {
                  setCreateBodyDisbursmentBody((prevValue) => ({
                    ...prevValue,
                    otp: e.target.value,
                  }));
                }}
              />
              {isCreateDisbursmentLoading ? (
                <button
                  className="w-full bg-blue-500 p-3 rounded-md text-white font-bold disabled:bg-gray-300"
                  disabled
                >
                  Loading
                </button>
              ) : (
                <button
                  className="w-full bg-blue-500 p-3 rounded-md text-white font-bold disabled:bg-gray-300"
                  disabled={
                    createDisbursmentBody.otp.length < 4 ||
                    createDisbursmentBody.total_amount < 1000
                  }
                  onClick={() =>
                    mutateCreateDisburstment(createDisbursmentBody)
                  }
                >
                  Kirim permintaan pencairan
                </button>
              )}
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
      <Dialog
        open={date}
        onClose={() => {
          setDate(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold"
            onClick={() => {
              setDate(false);
              setPeriodSales(10);
            }}
          >
            ok
          </button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(apiMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setApiMessage("");
        }}
        message={apiMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setApiMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default Dashboard;
