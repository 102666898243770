import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getNewPoDetail } from "../../../services/billingAPI";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { MdCancel } from "react-icons/md";
import { deleteProductPO } from "../../../services/purchaseOrderApi";
import { BsTrashFill } from "react-icons/bs";
import ModalSaveBilling from "./modal/ModalSaveBilling";
import { IGNORE_TYPE, PPN_OPTION_INT } from "../../constants/constant";

const toDate = (date) => moment(date).format("YYYY-MM-DD");
const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const CreateBillingManual = () => {
  const navigate = useNavigate();
  const { id, flag } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [openSave, setOpenSave] = useState(false);
  const [reqBody, setReqBody] = useState({
    warehouse: false,
    po_id: 0,
    po_number: "",
    invoice: "",
    due_date: null,
    faktur_number: "",
    distribution: {
      name: "",
      address: "",
      phone: "",
    },
    total_price: 0,
    is_draft: true,
    is_partial: false,
    is_invoice: false,
    voucher: 0,
    product: [],
  });

  const handleDpp = (ppnVal) => {
    return ppnVal ? (ppnVal === 11 ? 11 : (11 / 12) * 12) : 0;
  };

  const handleChangeValue = (i, index, type, val) => {
    var tempProduct = [...reqBody.product];
    var hna = type === "hna" ? val : tempProduct[i].batch[index].hna;
    var discount =
      type === "discount" ? val : tempProduct[i].batch[index].discount;
    var ppn = handleDpp(type === "ppn" ? val : tempProduct[i].batch[index].ppn);

    const discPrice = hna * ((100 - discount) / 100);
    const finalPrice = discPrice * ((100 + ppn) / 100);

    // setting values
    tempProduct[i].batch[index][type] = val;
    tempProduct[i].batch[index].final_price = finalPrice;
    tempProduct[i].batch[index].sub_total =
      finalPrice * tempProduct[i].batch[index].quantity_received;
    if (type === "po_stock") tempProduct[i].po_stock = val;

    // grand total
    var sum = 0;
    tempProduct.map(
      (item) => (sum += item.batch.reduce((acc, num) => acc + num.sub_total, 0))
    );

    setReqBody((prevVal) => ({
      ...prevVal,
      voucher: prevVal.voucher > sum ? sum : prevVal.voucher,
      product: tempProduct,
      total: sum,
    }));
  };

  const handleAddBatch = (i) => {
    var tempProduct = [...reqBody.product];
    tempProduct[i].batch.push({
      batch_number: "",
      expired_date: moment().format("YYYY-MM-DD"),
      quantity_received: 0,
      hna: tempProduct[i].batch[0].hna,
      hna_compare: tempProduct[i].batch[0].hna_compare,
      packaging: tempProduct[i].batch[0].packaging,
      type_stock: tempProduct[i].batch[0].type_stock,
      discount: 0,
      ppn: 0,
      dpp: 0,
      final_price: 0,
      sub_total: 0,
    });
    setReqBody((prevVal) => ({
      ...prevVal,
      product: tempProduct,
    }));
  };

  const getGrandTotal = () => {
    var total = 0;
    reqBody.product.map(
      (item) =>
        (total += item.batch.reduce((acc, num) => acc + num.sub_total, 0))
    );

    return total;
  };

  const validateDetailUpdateStock = () => {
    let isValid = true;
    const filteredReq = [
      ...reqBody.product.filter((item) =>
        item.batch.every(
          (batch) => batch.batch_number !== "" || batch.quantity_received > 0
        )
      ),
    ];

    if (reqBody.invoice_number === "") {
      isValid = false;
      setErrorMessage("Nomor invoice tidak boleh kosong");
    }
    if (filteredReq.length === 0) {
      isValid = false;
      setErrorMessage("Minimal 1 batch harus diisi");
    }

    filteredReq.forEach((item) => {
      if (
        item.batch.some(
          (batch) =>
            batch.batch_number === "" ||
            batch.quantity_received === 0 ||
            batch.expired_date === ""
        )
      ) {
        isValid = false;
        setErrorMessage(
          "Nama, kuantitas, atau expired date batch tidak boleh kosong"
        );
      }
      //   if (
      //     item.actual_stock <= 0 &&
      //     item.batch?.some(
      //       (it) => it.number !== "" || it.stock !== 0 || it.expired_date !== null
      //     )
      //   ) {
      //     setErrorMessage("total kuantitas batch harus sesuai dengan stock");
      //     isValid = false;
      //   }
      //   if (
      //     item.batch?.some((it) => it.stock !== 0) &&
      //     item.batch?.filter((it) => it.stock !== 0).length > 0 &&
      //     item.batch
      //       .filter((it) => it.stock !== 0)
      //       .reduce((acc, curr) => {
      //         return acc + curr.stock;
      //       }, 0) > item.actual_stock
      //   ) {
      //     setErrorMessage("total kuantitas batch harus sesuai dengan stock");
      //     isValid = false;
      //   }
      // });
    });

    return isValid;
  };

  const findDpp = (ppn, hna, discount) => {
    const price = hna * ((100 - discount) / 100);
    return ppn ? (ppn === 11 ? price : ppn === 12 ? price * (11 / 12) : 0) : 0;
  };

  const {
    mutate: mutateFetchPoDetail,
    data,
    isLoading,
  } = useMutation({
    mutationFn: getNewPoDetail,
    onSuccess: (data) => {
      setReqBody({
        voucher: 0,
        is_draft: data.is_draft ? data.is_draft : false,
        is_partial: data.is_partial ? data.is_partial : false,
        po_id: Number(id),
        invoice_number: data.invoice_number,
        due_date: toDate(data.due_date),
        faktur_number: data.faktur_number,
        voucher: data.voucher,
        total: 0,
        product: (data.is_draft
          ? data.product
          : data.product.filter(
              (data) => data.po_stock - data.filled_po_stock > 0
            )
        ).map((data) => ({
          name: data.name,
          po_stock: data.po_stock - data.filled_po_stock,
          purchase_product_id: data.id,
          product_detail_id: data.product_detail_id,
          type_stock: data.type_stock,
          batch: data.po_batch
            ? data.po_batch.map((item) => ({
                batch_number: item.batch_number,
                expired_date: item.expired_date,
                quantity_received: item.stock,
                packaging: data.packaging,
                type_stock: data.type_stock,
                hna: item.unit_price,
                hna_compare: data.po_pharmacy_net_price_comparer,
                discount: item.discount,
                ppn: item.ppn_value,
                dpp: findDpp(item.ppn_value, item.unit_price, item.discount),
                final_price: item.net_price,
                sub_total: item.sub_total,
              }))
            : [
                {
                  batch_number: "",
                  expired_date: moment().format("YYYY-MM-DD"),
                  quantity_received: 0,
                  packaging: data.packaging,
                  type_stock: data.type_stock,
                  hna: data.po_pharmacy_net_price,
                  hna_compare: data.po_pharmacy_net_price_comparer,
                  discount: data.pharmacy_net_price_discount
                    ? data.pharmacy_net_price_discount
                    : 0,
                  ppn: data.pharmacy_net_price_ppn_value
                    ? data.pharmacy_net_price_ppn_value
                    : 0,
                  dpp: findDpp(
                    data.pharmacy_net_price_ppn_value,
                    data.po_pharmacy_net_price,
                    data.pharmacy_net_price_discount
                  ),
                  final_price: 0,
                  sub_total: 0,
                },
              ],
        })),
      });
    },
  });

  const { mutate: mutateDeleteBilling } = useMutation({
    mutationFn: deleteProductPO,
  });

  useEffect(() => {
    mutateFetchPoDetail({
      po_id: Number(id),
      is_partial: flag === "true" ? true : false,
      is_billing: true,
      is_draft: false,
      warehouse: false,
    });
  }, [mutateFetchPoDetail, id, flag]);

  return (
    <>
      <div className="text-[12px] p-3 pb-[70px]">
        {data && !isLoading ? (
          <div className="flex gap-2 items-center mb-4">
            <div className="w-full">
              <div className="w-full flex gap-2 items-center mb-2">
                <p className="w-1/3">Tanggal PO</p>
                <p className="w-full">
                  : {moment(data.created_at).format("DD MMMM YYYY")}
                </p>
              </div>
              <div className="w-full flex gap-2 items-center mb-2">
                <p className="w-1/3">No PO</p>
                <p className="w-full">: {data.po_number}</p>
              </div>
              <div className="w-full flex gap-2 items-center">
                <p className="w-1/3">Distributor</p>
                <p className="w-full">: {data.distribution.name}</p>
              </div>
            </div>
            <div className="w-full">
              <p>
                No. Invoice <span className="text-red-500">*</span>
              </p>
              <TextField
                fullWidth
                value={reqBody.invoice_number}
                onChange={(e) =>
                  setReqBody((prevVal) => ({
                    ...prevVal,
                    invoice_number: e.target.value,
                  }))
                }
              />
            </div>
            <div className="w-full">
              <p>
                Jatuh Tempo <span className="text-red-500">*</span>
              </p>
              <DesktopDatePicker
                views={["year", "month", "day"]}
                sx={{ width: "100%" }}
                value={moment(reqBody.due_date)}
                onChange={(newDate) =>
                  setReqBody((prevVal) => ({
                    ...prevVal,
                    due_date: newDate
                      ? moment(newDate).format("YYYY-MM-DD")
                      : "",
                  }))
                }
                renderInput={(params) => {
                  return <TextField fullWidth {...params} />;
                }}
              />
            </div>
            <div className="w-full">
              <p>No. Faktur Pajak</p>
              <TextField
                fullWidth
                value={reqBody.faktur_number}
                onChange={(e) =>
                  setReqBody((prevVal) => ({
                    ...prevVal,
                    faktur_number: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        ) : isLoading ? (
          <CircularProgress size={20} sx={{ marginBottom: 2 }} />
        ) : (
          <p className="mb-4">Data PO tidak ditemukan</p>
        )}

        <div className="flex w-full mb-4">
          <div className="w-full">
            <div className="block overflow-x-auto w-full rounded-md">
              <table className="w-full overflow-x-auto rounded-md border-collapse">
                <thead className="bg-blue-500 text-white">
                  <th className="p-2 text-center">No</th>
                  <th className="p-2 text-center whitespace-nowrap min-w-[150px] sticky left-0 z-1 bg-blue-500">
                    Produk
                  </th>
                  <th className="p-2 text-center whitespace-nowrap min-w-[150px]">
                    Kuantitas PO
                  </th>
                  <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                    Kuantitas diterima
                  </th>
                  <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                    Kemasan
                  </th>
                  <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                    Batch
                  </th>
                  <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                    Tanggal Expired
                  </th>
                  <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                    HNA Satuan
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Diskon (%)
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">PPN (%)</th>
                  <th className="p-2 text-center whitespace-nowrap">DPP</th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Harga Akhir
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Sub Total
                  </th>
                  <th className="p-2 text-center whitespace-nowrap"></th>
                </thead>
                <tbody className="border">
                  {data && !isLoading ? (
                    reqBody.product.map((item, i) =>
                      item.batch.map((batchItem, index) => (
                        <tr
                          className={`${
                            i === 0 && index === 0 ? "" : "border-t"
                          } `}
                        >
                          <td className="px-2 py-4 text-center align-top">
                            {index === 0 ? i + 1 : ""}
                          </td>
                          <td className="px-2 py-4 text-center text-nowrap whitespace-nowrap align-top sticky left-0 z-10 bg-white">
                            {index === 0 ? item.name : ""}
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            {index === 0 ? (
                              <TextField
                                fullWidth
                                value={item.po_stock}
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value)))
                                    handleChangeValue(
                                      i,
                                      index,
                                      "po_stock",
                                      Number(e.target.value)
                                    );
                                }}
                              />
                            ) : null}
                          </td>
                          <td className="px-2 py-4 text-left align-top">
                            <div>
                              <TextField
                                fullWidth
                                value={batchItem.quantity_received}
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value)))
                                    handleChangeValue(
                                      i,
                                      index,
                                      "quantity_received",
                                      Number(e.target.value)
                                    );
                                }}
                              />
                              {item.po_stock <
                                item.batch.reduce(
                                  (acc, num) => acc + num.quantity_received,
                                  0
                                ) && index === item.batch.length - 1 ? (
                                <p className="text-red-500 text-[10px]">
                                  Stok melebihi batas maksimal
                                </p>
                              ) : null}
                            </div>
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            {batchItem.type_stock === 0
                              ? batchItem.packaging.type_box
                              : batchItem.type_stock === 1
                              ? batchItem.packaging.type_strip
                              : batchItem.type_stock === 2
                              ? IGNORE_TYPE.includes(
                                  batchItem.packaging.type_unit
                                )
                              : batchItem.packaging.type_strip
                              ? batchItem.packaging.type_unit
                              : ""}
                          </td>
                          <td className="px-2 py-4 text-right align-top">
                            <div className="flex gap-2 items-center">
                              <div className="w-full">
                                <TextField
                                  fullWidth
                                  value={batchItem.batch_number}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      i,
                                      index,
                                      "batch_number",
                                      e.target.value
                                    )
                                  }
                                />
                                {index === item.batch.length - 1 ? (
                                  <p
                                    className={`mt-2 ${
                                      item.batch.reduce(
                                        (acc, num) =>
                                          acc + num.quantity_received,
                                        0
                                      ) < item.po_stock
                                        ? "text-blue-500 hover:underline cursor-pointer"
                                        : "text-gray-300 cursor-default"
                                    }`}
                                    onClick={() => {
                                      if (
                                        item.batch.reduce(
                                          (acc, num) =>
                                            acc + num.quantity_received,
                                          0
                                        ) < item.po_stock
                                      )
                                        handleAddBatch(i);
                                    }}
                                  >
                                    + Tambah Batch
                                  </p>
                                ) : null}
                              </div>
                              {index === 0 ? null : (
                                <MdCancel
                                  className="text-[24px] text-red-500 cursor-pointer hover:text-gray-300"
                                  onClick={() => {
                                    var tempProduct = [...reqBody.product];
                                    tempProduct[i].batch = [
                                      ...tempProduct[i].batch.slice(0, index),
                                      ...tempProduct[i].batch.slice(index + 1),
                                    ];
                                    setReqBody((prevVal) => ({
                                      ...prevVal,
                                      product: tempProduct,
                                    }));
                                  }}
                                />
                              )}
                            </div>
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <DesktopDatePicker
                              views={["year", "month", "day"]}
                              value={batchItem.expired_date}
                              onChange={(newExpiredDate) =>
                                handleChangeValue(
                                  i,
                                  index,
                                  "expired_date",
                                  newExpiredDate
                                    ? moment(newExpiredDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""
                                )
                              }
                              sx={{ width: "100%" }}
                              renderInput={(params) => {
                                return <TextField fullWidth {...params} />;
                              }}
                            />
                          </td>
                          <td className="px-2 py-4 text-left align-top">
                            <div>
                              <TextField
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      Rp
                                    </InputAdornment>
                                  ),
                                }}
                                value={batchItem.hna}
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value))) {
                                    handleChangeValue(
                                      i,
                                      index,
                                      "hna",
                                      Number(e.target.value)
                                    );
                                    handleChangeValue(
                                      i,
                                      index,
                                      "dpp",
                                      findDpp(
                                        batchItem.ppn,
                                        Number(e.target.value),
                                        batchItem.discount
                                      )
                                    );
                                  }
                                }}
                              />
                              {batchItem.hna > batchItem.hna_compare ? (
                                <p className="text-red-500 text-[10px]">
                                  Anda menaikan harga akhir, ada potensi
                                  kerugian
                                </p>
                              ) : null}
                            </div>
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <TextField
                              fullWidth
                              value={
                                batchItem.discount
                                  ? batchItem.discount.toString()
                                  : 0
                              }
                              type="number"
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              onChange={(e) => {
                                if (
                                  !isNaN(Number(e.target.value)) &&
                                  Number(e.target.value) < 100
                                ) {
                                  handleChangeValue(
                                    i,
                                    index,
                                    "discount",
                                    Number(e.target.value)
                                  );
                                  handleChangeValue(
                                    i,
                                    index,
                                    "dpp",
                                    findDpp(
                                      batchItem.ppn,
                                      batchItem.hna,
                                      batchItem.discount
                                    )
                                  );
                                }
                              }}
                            />
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <Autocomplete
                              disablePortal
                              fullWidth
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              options={PPN_OPTION_INT}
                              value={
                                batchItem.ppn !== 0
                                  ? PPN_OPTION_INT.find(
                                      (ppn) =>
                                        ppn.value ===
                                        (batchItem.ppn < 1
                                          ? batchItem.ppn * 100
                                          : batchItem.ppn)
                                    )
                                  : null
                              }
                              sx={{ backgroundColor: "white" }}
                              onChange={(_, inputValue) => {
                                handleChangeValue(
                                  i,
                                  index,
                                  "ppn",
                                  inputValue ? inputValue.value : 0
                                );
                                handleChangeValue(
                                  i,
                                  index,
                                  "dpp",
                                  findDpp(
                                    inputValue ? inputValue.value : 0,
                                    batchItem.hna,
                                    batchItem.discount
                                  )
                                );
                              }}
                              getOptionLabel={(option) =>
                                option ? option.name : "Pilih PPN"
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  placeholder="Pilih PPN"
                                />
                              )}
                            />
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <p className="mt-2">{toIDR(batchItem.dpp)}</p>
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <p className="mt-2">
                              {toIDR(batchItem.final_price)}
                            </p>
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <p className="mt-2">{toIDR(batchItem.sub_total)}</p>
                          </td>
                          <td className="p-4 text-center align-top">
                            {index === 0 ? (
                              <BsTrashFill
                                className="text-red-500 text-[18px] mt-2 cursor-pointer hover:text-gray-300"
                                onClick={() => {
                                  mutateDeleteBilling(
                                    item.purchase_product_id,
                                    false
                                  );
                                  setReqBody((prevVal) => ({
                                    ...prevVal,
                                    product: prevVal.product.filter(
                                      (data) =>
                                        data.purchase_product_id !==
                                        item.purchase_product_id
                                    ),
                                  }));
                                }}
                              />
                            ) : null}
                          </td>
                        </tr>
                      ))
                    )
                  ) : (
                    <tr>
                      <td className="border p-2 text-center" colSpan={13}>
                        {isLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          "Tidak ada produk"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="w-full flex items-end justify-end mb-8 pb-[60px]">
          <div className="flex gap-2 items-center mb-2">
            <p className="w-1/3">Voucher</p>
            <p>:</p>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
              }}
              type="number"
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              disabled={isLoading}
              value={reqBody.voucher?.toString()}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value))) {
                  setReqBody((prevVal) => ({
                    ...prevVal,
                    voucher:
                      Number(e.target.value) > getGrandTotal()
                        ? getGrandTotal()
                        : Number(e.target.value),
                  }));
                }
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={`bg-blue-500 fixed bottom-0 left-0 lg:left-[260px] min-h-[60px] px-[20px] py-[10px] w-full lg:w-[calc(100%-260px)] z-50`}
      >
        <div className="flex items-center justify-between items-center w-full">
          <div className="text-white">
            <p className="text-[14px]">Total</p>
            <p className="text-[18px] font-bold">
              {(getGrandTotal() - reqBody.voucher).toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
            </p>
          </div>
          <button
            type="button"
            disabled={
              reqBody.invoice_number === "" ||
              reqBody.product.every(
                (item) =>
                  !item.batch.every(
                    (batchItem) =>
                      batchItem.quantity_received > 0 &&
                      batchItem.batch_number !== ""
                  )
              )
            }
            onClick={() => {
              if (validateDetailUpdateStock()) {
                setOpenSave(true);
              } else {
                return;
              }
            }}
            className="rounded-xl text-[14px] disabled:bg-[#687B8E] text-white py-[10px] px-[42px] bg-[#FF8D40] hover:bg-orange-500"
          >
            Simpan
          </button>
        </div>
      </div>

      <ModalSaveBilling
        open={openSave}
        setOpen={setOpenSave}
        reqBody={reqBody}
      />

      <Snackbar
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default CreateBillingManual;
