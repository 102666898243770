import { CircularProgress, Switch } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FaChevronRight, FaTrash } from "react-icons/fa";
import { BsPencil } from "react-icons/bs";
import { MdBrokenImage } from "react-icons/md";
import { useState } from "react";
import ModalChangeMarketplace from "./modal/ModalChangeMarketplace";
import ModalAddMarketplace from "./modal/ModalAddMarketplace";
import ModalChangeSchedule from "./modal/ModalChangeSchedule";
import { getOMSSetting } from "../../../services/oms";
import ModalRemoveMarketplace from "./modal/ModalRemoveMarketplace";

const OMSSettings = () => {
  const [openChangeShift, setOpenChangeShift] = useState(false);
  const [openAddMarketplace, setOpenAddMarketplace] = useState(false);
  const [openChangeMarketplace, setOpenChangeMarketplace] = useState(false);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [openDelete, setOpenDelete] = useState(null);

  const {
    data: dataDetail,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["oms-setting"],
    queryFn: () => getOMSSetting(),
    refetchOnWindowFocus: false,
  });

  return (
    <div className="text-[12px]">
      <div className="w-full flex justify-between items-center mb-4 border-2 p-2 rounded-md">
        <p className="text-[14px]">Push Notification</p>
        <FaChevronRight className="text-blue-500 text-[24px]" />
      </div>
      <div className="w-full flex justify-between items-center mb-4">
        <div>
          <p className="text-[14px]">Silent Mode</p>
          <p>
            Saat memilih mode ini anda akan mendapatkan notifikasi tanpa suara
          </p>
        </div>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <Switch
            disabled={true} //{isFetching || !dataDetail}
          />
        )}
      </div>

      <div className="flex w-full mb-4">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">Hari</th>
                <th className="p-2 text-center">Jam Mulai</th>
                <th className="p-2 text-center">Jam Selesai</th>
                <th className="p-2 text-center w-[100px]">Edit</th>
              </thead>
              <tbody>
                {dataDetail && !isFetching ? (
                  dataDetail.operation.map((item, i) => (
                    <tr key={i} className="border">
                      <td className="p-2 text-center">{item.name}</td>
                      <td className="p-2 text-center">{item.time[0]}</td>
                      <td className="p-2 text-center">{item.time[1]}</td>
                      <td className="p-2 text-center w-[100px]">
                        <div className="w-full flex items-center justify-center">
                          <div
                            className="bg-[#FFE0A5] h-[24px] w-[24px] p-1 rounded-md cursor-pointer hover:bg-gray-300 text-black"
                            onClick={() => {
                              setSelectedSchedule(item.id);
                              setOpenChangeShift(true);
                            }}
                          >
                            <BsPencil className="w-full h-full" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border">
                    <td colSpan={4} className="p-2 text-center">
                      {isFetching ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada jadwal shift"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-center mb-4">
        <p className="text-[14px]">Marketplace</p>
        <button
          type="button"
          className="bg-blue-500 text-white p-2 hover:bg-gray-300 rounded-md"
          onClick={() => setOpenAddMarketplace(true)}
        >
          Tambah Marketplace
        </button>
      </div>

      {dataDetail && !isFetching && dataDetail.store?.length > 0 ? (
        dataDetail.store.map((item, i) => (
          <div
            className="flex items-center justify-between mb-2 border-2 rounded-md py-2 px-4"
            key={i}
          >
            <div className="flex items-center gap-4">
              <div className="min-w-[250px]">
                <p className="font-bold text-[14px]">{item.name}</p>
                <p>{item.store_name}</p>
              </div>
              <div className="w-[48px] h-[48px]">
                {item.store_logo === "" ? (
                  <MdBrokenImage className="w-full h-full" />
                ) : (
                  <img
                    src={item.store_logo}
                    className="w-full h-full object-contain"
                  />
                )}
              </div>
            </div>
            <div className="flex gap-2 items-center">
              {isFetching ? (
                <CircularProgress size={20} />
              ) : (
                <Switch
                  checked={item.is_active}
                  disabled={isFetching}
                  onChange={() => {
                    setOpenChangeMarketplace(true);
                    setSelectedMarketplace(item);
                  }}
                />
              )}
              <button
                type="button"
                className="h-[16px] w-[16px] text-red-500 hover:text-gray-300"
                onClick={() => setOpenDelete(item)}
              >
                <FaTrash className="w-full h-full" />
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex items-center justify-center">
          {isFetching ? (
            <CircularProgress size={20} />
          ) : (
            "Tidak ada marketplace"
          )}
        </div>
      )}

      <ModalChangeMarketplace
        open={openChangeMarketplace}
        setOpen={setOpenChangeMarketplace}
        marketplace={selectedMarketplace}
        setMarketplace={setSelectedMarketplace}
        refetch={() => refetch()}
      />

      <ModalAddMarketplace
        open={openAddMarketplace}
        setOpen={setOpenAddMarketplace}
        refetch={() => refetch()}
        blibliCode={
          dataDetail
            ? dataDetail.partner.find((item) => item.partner_code === "BLIBLI")
                .id
            : null
        }
      />

      <ModalRemoveMarketplace
        open={openDelete}
        setOpen={setOpenDelete}
        refetch={() => refetch()}
      />

      <ModalChangeSchedule
        open={openChangeShift}
        setOpen={setOpenChangeShift}
        refetch={() => refetch()}
        scheduleArr={dataDetail ? dataDetail.operation : []}
        selectedSchedule={selectedSchedule}
      />
    </div>
  );
};

export default OMSSettings;
