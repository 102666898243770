import {
  Box,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { MdWarningAmber } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { useEffect, useState } from "react";
import moment from "moment";
import { cloneDeep } from "lodash";
import { setScheduleOms } from "../../../../services/oms";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalChangeSchedule = ({
  open,
  setOpen,
  scheduleArr,
  selectedSchedule,
  refetch,
}) => {
  const [schedule, setSchedule] = useState(
    selectedSchedule ? selectedSchedule : 1
  );
  const [newScheduleArr, setNewScheduleArr] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTime = (type, time) => {
    var temp = [...newScheduleArr];
    if (type === "start") temp[schedule - 1].time[0] = time;
    if (type === "end") temp[schedule - 1].time[1] = time;
    setNewScheduleArr(temp);
  };

  const handleChangeSchedule = () => {
    mutateSchedule({
      schedule: newScheduleArr.map((item) => ({
        id: item.id,
        open_time: item.time[0],
        close_time: item.time[1],
      })),
    });
  };

  const { mutate: mutateSchedule, isLoading } = useMutation({
    mutationFn: setScheduleOms,
    onError: (err) => setErrorMessage(err.id),
    onSuccess: () => {
      setSuccessMessage("Berhasil mengubah shift");
      refetch();
      handleClose();
    },
  });

  useEffect(() => {
    setSchedule(selectedSchedule ? selectedSchedule : 1);
    setNewScheduleArr(cloneDeep(scheduleArr));
    setErrorMessage("");
    setSuccessMessage("");
  }, [selectedSchedule, scheduleArr, open]);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/2"
        >
          <div className="px-8 py-4 ">
            <div className="mb-4 w-full flex items-center justify-between">
              <div
                className={`w-[56px] h-[56px] rounded-full p-[8px] flex items-center justify-center bg-blue-100`}
              >
                <div
                  className={`w-full h-full rounded-full p-[8px] flex items-center justify-center bg-blue-300`}
                >
                  <MdWarningAmber className={`w-full h-full text-blue-700`} />
                </div>
              </div>
              <RxCross1
                className="cursor-pointer hover:text-red-500 w-fit"
                onClick={handleClose}
              />
            </div>

            <div className="mb-4">
              <p className="mb-2 font-bold text-[18px]">Detail Jadwal Shift</p>
              <p>Update jadwal shift Anda</p>
            </div>

            <div className="mb-4">
              <div className="flex gap-4 items-center mb-2">
                <p className="w-full font-bold basis-1/2">Hari</p>
                <Select
                  sx={{ width: "100%" }}
                  value={schedule}
                  onChange={(e) => {
                    setSchedule(e.target.value);
                    setNewScheduleArr(cloneDeep(scheduleArr));
                  }}
                >
                  <MenuItem value={1}>Senin</MenuItem>
                  <MenuItem value={2}>Selasa</MenuItem>
                  <MenuItem value={3}>Rabu</MenuItem>
                  <MenuItem value={4}>Kamis</MenuItem>
                  <MenuItem value={5}>Jumat</MenuItem>
                  <MenuItem value={6}>Sabtu</MenuItem>
                  <MenuItem value={7}>Minggu</MenuItem>
                </Select>
              </div>
              <div className="flex gap-4 items-center mb-2">
                <p className="w-full font-bold basis-1/2">Mulai</p>
                <DesktopTimePicker
                  onChange={(time) =>
                    handleChangeTime("start", moment(time).format("HH:mm"))
                  }
                  value={moment(
                    newScheduleArr?.find((item) => item.id === schedule)
                      ?.time[0],
                    "HH:mm"
                  )}
                  renderInput={(params) => {
                    return <TextField {...params} fullWidth />;
                  }}
                />
              </div>
              <div className="flex gap-4 items-center mb-2">
                <p className="w-full font-bold basis-1/2">Selesai</p>
                <DesktopTimePicker
                  onChange={(time) =>
                    handleChangeTime("end", moment(time).format("HH:mm"))
                  }
                  value={moment(
                    newScheduleArr?.find((item) => item.id === schedule)
                      ?.time[1],
                    "HH:mm"
                  )}
                  renderInput={(params) => {
                    return <TextField {...params} fullWidth />;
                  }}
                />
              </div>
            </div>

            <p className="text-red-500 mb-4">{errorMessage}</p>

            <div className="flex gap-2 items-center">
              <button
                className="w-full p-2 rounded-md border-2 disabled:bg-gray-300 hover:bg-gray-100"
                onClick={handleClose}
              >
                Batal
              </button>
              <button
                className={`w-full p-2 rounded-md border-none text-white disabled:bg-gray-300 bg-blue-500 hover:bg-gray-300`}
                onClick={handleChangeSchedule}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={10} /> : "Simpan"}
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <ErrorPopup
        errorMessage={successMessage}
        setErrorMessage={setSuccessMessage}
      />
    </div>
  );
};

export default ModalChangeSchedule;
