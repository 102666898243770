import moment from "moment";
import OlinLogo from "../../../../assets/icons/olinlogo.png";

const PrintCardStock = (props) => {
  const { printRef, dataArr, dataLabel } = props;

  return (
    <div ref={printRef} className="print-with-pagination">
      {dataArr.map((page) => {
        return (
          <div className="m-3 mt-0 text-[11px] page-break">
            <table className="w-full">
              <thead className="table-header-group mt-0 pt-0">
                <tr>
                  <td>
                    <div className="mb-[1cm]">
                      <div className="flex gap-[5mm] items-center justify-center w-full mr-[4cm] pr-[4cm]">
                        <div className="h-[2cm] w-[4cm]">
                          <img
                            src={
                              dataLabel && dataLabel.logo
                                ? dataLabel.logo
                                : OlinLogo
                            }
                            className="h-full w-full object-contain"
                          />
                        </div>
                        <p className="font-bold text-[32px] text-left w-fit">
                          {dataLabel ? dataLabel.name_outlet.split(" ")[0] : ""}
                          <br />
                          {dataLabel
                            ? dataLabel.name_outlet
                                .split(" ")
                                .slice(1)
                                .join(" ")
                            : ""}
                        </p>
                      </div>
                      <hr className="my-[5mm]" />
                      <p className="text-[24px] font-bold mb-2 text-center">
                        Kartu Stok
                      </p>

                      <div className="w-[20cm] my-0 mx-auto relative flex flex-col items-center">
                        <div className="w-[10cm]">
                          <p className="w-[3cm] inline-block">Nama obat</p>
                          <p className="w-[7cm] inline-block overflow-wrap text-ellipsis align-top">
                            : {page.product_name}
                          </p>
                        </div>
                        <div className="w-[10cm]">
                          <p className="w-[3cm] inline-block">Bentuk Sediaan</p>
                          <p className="w-[7cm] inline-block overflow-wrap text-ellipsis align-top">
                            : {page.type_packaging}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="w-full">
                      <table className="border-collapse w-full">
                        <thead className="table-header-group">
                          <tr className="bg-blue-500 text-white">
                            <th className="p-2 text-ellipsis text-center">
                              No
                            </th>
                            <th className="p-2 text-ellipsis">
                              Tgl Penerimaan /Pengeluaran
                            </th>
                            <th className="p-2 text-ellipsis">No Dokumen</th>
                            <th className="p-2 text-ellipsis">
                              Sumber /Tujuan
                            </th>
                            <th className="p-2 text-ellipsis">No. Batch</th>
                            <th className="p-2 text-ellipsis">
                              Tanggal Kadaluarsa
                            </th>
                            <th className="p-2 text-ellipsis text-center">
                              Jumlah Penerimaan
                            </th>
                            <th className="p-2 text-ellipsis text-center">
                              Jumlah Pengeluaran
                            </th>
                            <th className="p-2 text-ellipsis text-center">
                              Sisa Stok
                            </th>
                            <th className="p-2 text-ellipsis text-center">
                              Paraf
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {page.detail
                            ? page.detail.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="p-2 border text-center">
                                      {i + 1}
                                    </td>
                                    <td className="p-2 border">
                                      {moment(item.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td className="p-2 border">
                                      {item.relational_id}
                                    </td>
                                    <td className="p-2 border">
                                      {item.user_log}
                                    </td>
                                    <td className="p-2 border">
                                      {item.number_batch}
                                    </td>
                                    <td className="p-2 border">
                                      {moment(item.expired_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td className="p-2 border text-center">
                                      {item.stock_in}
                                    </td>
                                    <td className="p-2 border text-center">
                                      {item.stock_out}
                                    </td>
                                    <td className="p-2 border text-center">
                                      {item.stock_current +
                                        item.stock_in -
                                        item.stock_out}
                                    </td>
                                    <td className="p-2 border w-[3cm]"></td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default PrintCardStock;
