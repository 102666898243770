import { useLocation, useNavigate } from "react-router-dom";

const TabNavigationReturSell = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex items-end mb-4">
      <div className="flex font-bold text-center w-1/3">
        <p
          onClick={() => navigate("/retur/sell-retur/pos")}
          className={`w-full p-2 border-b-2 hover:text-gray-300 cursor-pointer ${
            pathname.includes("/retur/sell-retur/pos")
              ? "border-blue-500 text-blue-500"
              : ""
          }`}
        >
          POS
        </p>
        <p
          onClick={() => navigate("/retur/sell-retur/invoice")}
          className={`w-full p-2 border-b-2 hover:text-gray-300 cursor-pointer ${
            pathname.includes("/retur/sell-retur/invoice")
              ? "border-blue-500 text-blue-500"
              : ""
          }`}
        >
          Invoice
        </p>
      </div>
      <hr className="w-full text-gray-400 border-t-2" />
    </div>
  );
};

export default TabNavigationReturSell;
