import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Tooltip } from "@mui/material";
import { CgUserList } from "react-icons/cg";
import {
  MdBusinessCenter,
  MdOutlineDashboard,
  MdOutlineInventory2,
  MdOutlineReceiptLong,
} from "react-icons/md";
import {
  BsBoxSeam,
  BsCartPlus,
  BsChatLeftText,
  BsPeopleFill,
} from "react-icons/bs";
import { BiPurchaseTag } from "react-icons/bi";
import { RiArrowGoBackLine, RiFileChartLine } from "react-icons/ri";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { GrNotes } from "react-icons/gr";
import { IoIosCreate } from "react-icons/io";
import { TbDiscount, TbFileImport } from "react-icons/tb";
import {
  FaBars,
  FaUserCog,
  FaHandHoldingUsd,
  FaFileInvoiceDollar,
  FaListUl,
} from "react-icons/fa";
import Logo from "../../assets/icons/logoOlin.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/auth/authSlice";
import { getUserProfile } from "../../services/authApi";
import { checkStatusByEmail } from "../../services/authApi";
import { getOutletList } from "../../services/outletApi";
import SwitchOutletModal from "../common/particles.jsx/SwitchOutletModal";
import {
  INVENTORY_ROLE,
  INVOICE_ROLE,
  PATIENT_CARE_ROLE,
  RETUR_ROLE,
  SIDEBAR_WIDTH,
  WAREHOUSE_INVENTORY_ROLE,
  WAREHOUSE_INVOICE_ROLE,
  WAREHOUSE_ROLE,
} from "../constants/constant";

const SidebarComponents = () => {
  const { toggleSidebar, broken } = useProSidebar();
  const [selectedOutlet, setSelectedOutlet] = useState(
    Number(localStorage.getItem("company_id"))
  );

  const [selectedOutletName, setSelectedOutletName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [userRole, setUserRole] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const img_profile = localStorage.getItem("img_profile");
  const name = localStorage.getItem("name");
  const job = localStorage.getItem("job");
  const titleId = localStorage.getItem("title");

  const { data: profileData } = useQuery({
    queryKey: ["profileData"],
    queryFn: () => getUserProfile(),
  });

  const { mutate: mutateCheckStatusByEmail, data: statusUser } = useMutation({
    mutationFn: checkStatusByEmail,
  });

  const { data: dataOutlet } = useQuery({
    queryFn: () => getOutletList(),
    queryKey: ["outlets"],
  });

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    if (profileData === undefined) return;
    mutateCheckStatusByEmail({ email: profileData?.email });
  }, [profileData]);

  useEffect(() => {
    if (
      dataOutlet &&
      localStorage.getItem("company_id") &&
      selectedOutlet !== 0 &&
      selectedOutlet !== Number(localStorage.getItem("company_id"))
    )
      setOpenModal(true);
    setSelectedOutletName(
      dataOutlet?.find((item) => item.company_id === Number(selectedOutlet))
        ?.company_name
    );
  }, [selectedOutlet]);

  useEffect(() => {
    setSelectedOutlet(Number(localStorage.getItem("company_id")));
    setIsChanged(false);
  }, [isChanged]);

  useEffect(() => {
    localStorage.getItem("roles")
      ? setUserRole(
          localStorage
            .getItem("roles")
            .split(",")
            .map((item) => Number(item))
        )
      : handleLogout();
  }, []);

  return (
    <div className="flex h-screen p-0 m-0 sticky top-0 z-[100] bg-white text-[14px]">
      <Sidebar
        breakPoint="lg"
        backgroundColor="#fff"
        className="shadow-md"
        width={SIDEBAR_WIDTH + "px"}
      >
        <div className="flex flex-column">
          <img
            src={Logo}
            alt="logo"
            className="mt-3 mb-10 ml-16 h-[7%] w-[25%]"
          />
          <Menu>
            {userRole.includes("2") ? null : (
              <a href="/home">
                <MenuItem
                  icon={<MdOutlineDashboard />}
                  active={window.location.pathname === "/home"}
                >
                  Dashboard
                </MenuItem>
              </a>
            )}
            {/* {userRole.includes(2) ? (
              <>
                <a href="/chat">
                  <MenuItem
                    icon={<BsChatLeftText />}
                    active={window.location.pathname === "/chat"}
                  >
                    Chat
                  </MenuItem>
                </a>
                <a href="/orders">
                  <MenuItem
                    icon={<MdOutlineReceiptLong />}
                    active={window.location.pathname === "/orders"}
                  >
                    Pesanan
                  </MenuItem>
                </a>
              </>
            ) : null} */}

            <div>
              <a href={true ? null : "/purchase"}>
                <MenuItem
                  icon={<BsCartPlus />}
                  active={window.location.pathname === "/purchase"}
                  disabled
                  // disabled={
                  // statusUser?.status_code === 2 || statusUser?.status_code === 8
                  // true
                  // }
                >
                  Purchase
                </MenuItem>
              </a>

              {userRole.includes(2) ? (
                <SubMenu
                  icon={<FaListUl />}
                  label="Order Management System"
                  defaultOpen={window.location.pathname.includes("/oms/")}
                >
                  <a href="/oms/0">
                    <MenuItem
                      icon={<></>}
                      active={window.location.pathname === "/oms/0"}
                    >
                      Order Management System
                    </MenuItem>
                  </a>
                  <a href="/oms/settings">
                    <MenuItem
                      icon={<></>}
                      active={window.location.pathname === "/oms/settings"}
                    >
                      System Settings
                    </MenuItem>
                  </a>
                </SubMenu>
              ) : null}

              {userRole.includes(9) ? (
                <SubMenu
                  icon={<BiPurchaseTag />}
                  label="Purchase Order"
                  defaultOpen={window.location.pathname.includes(
                    "/purchase-order"
                  )}
                >
                  <a href="/purchase-order">
                    <MenuItem
                      icon={<IoIosCreate />}
                      active={window.location.pathname === "/purchase-order"}
                    >
                      Create Purchase Order
                    </MenuItem>
                  </a>
                  <a href="/purchase-order/import">
                    <MenuItem
                      icon={<TbFileImport />}
                      active={
                        window.location.pathname === "/purchase-order/import"
                      }
                      disabled={
                        profileData && profileData !== undefined
                          ? !profileData.role?.includes(9)
                          : true
                      }
                    >
                      Import Purchase Order
                    </MenuItem>
                  </a>
                </SubMenu>
              ) : null}
              {userRole.some((elem) => INVENTORY_ROLE.includes(elem)) ? (
                <SubMenu
                  icon={<MdOutlineInventory2 />}
                  label="Inventory"
                  defaultOpen={window.location.pathname.includes("/inventory/")}
                >
                  {userRole.includes(4) ? (
                    <a href="/inventory/produk-aktif">
                      <MenuItem
                        icon={<></>}
                        active={[
                          "/inventory/produk-aktif",
                          "/inventory/produk-habis",
                          "/inventory/produk-hold",
                          "/inventory/produk-expired",
                          "/inventory/produk-inactive",
                        ].some((link) => window.location.pathname === link)}
                      >
                        Inventory List
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(22) ? (
                    <a href="/inventory/stock-adjustment">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/inventory/stock-adjustment"
                        }
                        // disabled
                      >
                        Penyesuaian Stock
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(23) ? (
                    <a href="/inventory/stock-opname">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname === "/inventory/stock-opname"
                        }
                        // disabled
                      >
                        Stock Opname
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(24) ? (
                    <a href="/inventory/card-stock">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname === "/inventory/card-stock"
                        }
                        // disabled
                      >
                        Kartu Stock
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(25) ? (
                    <a href="/inventory/summary">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname === "/inventory/summary"
                        }
                      >
                        Inventory Summary
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(45) ? (
                    <SubMenu label="Sinkronisasi Akun">
                      <a href="/inventory/sinkronisasi/good-doctor">
                        <MenuItem
                          icon={<></>}
                          active={
                            window.location.pathname ===
                            "/inventory/sinkronisasi/good-doctor"
                          }
                        >
                          Good Doctor
                        </MenuItem>
                      </a>
                    </SubMenu>
                  ) : null}
                </SubMenu>
              ) : null}

              {userRole.some((elem) => PATIENT_CARE_ROLE.includes(elem)) ? (
                <SubMenu
                  icon={<CgUserList />}
                  label="Patient Care"
                  defaultOpen={window.location.pathname.includes(
                    "/patient-care/"
                  )}
                >
                  {userRole.includes(17) ? (
                    <a href="/patient-care/medical-record">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/patient-care/medical-record"
                        }
                      >
                        Patient Medical Record
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(20) ? (
                    <a href="/patient-care/patient-database">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/patient-care/patient-database"
                        }
                      >
                        Patient Database
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(21) ? (
                    <a href="/patient-care/doctor-database">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/patient-care/doctor-database"
                        }
                      >
                        Doctor Database
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(18) ? (
                    <a href="/patient-care/initial-analysis">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/patient-care/initial-analysis"
                        }
                      >
                        Initial Analysis
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(19) ? (
                    <a href="/patient-care/doctor-analysis">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/patient-care/doctor-analysis"
                        }
                      >
                        Doctor Analysis
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(16) ? (
                    <a href="/patient-care/services">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname === "/patient-care/services"
                        }
                      >
                        Services
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(26) ? (
                    <a href="/patient-care/prescription-history">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/patient-care/prescription-history"
                        }
                      >
                        Prescription History
                      </MenuItem>
                    </a>
                  ) : null}
                </SubMenu>
              ) : null}

              {userRole.some((elem) => WAREHOUSE_ROLE.includes(elem)) &&
              dataOutlet &&
              dataOutlet.filter((item) => item.status === 1).length > 0 ? (
                <SubMenu
                  icon={<BsBoxSeam />}
                  label="Central Warehouse"
                  defaultOpen={window.location.pathname.includes(
                    "/central-warehouse/"
                  )}
                >
                  {userRole.includes(28) ? (
                    <a href="/central-warehouse/manual-billing/all-billing">
                      <MenuItem
                        icon={<></>}
                        active={[
                          "/central-warehouse/manual-billing/all-billing",
                          "/central-warehouse/manual-billing/unpaid",
                          "/central-warehouse/manual-billing/overdue",
                          "/central-warehouse/manual-billing/paid",
                          "/central-warehouse/manual-billing/draft",
                        ].some((link) => window.location.pathname === link)}
                      >
                        Billing
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(29) ? (
                    <a href="/central-warehouse/purchase-order">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/central-warehouse/purchase-order"
                        }
                      >
                        Purchase Order
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.some((elem) =>
                    WAREHOUSE_INVENTORY_ROLE.includes(elem)
                  ) ? (
                    <SubMenu
                      label="Inventory"
                      className="px-[23px]"
                      defaultOpen={window.location.pathname.includes(
                        "/central-warehouse/inventory/"
                      )}
                    >
                      {userRole.includes(27) ? (
                        <a href="/central-warehouse/inventory/produk-aktif">
                          <MenuItem
                            icon={<></>}
                            active={[
                              "/central-warehouse/inventory/produk-aktif",
                              "/central-warehouse/inventory/produk-habis",
                              "/central-warehouse/inventory/produk-hold",
                              "/central-warehouse/inventory/produk-expired",
                              "/central-warehouse/inventory/produk-inactive",
                            ].some((link) => window.location.pathname === link)}
                          >
                            Inventory List
                          </MenuItem>
                        </a>
                      ) : null}

                      {userRole.includes(30) ? (
                        <a href="/central-warehouse/inventory/card-stock">
                          <MenuItem
                            icon={<></>}
                            active={
                              window.location.pathname ===
                              "/central-warehouse/inventory/card-stock"
                            }
                          >
                            Kartu Stock
                          </MenuItem>
                        </a>
                      ) : null}
                      {userRole.includes(31) ? (
                        <a href="/central-warehouse/inventory/summary">
                          <MenuItem
                            icon={<></>}
                            active={
                              window.location.pathname ===
                              "/central-warehouse/inventory/summary"
                            }
                          >
                            Inventory Summary
                          </MenuItem>
                        </a>
                      ) : null}
                      {userRole.includes(32) ? (
                        <a href="/central-warehouse/inventory/stock-adjustment">
                          <MenuItem
                            icon={<></>}
                            active={
                              window.location.pathname ===
                              "/central-warehouse/inventory/stock-adjustment"
                            }
                          >
                            Penyesuaian Stok
                          </MenuItem>
                        </a>
                      ) : null}
                      {userRole.includes(33) ? (
                        <a href="/central-warehouse/inventory/stock-opname">
                          <MenuItem
                            icon={<></>}
                            active={
                              window.location.pathname ===
                              "/central-warehouse/inventory/stock-opname"
                            }
                          >
                            Stock Opname
                          </MenuItem>
                        </a>
                      ) : null}
                    </SubMenu>
                  ) : null}
                  {userRole.some((elem) =>
                    WAREHOUSE_INVOICE_ROLE.includes(elem)
                  ) ? (
                    <SubMenu
                      label="Invoice"
                      className="px-[23px]"
                      defaultOpen={window.location.pathname.includes(
                        "/central-warehouse/invoice/"
                      )}
                    >
                      {userRole.includes(41) ? (
                        <a href="/central-warehouse/invoice/customer">
                          <MenuItem
                            icon={<></>}
                            active={
                              window.location.pathname ===
                              "/central-warehouse/invoice/customer"
                            }
                            // disabled={statusUser?.status_code === 9}
                          >
                            Customer
                          </MenuItem>
                        </a>
                      ) : null}
                      {userRole.includes(42) ? (
                        <a href="/central-warehouse/invoice/invoice">
                          <MenuItem
                            icon={<></>}
                            active={
                              window.location.pathname ===
                              "/central-warehouse/invoice/invoice"
                            }
                          >
                            Invoice
                          </MenuItem>
                        </a>
                      ) : null}
                      {userRole.includes(43) ? (
                        <a href="/central-warehouse/invoice/promo">
                          <MenuItem
                            icon={<></>}
                            active={
                              window.location.pathname ===
                              "/central-warehouse/invoice/promo"
                            }
                          >
                            Setting Promo
                          </MenuItem>
                        </a>
                      ) : null}
                      {userRole.includes(44) ? (
                        <a href="/central-warehouse/invoice/setting-invoice">
                          <MenuItem
                            icon={<></>}
                            active={
                              window.location.pathname ===
                              "/central-warehouse/invoice/setting-invoice"
                            }
                          >
                            Setting Invoice
                          </MenuItem>
                        </a>
                      ) : null}
                    </SubMenu>
                  ) : null}
                  {userRole.includes(34) ? (
                    <a href="/central-warehouse/allocation">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/central-warehouse/allocation"
                        }
                      >
                        Alokasi
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(35) ? (
                    <a href="/central-warehouse/transfer">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/central-warehouse/transfer"
                        }
                      >
                        Transfer
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(36) ? (
                    <a href="/central-warehouse/retur-warehouse">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/central-warehouse/retur-warehouse"
                        }
                      >
                        Retur to Warehouse
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(39) ? (
                    <a href="/central-warehouse/inbox/warehouse">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/central-warehouse/inbox/warehouse"
                        }
                      >
                        Inbox Warehouse
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(40) ? (
                    <a href="/central-warehouse/inbox/outlet">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/central-warehouse/inbox/outlet"
                        }
                      >
                        Inbox Outlet
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(37) ? (
                    <a href="/central-warehouse/request-allocation">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/central-warehouse/request-allocation"
                        }
                      >
                        Request Allocation
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(38) ? (
                    <a href="/central-warehouse/request-transfer">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/central-warehouse/request-transfer"
                        }
                      >
                        Request Transfer
                      </MenuItem>
                    </a>
                  ) : null}
                </SubMenu>
              ) : null}

              {userRole.includes(8) ? (
                <a href="/inventory/consignment">
                  <MenuItem
                    icon={<FaHandHoldingUsd />}
                    active={
                      window.location.pathname === "/inventory/consignment"
                    }
                    // disabled={true}
                  >
                    Consignment
                  </MenuItem>
                </a>
              ) : null}

              {userRole.includes(7) ? (
                <SubMenu
                  icon={<GrNotes />}
                  label="Billing"
                  defaultOpen={window.location.pathname.includes(
                    "/manual-billing"
                  )}
                >
                  <a href={true ? null : "/billing/unpaid"}>
                    <MenuItem
                      icon={<></>}
                      active={window.location.pathname === "/billing/unpaid"}
                      // disabled={statusUser?.status_code === 9}
                      disabled={true}
                    >
                      Billing List
                    </MenuItem>
                  </a>
                  <a href="/manual-billing">
                    <MenuItem
                      icon={<></>}
                      active={window.location.pathname === "/manual-billing"}
                      // disabled
                    >
                      Manual Billing
                    </MenuItem>
                  </a>
                </SubMenu>
              ) : null}

              {userRole.some((elem) => RETUR_ROLE.includes(elem)) ? (
                <SubMenu
                  icon={<RiArrowGoBackLine />}
                  label="Retur"
                  defaultOpen={window.location.pathname.includes("/retur/")}
                >
                  {userRole.includes(46) ? (
                    <a href="/retur/buy-retur">
                      <MenuItem
                        icon={<></>}
                        active={window.location.pathname === "/retur/buy-retur"}
                      >
                        Retur Pembelian
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(47) ? (
                    <a href="/retur/sell-retur/pos">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname === "/retur/sell-retur/pos"
                        }
                      >
                        Retur Penjualan
                      </MenuItem>
                    </a>
                  ) : null}
                </SubMenu>
              ) : null}

              {userRole.includes(5) ? (
                <SubMenu
                  icon={<RiFileChartLine />}
                  label="Reporting"
                  defaultOpen={window.location.pathname.includes("/reporting")}
                >
                  <a href="/reporting">
                    <MenuItem
                      icon={<></>}
                      active={window.location.pathname === "/reporting"}
                    >
                      All Report
                    </MenuItem>
                  </a>
                  <a href="/reporting/product">
                    <MenuItem
                      icon={<></>}
                      active={window.location.pathname === "/reporting/product"}
                    >
                      Report Product
                    </MenuItem>
                  </a>
                  <a href="/reporting/log-shift">
                    <MenuItem
                      icon={<></>}
                      active={
                        window.location.pathname === "/reporting/log-shift"
                      }
                    >
                      Log Shift
                    </MenuItem>
                  </a>
                  <a href="/operational/list">
                    <MenuItem
                      icon={<></>}
                      active={window.location.pathname === "/operational/list"}
                    >
                      Operational Cost
                    </MenuItem>
                  </a>
                  <a href="/reporting/profit-loss">
                    <MenuItem
                      icon={<></>}
                      active={
                        window.location.pathname === "/reporting/profit-loss"
                      }
                    >
                      Profit & Loss
                    </MenuItem>
                  </a>
                </SubMenu>
              ) : null}

              {userRole.some((elem) => INVOICE_ROLE.includes(elem)) ? (
                <SubMenu
                  icon={<FaFileInvoiceDollar />}
                  label="Invoice"
                  defaultOpen={window.location.pathname.includes("/invoice/")}
                >
                  {userRole.includes(10) ? (
                    <a href="/invoice/customer">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname === "/invoice/customer"
                        }
                      >
                        Customer
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(11) ? (
                    <a href="/invoice/invoice">
                      <MenuItem
                        icon={<></>}
                        active={window.location.pathname === "/invoice/invoice"}
                      >
                        Invoice
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(12) ? (
                    <a href="/invoice/promo">
                      <MenuItem
                        icon={<></>}
                        active={window.location.pathname === "/invoice/promo"}
                      >
                        Setting Promo
                      </MenuItem>
                    </a>
                  ) : null}
                  {userRole.includes(13) ? (
                    <a href="/invoice/setting-invoice">
                      <MenuItem
                        icon={<></>}
                        active={
                          window.location.pathname ===
                          "/invoice/setting-invoice"
                        }
                      >
                        Setting Invoice
                      </MenuItem>
                    </a>
                  ) : null}
                </SubMenu>
              ) : null}
              <a href="/discount">
                <MenuItem
                  icon={<TbDiscount />}
                  active={window.location.pathname === "/discount"}
                >
                  Discount/Promo
                </MenuItem>
              </a>
              <a href="/pelanggan">
                <MenuItem
                  icon={<BsPeopleFill />}
                  active={window.location.pathname === "/pelanggan"}
                >
                  Pelanggan
                </MenuItem>
              </a>
              <a href="/sales">
                <MenuItem
                  icon={<MdBusinessCenter />}
                  active={window.location.pathname === "/sales"}
                >
                  Sales
                </MenuItem>
              </a>
              {userRole.includes(6) ? (
                <a href="/user-management">
                  <MenuItem
                    icon={<FaUserCog />}
                    active={window.location.pathname === "/user-management"}
                    // disabled
                  >
                    User Management
                  </MenuItem>
                </a>
              ) : null}
              {/* </Link> */}
              <SubMenu
                icon={<FiSettings />}
                label="Settings"
                defaultOpen={window.location.pathname.includes("/settings/")}
              >
                <a href="/settings/payment-method">
                  <MenuItem
                    icon={<></>}
                    active={
                      window.location.pathname === "/settings/payment-method"
                    }
                  >
                    Payment method
                  </MenuItem>
                </a>
                <a href="/settings/invoice-setting">
                  <MenuItem
                    icon={<></>}
                    active={
                      window.location.pathname === "/settings/invoice-setting"
                    }
                  >
                    Invoice Settings
                  </MenuItem>
                </a>
              </SubMenu>
            </div>
          </Menu>
          <div className="grid grid-cols-5 p-6 w-full gap-2">
            <div className="w-fit flex items-center">
              <img
                src={img_profile}
                alt="profile"
                className="w-10 rounded-full"
              />
            </div>
            <div className="break-all col-span-3">
              <div
                className="flex flex-col text-[12px] hover:cursor-pointer hover:text-[#0d6efd]"
                onClick={() => navigate("/profile")}
              >
                <p className="font-bold">{name}</p>
                <p>{job}</p>
              </div>
              {Number(titleId) === 1 && dataOutlet && dataOutlet.length > 1 ? (
                //&&  !dataOutlet.every((outlet) => outlet.status !== 1) ? (
                <div>
                  <select
                    className="py-2 hover:text-gray-400 hover:cursor-pointer text-[12px] w-full whitespace-nowrap text-ellipsis"
                    onChange={(e) => {
                      setSelectedOutlet(e.target.value);
                    }}
                    value={Number(localStorage.getItem("company_id"))}
                  >
                    {dataOutlet && dataOutlet.length > 1 ? (
                      dataOutlet.map((outlet) => {
                        // if (outlet.status === 1)
                        return (
                          <option
                            value={outlet.company_id}
                            key={outlet.company_id}
                          >
                            {outlet.company_name}
                          </option>
                        );
                      })
                    ) : (
                      <option value={0} disabled>
                        Tidak ada outlet Lain
                      </option>
                    )}
                  </select>
                </div>
              ) : null}
            </div>
            <div className="flex items-center">
              <Tooltip title="Logout">
                <div
                  className="ml-[10px] hover:cursor-pointer hover:text-[#0d6efd]"
                  onClick={handleLogout}
                >
                  <FiLogOut className="text-[30px]" />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </Sidebar>

      <SwitchOutletModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        setIsChanged={setIsChanged}
        companyId={selectedOutlet}
        outletName={selectedOutletName}
      />
    </div>
  );
};

export default SidebarComponents;
