import {
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaMoneyBill } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import ModalBankBilling from "./ModalBankBilling";
import ModalNotaRetur from "./ModalNotaRetur";
import { payBillingMultiple } from "../../../../services/billingAPI";
import { useMutation } from "@tanstack/react-query";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const ModalPaymentBillingList = ({ open, setOpen, billing, refetch }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [openBank, setOpenBank] = useState(false);
  const [openRetur, setOpenRetur] = useState(false);
  const [payArr, setPayArr] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([0, 0]);
  const [distArr, setDistArr] = useState([]);

  const handleClose = () => {
    setErrorMessage("");
    setPayArr([]);
    setSelectedPayment([0, 0]);
    setOpen(false);
  };

  const splitPayment = (i) => {
    setPayArr([
      ...payArr.slice(0, i),
      {
        ...payArr[i],
        payment: [...payArr[i].payment, { method: 1, amount: 0 }],
      },
      ...payArr.slice(i + 1),
    ]);
  };

  const handleBankNota = (bank, nota) => {
    const i = selectedPayment[0];
    const indexPay = selectedPayment[1];

    const item = payArr[i];
    const pay = payArr[i].payment[indexPay];

    setPayArr([
      ...payArr.slice(0, i),
      {
        ...item,
        payment: [
          ...item.payment.slice(0, indexPay),
          bank && !nota
            ? {
                ...pay,
                bank_id: bank.bank_id,
                bank_name: bank.bank_name,
                method: 2,
              }
            : nota && !bank
            ? {
                ...pay,
                amount: nota.total_price,
                note_number: nota.note_number,
                method: 3,
              }
            : {
                ...pay,
                method: 1,
              },

          ...item.payment.slice(indexPay + 1),
        ],
      },
      ...payArr.slice(i + 1),
    ]);
  };

  const getRemainingTotal = (i) => {
    return billing[i] ? billing[i].total_bill - billing[i].total_amount : 0;
  };

  const getTotalInput = () => {
    var total = 0;
    payArr.map((item) => {
      total = total + item.payment.reduce((acc, num) => acc + num.amount, 0);
    });
    return total;
  };

  const validPayment = () => {
    {
      /**
        Payment is valid if 
        * full payment, each po is paid equal or more if contains nota retur
        * full payment, each po is paid equal if doesn't contain nota retur
        * other payment, does contain nota retur can pay any amount
        * other payment, doesn't contain nota retur cannot exceed total
        */
    }
    var isValid = true;

    payArr.map((item, i) => {
      if (item.is_full) {
        if (
          item.payment.find((pay) => pay.method === 3) &&
          item.payment.reduce((acc, num) => acc + num.amount, 0) <
            getRemainingTotal(i)
        )
          isValid = false;

        if (
          item.payment.every((pay) => pay.method !== 3) &&
          !(
            item.payment.reduce((acc, num) => acc + num.amount, 0) ===
            getRemainingTotal(i)
          )
        )
          isValid = false;
      } else {
        if (
          item.payment.every((pay) => pay.method !== 3) &&
          item.payment.reduce((acc, num) => acc + num.amount, 0) >
            getRemainingTotal(i)
        )
          isValid = false;
      }
    });

    return isValid;
  };

  const setFull = (val) => {
    const i = selectedPayment[0];
    const payIndex = selectedPayment[1];

    setPayArr([
      ...payArr.slice(0, i),
      { ...payArr[i], is_full: val },
      ...payArr.slice(i + 1),
    ]);
  };

  const handlePay = () => {
    mutatePayment(payArr);
  };

  const { mutate: mutatePayment, isLoading } = useMutation({
    mutationFn: payBillingMultiple,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (err) =>
      setErrorMessage(
        err.message.id
          ? err.message.id
          : err.message
          ? err.message
          : err
          ? err
          : "Terjadi kesalahan saat membayar billing"
      ),
  });

  useEffect(() => {
    setPayArr(
      billing.map((item) => ({
        id: item.billing_id,
        is_full: true,
        payment: [
          {
            method: 1,
            amount: item.total_bill - item.total_amount,
          },
        ],
      }))
    );
  }, [billing]);

  useEffect(() => {
    var dist = [];
    payArr.map((item) => {
      item.payment.map((pay) => {
        if (pay.method === 3 && pay.note_number) dist.push(pay.note_number);
      });
    });
    setDistArr(dist);
  }, [payArr]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "hidden",
            overflowX: "wrap",
            maxHeight: "90vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/3 h-full"
        >
          <div className="flex justify-between items-center px-8 py-4">
            <div className="flex gap-4 items-center w-full">
              <div className="w-12 h-12 bg-blue-100 p-1 rounded-full">
                <div className="w-full h-full bg-blue-300 p-2 rounded-full">
                  <FaMoneyBill className="w-full h-full text-blue-600" />
                </div>
              </div>
              <p className="font-bold text-lg">Pembayaran Billing</p>
            </div>

            <RxCross1
              className="cursor-pointer hover:text-red-500"
              onClick={handleClose}
            />
          </div>

          <div className="px-8">
            <div className="mb-4">
              <p className="mb-2 font-bold text-[14px]">Nominal Pembayaran</p>
              <div className="mb-4 flex gap-2 items-center text-[14px] mb-4">
                <div
                  className={`w-full p-2 rounded-md border-2 hover:bg-gray-300 text-center cursor-pointer ${
                    payArr[selectedPayment[0]]?.is_full
                      ? "bg-blue-500 text-white border-none"
                      : ""
                  }`}
                  onClick={() => setFull(true)}
                >
                  Bayar Penuh
                </div>
                <div
                  className={`w-full p-2 rounded-md border-2 hover:bg-gray-300 text-center cursor-pointer ${
                    payArr[selectedPayment[0]]?.is_full
                      ? ""
                      : "bg-blue-500 text-white border-none"
                  }`}
                  onClick={() => setFull(false)}
                >
                  Bayar Nominal Lain
                </div>
              </div>

              <div className="w-full h-[44vh] overflow-y-auto px-1">
                {payArr.map((item, i) => {
                  return (
                    <div className="mb-4 w-full">
                      <div>
                        {payArr.length > 1 && billing[i] ? (
                          <div className="flex justify-between items-center">
                            <p>{billing[i].po_number}</p>
                            <p className="font-bold">
                              {toIDR(
                                billing[i].total_bill - billing[i].total_amount
                              )}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <hr className="my-2" />
                      {item.payment.map((pay, indexPay) => {
                        return (
                          <div className="flex gap-2 items-center mb-2">
                            <div className="w-full max-w-full">
                              <Select
                                sx={{ width: "100%" }}
                                value={pay.method}
                                onChange={(e) => {
                                  setPayArr([
                                    ...payArr.slice(0, i),
                                    {
                                      ...item,
                                      payment: [
                                        ...item.payment.slice(0, indexPay),
                                        {
                                          ...pay,
                                          method: e.target.value,
                                        },
                                        ...item.payment.slice(indexPay + 1),
                                      ],
                                    },
                                    ...payArr.slice(i + 1),
                                  ]);
                                }}
                              >
                                <MenuItem
                                  value={1}
                                  onClick={() =>
                                    setSelectedPayment([i, indexPay])
                                  }
                                >
                                  Cash
                                </MenuItem>
                                <MenuItem
                                  value={2}
                                  onClick={() => {
                                    setSelectedPayment([i, indexPay]);
                                    setOpenBank(true);
                                  }}
                                >
                                  {pay.bank_name
                                    ? "Bank Transfer - " + pay.bank_name
                                    : "Bank"}
                                </MenuItem>
                                <MenuItem
                                  value={3}
                                  onClick={() => {
                                    setSelectedPayment([i, indexPay]);
                                    setOpenRetur(true);
                                  }}
                                >
                                  Nota Retur
                                </MenuItem>
                              </Select>
                            </div>
                            <TextField
                              fullWidth
                              type="number"
                              onClick={() => setSelectedPayment([i, indexPay])}
                              disabled={pay.method === 3}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              value={pay.amount ? pay.amount.toString() : 0}
                              onChange={(e) => {
                                if (!isNaN(Number(e.target.value))) {
                                  setPayArr([
                                    ...payArr.slice(0, i),
                                    {
                                      ...item,
                                      payment: [
                                        ...item.payment.slice(0, indexPay),
                                        {
                                          ...pay,
                                          amount: Number(e.target.value),
                                        },
                                        ...item.payment.slice(indexPay + 1),
                                      ],
                                    },
                                    ...payArr.slice(i + 1),
                                  ]);
                                }
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Rp
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor:
                                      selectedPayment[0] === i ? "blue" : "",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "blue",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "blue",
                                  },
                                },
                              }}
                            />
                            {item.payment.length > 1 ? (
                              <RxCross1
                                className="cursor-pointer text-red-500 hover:text-red-700 text-[30px]"
                                onClick={() => {
                                  setPayArr([
                                    ...payArr.slice(0, i),
                                    {
                                      ...item,
                                      payment: [
                                        ...item.payment.slice(0, indexPay),
                                        ...item.payment.slice(indexPay + 1),
                                      ],
                                    },
                                    ...payArr.slice(i + 1),
                                  ]);
                                }}
                              />
                            ) : null}
                          </div>
                        );
                      })}
                      <p
                        className={`text-[10px] text-right  mb-4 ${
                          billing[i] &&
                          item.payment.reduce(
                            (acc, num) => acc + num.amount,
                            0
                          ) <
                            billing[i].total_bill - billing[i].total_amount
                            ? "text-blue-500 cursor-pointer hover:underline"
                            : "text-gray-300 cursor-default"
                        }`}
                        onClick={() => {
                          if (
                            billing[i] &&
                            item.payment.reduce(
                              (acc, num) => acc + num.amount,
                              0
                            ) <
                              billing[i].total_bill - billing[i].total_amount
                          )
                            splitPayment(i);
                        }}
                      >
                        Split Payment
                      </p>
                    </div>
                  );
                })}
              </div>
              <p className="mb-2 text-red-500">{errorMessage}</p>
            </div>
          </div>

          <div className="shadow-lg shadow-slate-900/20 shadow-t-2 -shadow-spread-1 py-2 min-h-[10vh] fixed w-full bottom-0">
            <div className="flex justify-between items-center px-8">
              <div>
                <p>Jumlah yang harus dibayar</p>
                <p className="text-[14px] font-bold">
                  {toIDR(
                    billing.reduce(
                      (acc, num) => acc + (num.total_bill - num.total_amount),
                      0
                    )
                  )}
                </p>
              </div>
              <div>
                <p>Jumlah yang diinput</p>
                <p className="text-[14px] font-bold">
                  {toIDR(getTotalInput())}
                </p>
              </div>
            </div>
            <hr className="my-2" />
            <div className=" flex gap-2 items-center px-8">
              <button
                type="button"
                className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
                disabled={isLoading}
                onClick={handleClose}
              >
                Batal
              </button>
              <button
                type="button"
                className="w-full p-2 rounded-md border-2 border-blue-500 bg-blue-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300 disabled:border-none"
                disabled={!validPayment() || isLoading}
                onClick={handlePay}
              >
                {isLoading ? <CircularProgress size={15} /> : "Bayar"}
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <ModalBankBilling
        open={openBank}
        setOpen={setOpenBank}
        selectBank={(bank) => handleBankNota(bank, null)}
      />

      <ModalNotaRetur
        open={openRetur}
        setOpen={setOpenRetur}
        selectNota={(nota) => handleBankNota(null, nota)}
        distArr={distArr}
        distributorId={billing[selectedPayment[0]]?.distributor_id}
      />
    </>
  );
};

export default ModalPaymentBillingList;
