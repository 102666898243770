import { useLocation, useNavigate } from "react-router-dom";

const TabNavigationOms = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex items-end mb-4 overflow-x-auto">
      <div className="flex text-center w-full">
        <p
          onClick={() => navigate("/oms/0")}
          className={`w-full py-2 px-4 border-b-2 hover:text-gray-300 cursor-pointer whitespace-nowrap ${
            pathname.includes("/oms/0") ? "border-blue-500 text-blue-500" : ""
          }`}
        >
          Semua Pesanan
        </p>
        <p
          onClick={() => navigate("/oms/1")}
          className={`w-full py-2 px-4 border-b-2 hover:text-gray-300 cursor-pointer whitespace-nowrap ${
            pathname.includes("/oms/1") ? "border-blue-500 text-blue-500" : ""
          }`}
        >
          Pesanan Baru
        </p>
        <p
          onClick={() => navigate("/oms/2")}
          className={`w-full py-2 px-4 border-b-2 hover:text-gray-300 cursor-pointer whitespace-nowrap ${
            pathname.includes("/oms/2") ? "border-blue-500 text-blue-500" : ""
          }`}
        >
          Siap Dikirim
        </p>
        <p
          onClick={() => navigate("/oms/3")}
          className={`w-full py-2 px-4 border-b-2 hover:text-gray-300 cursor-pointer whitespace-nowrap ${
            pathname.includes("/oms/3") ? "border-blue-500 text-blue-500" : ""
          }`}
        >
          Dalam Pengiriman
        </p>
        <p
          onClick={() => navigate("/oms/4")}
          className={`w-full py-2 px-4 border-b-2 hover:text-gray-300 cursor-pointer whitespace-nowrap ${
            pathname.includes("/oms/4") ? "border-blue-500 text-blue-500" : ""
          }`}
        >
          Pesanan Selesai
        </p>
        <p
          onClick={() => navigate("/oms/5")}
          className={`w-full py-2 px-4 border-b-2 hover:text-gray-300 cursor-pointer whitespace-nowrap ${
            pathname.includes("/oms/5") ? "border-blue-500 text-blue-500" : ""
          }`}
        >
          Dibatalkan
        </p>
      </div>
      <hr className="w-full text-gray-400 border-t-2" />
    </div>
  );
};

export default TabNavigationOms;
