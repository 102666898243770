import { CircularProgress, Drawer } from "@mui/material";
import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useQuery } from "@tanstack/react-query";
import { BsDownload, BsPrinter } from "react-icons/bs";
import ModalReceiveOrder from "./modal/ModalReceiveOrder";
import ModalRejectOrder from "./modal/ModalRejectOrder";
import { getOMSDetail } from "../../../services/oms";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const OmsReceiveDrawer = ({ open, setOpen, refetch, order }) => {
  const [openReceive, setOpenReceive] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    data: dataDetail,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["oms-detail", open, order],
    queryFn: () => getOMSDetail(order.order_id),
    refetchOnWindowFocus: false,
    enabled: open === true && order !== null,
  });

  return (
    <div className="text-[12px] w-full">
      <Drawer
        open={open}
        onClose={handleClose}
        anchor="right"
        PaperProps={{
          sx: { width: { xs: "100%", md: "65%", lg: "55%" } },
        }}
      >
        <div className="h-[calc(100%-50px)] overflow-y-auto">
          <div className="py-4 px-8 flex items-center justify-between gap-8 w-full">
            <div>
              <p className="text-[24px] font-bold">Konfirmasi Resep</p>
              <p>
                Harap pastikan resep yang diunggah customer telah sesuai dengan
                obat yang dipesan
              </p>
            </div>
            <RxCross1
              className="cursor-pointer hover:text-red-500 w-fit text-[18px]"
              onClick={handleClose}
            />
          </div>
          <hr className="text-gray-400 border-t-[2px] border-solid" />
          <div className="px-8 py-4 min-h-[calc(100%-110px)] overflow-y-auto grid grid-cols-1 lg:grid-cols-3 gap-2 items-stretch">
            <div className="w-full border-r-none lg:border-r-2 pr-2 w-1/2">
              <div className="mb-4">Tidak ada resep dokter</div>
              <div className="flex items-center justify-end gap-2">
                <button
                  type="button"
                  className="w-[32px] h-[32px] bg-blue-500 text-white rounded-md p-1.5 disabled:bg-gray-300"
                  disabled={true}
                >
                  <BsPrinter className="w-full h-full" />
                </button>
                <button
                  type="button"
                  className="w-[32px] h-[32px] bg-blue-500 text-white rounded-md p-1.5 disabled:bg-gray-300"
                  disabled={true}
                >
                  <BsDownload className="w-full h-full" />
                </button>
              </div>
            </div>
            <div className="w-full pl-2 col-span-1 lg:col-span-2">
              {dataDetail && !isFetching && dataDetail.product?.length > 0 ? (
                dataDetail.product?.map((item, i) => (
                  <div
                    className="flex gap-4 border-2 rounded-md p-2 mb-4 items-start w-full justify-between"
                    key={i}
                  >
                    <div className="flex gap-4 items-center">
                      <div className="w-[80px] h-[80px]">
                        <img
                          src={item.image}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className="w-full">
                        <p className="text-[16px] font-semibold">
                          {item.product_name}
                        </p>
                        <p>Jumlah: {item.quantity}</p>
                      </div>
                    </div>
                    {item.is_okt ? (
                      <div className="w-fit text-right font-bold text-red-500 text-[14px]">
                        OKT
                      </div>
                    ) : null}
                  </div>
                ))
              ) : (
                <div>
                  {isFetching ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Tidak ada produk"
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="relative h-[50px]">
          <div className="static bottom-0 right-0 h-full">
            <hr className="text-gray-400 border-t-[2px] border-solid" />
            <div className="flex gap-2 items-center h-[40px] overflow-hidden p-4">
              <button
                type="button"
                className="w-full rounded-md p-2 border-2 border-red-500 text-red-500 hover:bg-gray-100"
                onClick={() => setOpenReject(true)}
              >
                Tolak Pesanan
              </button>
              <button
                type="button"
                className="w-full rounded-md p-2 border-2 border-blue-500 text-blue-500 hover:bg-gray-100 text-truncate disabled:bg-gray-300 disabled:text-white"
                disabled={true}
              >
                Terima & Buat Salinan Resep
              </button>
              <button
                type="button"
                className="w-full rounded-md p-2 bg-blue-500 text-white hover:bg-gray-300"
                onClick={() => setOpenReceive(true)}
              >
                Terima Pesanan
              </button>
            </div>
          </div>
        </div>
      </Drawer>

      <ModalReceiveOrder
        open={openReceive}
        setOpen={setOpenReceive}
        refetch={refetch}
        body={
          order ? { id: order.order_id, partner: order.platform_code } : null
        }
        closeParent={() => handleClose()}
      />
      <ModalRejectOrder
        open={openReject}
        setOpen={setOpenReject}
        refetch={refetch}
        body={
          order
            ? { order_id: order.order_id, partner_code: order.platform_code }
            : null
        }
        closeParent={() => handleClose()}
      />
    </div>
  );
};

export default OmsReceiveDrawer;
