import React from "react";
import InventorySummary from "../../../components/screen/inventory/InventorySummary/InventorySummary";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../../components/constants/muiTheme";

const InventorySummaryPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={isWarehouse ? "Central Warehouse" : "" + " Inventory Summary"}
        mainComponent={
          <ThemeProvider theme={theme}>
            <InventorySummary isWarehouse={isWarehouse} />
          </ThemeProvider>
        }
        pageRole={isWarehouse ? 31 : 25}
      />
    </div>
  );
};

export default HOC(InventorySummaryPage);
