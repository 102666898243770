import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  TextField,
} from "@mui/material";
import { IoMdAddCircleOutline } from "react-icons/io";
import moment from "moment";
import PopupRangeCalendar from "../../common/particles.jsx/PopupRangeCalendar";
import { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getPdfPo, getPoList } from "../../../services/purchaseOrderApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import PagePagination from "../../common/particles.jsx/PagePagination";
import { useNavigate } from "react-router-dom";
import ModalDeletePo from "./modal/ModalDeletePo";
import { useReactToPrint } from "react-to-print";
import { getPreviewInvoice } from "../../../services/ReportingApi";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";
import DatePicker from "react-datepicker";
import useDebounce from "../../hooks/useDebounce";
import { MONTHS, YEARS } from "../../constants/constant";
import PrintNotaPo from "./PrintNotaPo";

const INITIAL_REQ = {
  limit: 10,
  offset: 1,
  type: 0,
  sort: 0,
  start_date: "",
  end_date: "",
};

const PurchaseOrderList = () => {
  const navigate = useNavigate();
  const printRef = useRef();

  const [search, setSearch] = useState("");
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [anchorElMoreProduct, setAnchorElMoreProduct] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedPo, setSelectedPo] = useState(null);
  const [reqBody, setReqBody] = useState(INITIAL_REQ);
  const [moreProductArr, setMoreProductArr] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [period, setPeriod] = useState(0);
  const [periodDummy, setPeriodDummy] = useState(0);

  const debounce = useDebounce(search, 500);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setSelectedPo(null);
      setIsPrint(false);
    },
  });

  const {
    mutate: mutatePoList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: getPoList,
  });

  const { data: dataPdf, isFetching: fetchingPdf } = useQuery({
    queryKey: ["po-pdf", selectedPo],
    refetchInterval: 5000,
    queryFn: () => getPdfPo(selectedPo.id, false),
    onSuccess: () => {
      setTimeout(() => {
        handlePrint();
      }, 300);
    },
    enabled: selectedPo !== null && isPrint === true,
  });

  const { data: dataLabel } = useQuery({
    queryKey: ["label-print"],
    queryFn: getPreviewInvoice,
  });

  useEffect(() => {
    mutatePoList({ ...reqBody, search: debounce });
  }, [reqBody, debounce]);

  useEffect(() => {
    setReqBody((prevVal) => ({ ...prevVal, offset: 1 }));
  }, [debounce, reqBody.sort, reqBody.type, reqBody.end_date]);

  useEffect(() => {
    setReqBody((prevVal) => ({ ...prevVal, sort: period }));
  }, [period]);

  const NotaInfo = () => {
    return (
      <div className="mb-4">
        <div className="flex gap-3">
          <div className="flex gap-2 w-full">
            <p className="w-1/2">Nama Supplier</p>
            <p>:</p>
            <p>{dataPdf.distributor_name}</p>
          </div>
          <div className="flex gap-2 w-full">
            <p className="w-1/2">Tanggal</p>
            <p>:</p>
            <p>{moment(dataPdf.created_at).format("DD MMMM YYYY")}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2 w-full">
            <p className="w-1/2">No. Telepon</p>
            <p>:</p>
            <p>{dataPdf.distributor_phone}</p>
          </div>
          <div className="flex gap-2 w-full">
            <p className="w-1/2">No. PO</p>
            <p>:</p>
            <p>{dataPdf.sp_no}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2 w-1/2 pr-2">
            <p className="w-1/2">Alamat</p>
            <p>:</p>
            <p>{dataPdf.distributor_address}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="text-[12px]">
      <LoadingBackdrop isLoading={isPrint} />
      <div className="flex w-full gap-[10px] items-center mb-4">
        <TextField
          fullWidth
          placeholder="Cari Data"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth>
          <InputLabel>Periode PO</InputLabel>
          <Select
            name="category_id"
            label="Periode"
            value={periodDummy}
            onChange={(e) => {
              if (e.target.value === 9) {
                setOpenCalendar(true);
                return;
              } else if (e.target.value !== 10) {
                setOpenCalendar(false);
              }
              setPeriod(e.target.value);
              setReqBody((prevValue) => ({
                ...prevValue,
                sort: e.target.value,
                start_date: "",
                end_date: "",
              }));
              setPeriodDummy(e.target.value);
            }}
          >
            <MenuItem value={0}>Semua Periode</MenuItem>
            <MenuItem value={1}>Hari ini</MenuItem>
            <MenuItem value={2}>Kemarin</MenuItem>
            <MenuItem value={4}>Minggu Berjalan</MenuItem>
            <MenuItem value={3}>Minggu Lalu</MenuItem>
            <MenuItem value={5}>Bulan Berjalan</MenuItem>
            <MenuItem value={6}>Bulan Lalu</MenuItem>
            <MenuItem value={7}>Tahun Berjalan</MenuItem>
            <MenuItem value={8}>Tahun Lalu</MenuItem>
            <MenuItem value={9}>Pilih Tanggal</MenuItem>
            <MenuItem value={10} disabled sx={{ display: "none" }}>
              {moment(reqBody.start_date).format("DD/MM/YYYY") +
                " - " +
                moment(reqBody.end_date).format("DD/MM/YYYY")}
            </MenuItem>
          </Select>
        </FormControl>
        <Select
          value={reqBody.type}
          onChange={(e) =>
            setReqBody((prevVal) => ({ ...prevVal, type: e.target.value }))
          }
          sx={{ width: "50%" }}
        >
          <MenuItem value={0}>Semua Status</MenuItem>
          <MenuItem value={1}>Open</MenuItem>
          <MenuItem value={2}>Closed</MenuItem>
          <MenuItem value={3}>Partial</MenuItem>
        </Select>
        <button
          className="bg-blue-500 text-white w-1/2 flex items-center justify-between p-[10px] rounded-[12px] text-[14px] hover:bg-gray-300"
          type="button"
          onClick={() => navigate("/purchase-order/create-po")}
        >
          Buat PO
          <IoMdAddCircleOutline />
        </button>
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal PO
                </th>
                <th className="p-2 text-center whitespace-nowrap">Nomor PO</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Distributor
                </th>
                <th className="p-2 text-center whitespace-nowrap">Produk</th>
                <th className="p-2 text-center whitespace-nowrap">Status</th>
                <th className="p-2 text-center whitespace-nowrap">Total</th>
                <th className="p-2 text-center whitespace-nowrap">
                  No Invoice
                </th>
                <th className="p-2 text-center whitespace-nowrap">Action</th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.data ? (
                  dataList.data.map((item, i) => (
                    <tr className="border" key={i}>
                      <td className="p-2 text-center">
                        {(reqBody.offset - 1) * reqBody.limit + (i + 1)}
                      </td>
                      <td className="p-2 text-center">
                        {moment(item.po_date).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-2 text-center">
                        <a
                          className="w-full text-blue-500 cursor-pointer hover:text-gray-300"
                          href={`/purchase-order/detail-po/${btoa(item.id)}-${
                            item.is_partial ? 1 : 0
                          }`}
                        >
                          {item.po_number}
                        </a>
                      </td>
                      <td className="p-2 text-center">
                        {item.distributor_name}
                      </td>
                      <td className="p-2 text-left">
                        <div className="w-full flex justify-between gap-4 items-center">
                          <p>{item.product_name[0]}</p>
                          {item.product_name.length > 1 ? (
                            <p
                              className="text-blue-500 cursor-pointer"
                              onMouseEnter={(e) => {
                                setMoreProductArr(item.product_name.slice(1));
                                setAnchorElMoreProduct(e.currentTarget);
                              }}
                              onMouseLeave={() => setAnchorElMoreProduct(null)}
                            >
                              +{item.product_name.length - 1} Lainnya
                            </p>
                          ) : null}
                        </div>
                      </td>
                      <td
                        className={`p-2 text-center font-bold ${
                          !item.get_purchase_order_billing_lists ||
                          item.get_purchase_order_billing_lists.length === 0
                            ? "text-green-500"
                            : item.is_partial
                            ? "text-orange-500"
                            : "text-black"
                        }`}
                      >
                        {!item.get_purchase_order_billing_lists ||
                        item.get_purchase_order_billing_lists.length === 0
                          ? "Open"
                          : item.is_partial
                          ? "Partial"
                          : "Closed"}
                      </td>
                      <td className="p-2 text-center">
                        {item.total.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </td>
                      <td className="p-2 text-center">
                        {item.get_purchase_order_billing_lists?.length > 0
                          ? item.get_purchase_order_billing_lists.map((trx) => (
                              <a
                                className=" text-blue-500 cursor-pointer hover:text-gray-300 block"
                                href={`/purchase-order/detail-invoice/${trx.po_id}/${trx.billing_id}`}
                              >
                                {trx.invoice}
                              </a>
                            ))
                          : "-"}
                      </td>
                      <td className="p-2 text-center">
                        <div className="w-full flex gap-2 items-center">
                          <button
                            className="bg-gray-100 text-gray-500 border-2 rounded-md w-full hover:bg-gray-300 p-1 disabled:bg-gray-300 disabled:text-white"
                            type="button"
                            disabled={
                              selectedPo &&
                              selectedPo.po_number === item.po_number &&
                              fetchingPdf
                            }
                            onClick={() => {
                              setSelectedPo(item);
                              setIsPrint(true);
                            }}
                          >
                            {selectedPo &&
                            selectedPo.po_number === item.po_number &&
                            isPrint &&
                            fetchingPdf ? (
                              <CircularProgress
                                size={10}
                                className="text-white"
                              />
                            ) : (
                              "Print"
                            )}
                          </button>
                          <a
                            className={`border-2 rounded-md w-full p-1  ${
                              (!item.get_purchase_order_billing_lists ||
                                item.get_purchase_order_billing_lists.length ===
                                  0) &&
                              !item.is_partial
                                ? "bg-gray-100 text-gray-500 hover:bg-gray-300"
                                : "bg-gray-300 text-white cursor-default"
                            }`}
                            href={
                              (!item.get_purchase_order_billing_lists ||
                                item.get_purchase_order_billing_lists.length ===
                                  0) &&
                              !item.is_partial
                                ? `/purchase-order/edit/${btoa(item.id)}-${
                                    item.is_partial ? 1 : 0
                                  }`
                                : null
                            }
                            onClick={() => {
                              if (
                                (!item.get_purchase_order_billing_lists ||
                                  item.get_purchase_order_billing_lists
                                    .length === 0) &&
                                !item.is_partial
                              )
                                navigate(
                                  `/purchase-order/edit/${btoa(item.id)}-${
                                    item.is_partial ? 1 : 0
                                  }`
                                );
                            }}
                          >
                            Edit
                          </a>
                          <button
                            className="bg-red-100 border-red-500 text-red-500 border-2 rounded-md w-full hover:bg-gray-300 p-1 disabled:border-none disabled:bg-gray-300 disabled:text-white"
                            disabled={
                              !(
                                !item.get_purchase_order_billing_lists ||
                                item.get_purchase_order_billing_lists.length ===
                                  0
                              )
                            }
                            onClick={() => {
                              setSelectedPo(item);
                              setOpenDelete(true);
                            }}
                            type="button"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border">
                    <td className="p-2 text-center" colSpan={9}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada data"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && !isLoading && dataList.total > 0 ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={dataList.total}
        />
      ) : null}

      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
      />

      <ModalDeletePo
        open={openDelete}
        setOpen={setOpenDelete}
        po={selectedPo}
        setPo={setSelectedPo}
        refetch={() => {
          setReqBody(INITIAL_REQ);
          mutatePoList(INITIAL_REQ);
        }}
      />

      <Popper
        placement="bottom"
        open={Boolean(anchorElMoreProduct)}
        anchorEl={anchorElMoreProduct}
        sx={{ maxWidth: "200px" }}
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            // options: {
            //   element: arrowRef,
            // },
          },
        ]}
      >
        <div className="bg-white text-[10px] p-2 rounded-md border text-wrap whitespace-wrap overflow-hidden break-words">
          {moreProductArr.map((item, i) => `${i + 1}. ${item}`).join(", ")}
        </div>
      </Popper>

      <div className="hidden">
        <PrintNotaPo
          dataLabel={dataLabel}
          dataList={dataPdf ? [...dataPdf.product] : []}
          printRef={printRef}
          title="Surat Pesanan"
          tableContent={[
            {
              header: "No",
              key: "INDEX",
            },
            {
              header: "Nama Obat",
              key: "product_name",
            },
            {
              header: "Kemasan",
              key: "product_unit",
            },
            {
              header: "Kuantitas",
              key: "product_quantity",
            },
          ]}
          dataPdf={dataPdf ? dataPdf : null}
        />
      </div>

      <Dialog
        open={openCalendar}
        onClose={() => {
          setOpenCalendar(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent sx={{ fontSize: 12 }}>
          <div className="mb-2 w-full">
            <DatePicker
              onChange={(dates) => {
                const [start, end] = dates;
                setReqBody((prevVal) => ({
                  ...prevVal,
                  start_date: start ? moment(start).format("YYYY-MM-DD") : "",
                  end_date: end ? moment(end).format("YYYY-MM-DD") : "",
                }));
              }}
              startDate={
                reqBody.start_date == "" ? null : new Date(reqBody.start_date)
              }
              endDate={
                reqBody.end_date === "" ? null : new Date(reqBody.end_date)
              }
              selectsRange={true}
              inline
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="flex justify-center gap-2 px-2">
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <FaChevronLeft />
                  </button>
                  <select
                    className="p-2 px-4 rounded-md cursor-pointer hover:text-white"
                    value={new Date(date).getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {YEARS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    className="p-2 px-4 rounded-md cursor-pointer hover:text-white"
                    value={MONTHS[new Date(date).getMonth()]}
                    onChange={({ target: { value } }) =>
                      changeMonth(MONTHS.indexOf(value))
                    }
                  >
                    {MONTHS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              )}
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 w-full"
            onClick={() => {
              mutatePoList(reqBody);
              setOpenCalendar(false);
              setPeriod(9);
              setPeriodDummy(10);
            }}
          >
            Pilih Tanggal
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PurchaseOrderList;
