import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CiFilter } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { BsPrinter } from "react-icons/bs";
import { RiArrowGoBackLine } from "react-icons/ri";
import TabNavigationReturSell from "./TabNavigationReturSell";
import {
  fetchReturListSellPOS,
  getDetailReturPOS,
} from "../../../services/billingAPI";
import useDebounce from "../../hooks/useDebounce";
import PagePagination from "../../common/particles.jsx/PagePagination";
import PrintNota from "../../common/particles.jsx/PrintNota";
import { getPreviewInvoice } from "../../../services/ReportingApi";
import { useReactToPrint } from "react-to-print";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";

const STATUS = [
  { id: 1, value: "Berhasil", bgColor: "#E8E8E8", textColor: "#000" },
  { id: 2, value: "Retur", bgColor: "#F1EBE5", textColor: "#FF8D40" },
  { id: 3, value: "Dibatalkan", bgColor: "#F1EBE5", textColor: "#F21B3F" },
];

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const ReturSellPOS = () => {
  const navigate = useNavigate();
  const printRef = useRef();
  const { pageSetting } = useParams();

  const [reqBody, setReqBody] = useState({
    search: "",
    start_date: "",
    end_date: "",
    limit: 10,
    offset: 1,
    status: [1, 2, 3],
    period: 0,
  });
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [periodDummy, setPeriodDummy] = useState(0);
  const [returId, setReturId] = useState(null);

  const debounce = useDebounce(reqBody.search, 500);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setReturId(null),
  });

  const navigateTo = (
    newOffset,
    newLimit,
    newStart,
    newEnd,
    newStatus,
    newPeriod
  ) => {
    navigate(
      `/retur/sell-retur/pos/offset=${newOffset}&limit=${newLimit}&start_date=${newStart}&end_date=${newEnd}&period=${newPeriod}&status=${encodeURIComponent(
        newStatus
      )}`
    );
  };

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: fetchReturListSellPOS,
  });

  const { data: dataLabel } = useQuery({
    queryKey: ["label-print"],
    queryFn: getPreviewInvoice,
  });

  const { data: dataDetail, isFetching } = useQuery({
    queryKey: ["detail-retur-pos", returId],
    queryFn: () => getDetailReturPOS(returId),
    enabled: returId !== null,
  });

  const returPrintData = useMemo(() => {
    return dataDetail && dataDetail.product
      ? dataDetail.product
          .filter(
            (item) =>
              !dataDetail.product_retur?.find(
                (returItem) => returItem.product_id === item.product_id
              ) ||
              dataDetail.product_retur?.find(
                (returItem) => returItem.product_id === item.product_id
              )?.quantity < item.quantity
          )
          .map((item) => {
            return {
              ...item,
              quantity: dataDetail.product_retur?.find(
                (returItem) => returItem.product_id === item.product_id
              )
                ? item.quantity -
                  dataDetail.product_retur?.find(
                    (returItem) => returItem.product_id === item.product_id
                  ).quantity
                : item.quantity,
            };
          })
      : [];
  }, [dataDetail]);

  useEffect(() => {
    if (pageSetting) {
      var limitSetting,
        offsetSetting,
        period,
        startDate,
        endDate,
        statusSetting;

      pageSetting.split("&").map((item) => {
        var val = item.split("=");
        if (val[0] === "limit") limitSetting = val[1];
        if (val[0] === "offset") offsetSetting = val[1];
        if (val[0] === "period") period = val[1];
        if (val[0] === "start_date") startDate = val[1];
        if (val[0] === "end_date") endDate = val[1];
        if (val[0] === "status")
          statusSetting = decodeURIComponent(val[1])
            .split(",")
            .map((item) => {
              return Number(item);
            });
      });

      setReqBody((prevVal) => ({
        ...prevVal,
        limit: limitSetting ? Number(limitSetting) : 50,
        offset: offsetSetting ? Number(offsetSetting) : 1,
        period: period ? Number(period) : 0,
        start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        status:
          statusSetting && statusSetting !== ""
            ? statusSetting.length > 0 &&
              !(statusSetting.length === 1 && statusSetting[0] === 0)
              ? statusSetting
              : []
            : [],
      }));
    }
  }, [pageSetting]);

  useEffect(() => {
    mutateList({ ...reqBody, search: debounce });
  }, [reqBody, debounce]);

  useEffect(() => {
    if (dataDetail) handlePrint();
  }, [dataDetail]);

  const NotaInfo = () => {
    return (
      <div className="mb-4">
        <div className="flex gap-3 items-start">
          <div className="flex gap-2 w-full">
            <p className="w-1/2">No Transaksi</p>
            <p>:</p>
            <p>{dataDetail.transaction_number}</p>
          </div>
          <div className="flex gap-2 w-full">
            <p className="w-1/2">Sales</p>
            <p>:</p>
            <p>{dataDetail.sales_name === "" ? "-" : dataDetail.sales_name}</p>
          </div>
        </div>
        <div className="flex gap-3 items-start">
          <div className="flex gap-2 w-1/2 pr-2">
            <p className="w-1/2">Tanggal Transaksi</p>
            <p>:</p>
            <p>{moment(dataDetail.created_at).format("DD MMMM YYYY")}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2 w-1/2 pr-2">
            <p className="w-1/2">Member</p>
            <p>:</p>
            <p>
              {dataDetail.member_name === "" ? "-" : dataDetail.member_name}
            </p>
          </div>
          <div className="flex gap-2 w-1/2 pr-2">
            <p className="w-1/2">Metode Pembayaran</p>
            <p>:</p>
            <p>{dataDetail.payment_method}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="text-[12px]">
      <LoadingBackdrop isLoading={returId !== null} />
      <TabNavigationReturSell />
      <div className="flex items-center gap-2 mb-4">
        <TextField
          placeholder="Cari berdasarkan nama member & no. transaksi"
          value={reqBody.search}
          onChange={(e) =>
            setReqBody((prevVal) => ({
              ...prevVal,
              search: e.target.value,
              offset: 1,
            }))
          }
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <div
          className="border rounded-md flex items-center justify-between gap-4 w-fit py-2.5 px-2 text-gray-500 cursor-pointer hover:bg-gray-100"
          onClick={(e) => setAnchorElStatus(e.currentTarget)}
        >
          <div className="flex items-center gap-2 whitespace-nowrap">
            <CiFilter className="text-[18px]" />{" "}
            {reqBody.status.length === STATUS.length
              ? "All Status"
              : reqBody.status.length < STATUS.length &&
                reqBody.status.length > 1
              ? "Multiple Status"
              : reqBody.status.length === 1
              ? STATUS.find((item) => item.id === reqBody.status[0])?.value
              : "Status"}
          </div>
          <div className="flex items-center gap-2">
            {reqBody.status.length < STATUS.length &&
            reqBody.status.length > 1 ? (
              <div className="p-[4px] w-[18px] h-[18px] flex justify-center items-center rounded-full bg-gray-200">
                {reqBody.status.length}
              </div>
            ) : null}
            <FaChevronDown />
          </div>
        </div>
        <Select
          value={periodDummy}
          onChange={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              period: e.target.value === 10 ? 9 : e.target.value,
              start_date: "",
              end_date: "",
            }));
            if (e.target.value === 9) {
              setOpenCalendar(true);
              return;
            } else if (e.target.value !== 10) {
              navigateTo(
                reqBody.offset,
                reqBody.limit,
                "",
                "",
                reqBody.status,
                e.target.value
              );
              setOpenCalendar(false);
              setStartDate(null);
              setEndDate(null);
            }
            setPeriodDummy(e.target.value);
          }}
        >
          <MenuItem value={0}>Semua Periode</MenuItem>
          <MenuItem value={1}>Hari ini</MenuItem>
          <MenuItem value={2}>Kemarin</MenuItem>
          <MenuItem value={4}>Minggu Berjalan</MenuItem>
          <MenuItem value={3}>Minggu Lalu</MenuItem>
          <MenuItem value={6}>Bulan Berjalan</MenuItem>
          <MenuItem value={5}>Bulan Lalu</MenuItem>
          <MenuItem value={8}>Tahun Berjalan</MenuItem>
          <MenuItem value={7}>Tahun Lalu</MenuItem>
          <MenuItem value={9}>Pilih Tanggal</MenuItem>
          <MenuItem value={10} disabled sx={{ display: "none" }}>
            {moment(reqBody.start_date).format("DD/MM/YYYY") +
              " - " +
              moment(reqBody.end_date).format("DD/MM/YYYY")}
          </MenuItem>
        </Select>
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">Tanggal</th>
                <th className="p-2 text-center whitespace-nowrap">
                  No. Transaksi
                </th>
                <th className="p-2 text-center whitespace-nowrap">Status</th>
                <th className="p-2 text-center whitespace-nowrap">Catatan</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Nominal Retur
                </th>
                <th className="p-2 text-center whitespace-nowrap">Action</th>
              </thead>
              <tbody className="border">
                {dataList &&
                !isLoading &&
                dataList.data &&
                dataList.count > 0 ? (
                  dataList.data.map((item, i) => (
                    <tr className="border" key={i}>
                      <td className="p-2 text-center">
                        {(reqBody.offset - 1) * reqBody.limit + (i + 1)}
                      </td>
                      <td className="p-2 text-center">
                        {moment(item.created_at).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-2 text-center text-blue-500 hover:text-gray-300 cursor-pointer">
                        <a
                          href={`/retur/sell-retur/pos/detail-retur/${item.id}`}
                        >
                          {item.transaction_number}
                        </a>
                      </td>
                      <td className="p-2 text-center font-bold">
                        <div
                          className={`rounded-full p-1 ${
                            "bg-[" +
                            STATUS.find((stat) => stat.id === item.status)
                              ?.bgColor +
                            "]"
                          } ${
                            "text-[" +
                            STATUS.find((stat) => stat.id === item.status)
                              ?.textColor +
                            "]"
                          }`}
                        >
                          {" "}
                          {
                            STATUS.find((stat) => stat.id === item.status)
                              ?.value
                          }
                        </div>
                      </td>
                      <td className="p-2 text-center">{item.notes}</td>
                      <td className="p-2 text-center">
                        {toIDR(item.total_retur)}
                      </td>
                      <td className="p-2 text-center bg-gray-100">
                        <div className="w-full flex gap-3 items-center justify-center">
                          <button
                            className="disabled:text-gray-400 text-black hover:text-blue-500"
                            type="button"
                            disabled={item.status === 3}
                            onClick={() => setReturId(item.id)}
                          >
                            <BsPrinter className="text-[16px]" />
                          </button>
                          <a
                            className={` rounded-md w-fit py-1 px-3 flex gap-2 items-center ${
                              item.status > 1 || item.is_new
                                ? " bg-gray-300 text-white cursor-default"
                                : "bg-blue-500 text-white hover:bg-gray-300 cursor-pointer"
                            }`}
                            href={
                              item.status > 1 || item.is_new
                                ? null
                                : `/retur/sell-retur/pos/create-retur/${item.id}`
                            }
                          >
                            Retur
                            <RiArrowGoBackLine />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-2 text-center border" colSpan={7}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada data"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && dataList.count > 0 && dataList.data !== null ? (
        <>
          <div className="mb-4">
            <PagePagination
              offset={reqBody.offset}
              setOffsetMemory={(limit, offset) => {
                navigateTo(
                  offset,
                  limit,
                  reqBody.start_date,
                  reqBody.end_date,
                  reqBody.status,
                  reqBody.period
                );
              }}
              limit={reqBody.limit}
              setLimit={(e) => {
                navigateTo(
                  1,
                  e,
                  reqBody.start_date,
                  reqBody.end_date,
                  reqBody.status,
                  reqBody.period
                );
              }}
              total={dataList.count}
            />
          </div>
        </>
      ) : null}

      <Popover
        open={Boolean(anchorElStatus)}
        anchorEl={anchorElStatus}
        onClose={() => setAnchorElStatus(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="w-full">
          <div
            className={`py-2 px-3 whitespace-nowrap w-full flex gap-2 items-center cursor-pointer hover:text-gray-300`}
            onClick={() => {
              navigateTo(
                1,
                reqBody.limit,
                reqBody.start_date,
                reqBody.end_date,
                reqBody.status.length < STATUS.length
                  ? STATUS.map((stat) => stat.id)
                  : [],
                reqBody.period
              );
              setReqBody((prevVal) => ({
                ...prevVal,
                status:
                  reqBody.status.length < STATUS.length
                    ? STATUS.map((stat) => stat.id)
                    : [],
              }));
            }}
          >
            <input
              type="checkbox"
              checked={
                reqBody.status.length === STATUS.length &&
                reqBody.status.length > 0
              }
              className="cursor-pointer w-[16px] h-[16px]"
            />
            Semua
          </div>
          {STATUS.map((item, i) => (
            <div
              className={`py-2 px-3 whitespace-nowrap w-full border-t flex gap-2 items-center cursor-pointer hover:text-gray-300`}
              key={i}
              onClick={() => {
                const index = reqBody.status.findIndex(
                  (stat) => stat === item.id
                );
                const newStatus =
                  index < 0
                    ? [...reqBody.status, item.id]
                    : [
                        ...reqBody.status.slice(0, index),
                        ...reqBody.status.slice(index + 1),
                      ];
                navigateTo(
                  1,
                  reqBody.limit,
                  reqBody.start_date,
                  reqBody.end_date,
                  newStatus,
                  reqBody.period
                );
                setReqBody((prevVal) => ({
                  ...prevVal,
                  status: newStatus,
                }));
              }}
            >
              <input
                type="checkbox"
                className="cursor-pointer w-[16px] h-[16px]"
                checked={reqBody.status.includes(item.id)}
              />
              {item.value}
            </div>
          ))}
        </div>
      </Popover>

      <Dialog
        open={openCalendar}
        onClose={() => {
          setOpenCalendar(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              selected={startDate}
              onChange={(dates) => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold w-full"
            onClick={() => {
              setOpenCalendar(false);
              setPeriodDummy(10);
              setReqBody((prevVal) => ({
                ...prevVal,
                start_date: startDate
                  ? moment(startDate).format("YYYY-MM-DD")
                  : "",
                end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
              }));
              navigateTo(
                reqBody.offset,
                reqBody.limit,
                startDate ? moment(startDate).format("YYYY-MM-DD") : "",
                endDate ? moment(endDate).format("YYYY-MM-DD") : "",
                reqBody.status,
                reqBody.period
              );
            }}
          >
            Konfirmasi
          </button>
        </DialogContent>
      </Dialog>

      <div className="hidden">
        <PrintNota
          dataLabel={dataLabel}
          data={dataDetail}
          dataList={returPrintData}
          printRef={printRef}
          title="Nota Retur POS"
          tableContent={[
            {
              header: "No",
              key: "INDEX",
            },
            {
              header: "Produk",
              key: "product_name",
            },
            {
              header: "Kemasan",
              key: "packaging",
            },
            {
              header: "Qty",
              key: "quantity",
            },
            {
              header: "Harga satuan",
              key: "unit_price",
              type: "currency",
            },
            {
              header: "Total Harga",
              key: "total_price",
              type: "currency",
            },
          ]}
          totalPrice={dataDetail ? dataDetail.total_payment : 0}
          NotaInfo={NotaInfo}
          noNote={true}
          noFooter={true}
          footer={
            <div className="w-ful flex justify-end mt-4">
              <div className="w-1/2 flex gap-2 items-end">
                <div className="w-full">
                  <div className="mb-2">
                    <div className="flex items-center gap-2">
                      <p className="w-1/2">Subtotal</p>
                      <p>:</p>
                      <p>
                        {toIDR(
                          dataDetail
                            ? returPrintData.reduce(
                                (acc, num) => acc + num.total_price,
                                0
                              )
                            : 0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="flex items-center gap-2">
                      <p className="w-1/2">Biaya Lain-lain</p>
                      <p>:</p>
                      <p>{toIDR(dataDetail ? dataDetail.other_cost : 0)}</p>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="flex items-center gap-2">
                      <p className="w-1/2">Diskon</p>
                      <p>:</p>
                      <p>{toIDR(dataDetail ? dataDetail.discount : 0)}</p>
                    </div>
                  </div>
                  <div className="mb-2 text-blue-500 bg-gray-100 rounded-md p-2 font-bold">
                    <div className="flex items-center gap-2">
                      <p className="w-1/2">Total Pembayaran</p>
                      <p>:</p>
                      <p>
                        {toIDR(
                          dataDetail
                            ? returPrintData.reduce(
                                (acc, num) => acc + num.total_price,
                                0
                              )
                            : 0
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ReturSellPOS;
