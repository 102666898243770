import { Box, CircularProgress, Modal } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { BsTrash } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { setReturPOS } from "../../../../services/billingAPI";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalCancelCreatePOS = ({
  open,
  setOpen,
  formik,
  isCreate,
  errorMessage,
  setErrorMessage,
}) => {
  const navigate = useNavigate();

  const handleClose = () => {
    setErrorMessage("");
    setOpen(false);
  };

  const handleSave = () => {
    if (!isCreate) navigate(`/retur/sell-retur/pos`);
    else mutateNewRetur(formik.values);
  };

  const { mutate: mutateNewRetur, isLoading } = useMutation({
    mutationFn: setReturPOS,
    onSuccess: () => navigate(`/retur/sell-retur/pos`),
    onError: (err) => {
      setErrorMessage(err.message.id ? err.message.id : err.massages);
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "50vh",
          padding: 0,
        }}
        className="text-left text-[14px] w-3/4 lg:w-1/3"
      >
        <div className="flex justify-between items-center px-8 py-4">
          <div className="w-12 h-12 bg-red-100 p-1 rounded-full">
            <div className="w-full h-full bg-red-300 p-2 rounded-full">
              <BsTrash className="w-full h-full text-red-600" />
            </div>
          </div>
          <RxCross1
            className="cursor-pointer hover:text-red-500"
            onClick={handleClose}
          />
        </div>

        <div className="px-8 mb-4">
          <p className="font-bold text-lg mb-2">
            {isCreate &&
            formik.values.discount === 0 &&
            formik.values.other_cost === 0
              ? "Retur Penjualan"
              : "Batalkan Transaksi"}
          </p>
          <p>
            Apa Anda yakin ingin{" "}
            {isCreate &&
            formik.values.discount === 0 &&
            formik.values.other_cost === 0
              ? "melakukan retur penjualan"
              : "membatalkan transaksi"}{" "}
            dengan no. transaksi{" "}
            <span className="font-bold text-nowrap whitespace-nowrap">
              {formik.values.transaction_number}
            </span>{" "}
            ?
          </p>
          <p className="mt-2 text-red-500">{errorMessage}</p>
        </div>

        <div className="px-8 mb-4 flex gap-2 items-center">
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-red-500 bg-red-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300"
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={10} />
            ) : (
              `Ya, ${
                isCreate &&
                formik.values.discount === 0 &&
                formik.values.other_cost === 0
                  ? "Retur Penjualan"
                  : "Batalkan Transaksi"
              }`
            )}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalCancelCreatePOS;
