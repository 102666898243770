import React from "react";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../components/constants/muiTheme";
import BreadcrumbRetur from "../../components/screen/Retur/BreadcrumbRetur";
import CreateReturSellPOS from "../../components/screen/Retur/CreateReturSellPOS";

const CreateReturSellPOSPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Buat Retur Penjualan"
        mainComponent={
          <ThemeProvider theme={theme}>
            <CreateReturSellPOS />
          </ThemeProvider>
        }
        breadcrumb={<BreadcrumbRetur />}
        pageRole={47}
      />
    </div>
  );
};

export default HOC(CreateReturSellPOSPage);
