import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  IconButton,
} from "@mui/material";

import {
  NotificationsActive as NotificationsActiveIcon,
  NotificationsOff as NotificationsOffIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getDetailInventory,
  changeStatusReminderStock,
} from "../../../../services/InventoryAPI";
import BreadcrumbInventory from "../BreadcrumbInventory";

const DetailProductHold = ({ isWarehouse }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [reminderStockMessage, setReminderStockMessage] = useState("");
  const [open, setOpen] = useState(false);

  const { data } = useQuery({
    queryKey: ["detail_product"],
    queryFn: () => getDetailInventory(id, isWarehouse ? isWarehouse : false),
  });

  const { mutate: changeStatusStock } = useMutation({
    mutationFn: changeStatusReminderStock,
    onSuccess: () => {
      queryClient.invalidateQueries(["detail_product"]);
      setReminderStockMessage("status berhasil diganti");
    },
  });

  const reminderTypeChecker = (status) => {
    if (data && data !== undefined) {
      if (status === 0) return data.packing?.type_box;
      if (status === 1) return data.packing?.type_strip;
      if (status === 2) return data.packing?.type_unit;
    }
  };

  return (
    <>
      {data && data !== undefined ? (
        <div className="container mx-auto w-full mt-16 lg:mt-0">
          <BreadcrumbInventory />
          <div className="flex justify-between">
            <button
              onClick={() =>
                changeStatusStock({
                  product_id: id,
                  warehouse: isWarehouse ? isWarehouse : false,
                })
              }
            >
              {data.status_reminder_stock ? (
                <NotificationsActiveIcon
                  fontSize="large"
                  sx={{ color: "#4169E1" }}
                />
              ) : (
                <NotificationsOffIcon fontSize="large" sx={{ color: "gray" }} />
              )}
            </button>

            <div className="flex flex-row justify-center lg:justify-end mt-10 p-3 gap-3">
              <button className="bg-orange-500 hover:bg-grey-200 py-2 px-[28px] rounded-lg inline-flex items-center gap-3 font-medium text-white">
                <Link
                  to={`${
                    isWarehouse ? "/central-warehouse" : ""
                  }/inventory/produk-hold/edit-product-details/${id}`}
                  className="hover:text-white"
                >
                  Edit Information
                </Link>
              </button>
            </div>
          </div>
          <h1 className="bg-[#838383] text-white text-center py-2 font-medium mt-5">
            Produk Ditahan
          </h1>
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="flex flex-col w-full">
              <div className="flex flex-wrap w-full justify-center lg:justify-start mt-10 gap-3">
                <div className="flex flex-col">
                  {data.media === null ? (
                    <p>Masukin no image disini</p>
                  ) : (
                    <>
                      <img
                        src={data.media[0]}
                        alt=""
                        className="w-[200px] border-2 rounded-lg shadow-md"
                      />
                      <div className="flex flex-row gap-2 mt-2">
                        <img
                          src={data.media[0]}
                          alt=""
                          className="w-[60px] border-2 rounded-lg"
                        />
                        <img
                          src={data.media[0]}
                          alt=""
                          className="w-[60px] border-2 rounded-lg"
                        />
                        <img
                          src={data.media[0]}
                          alt=""
                          className="w-[60px] border-2 rounded-lg"
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="flex flex-col gap-4 items-center lg:items-start p-4">
                  <div className="flex justify-between items-center w-full">
                    <p className="font-bold text-[20px]">{data.product_name}</p>
                  </div>
                  <p className="font-medium">
                    Jumlah Produk{" "}
                    <span className="bg-gray-300 font-medium p-2 rounded-full">
                      {data.packaging_detail}
                    </span>
                  </p>
                  <p
                    className="text-[#007AF1] hover:cursor-pointer font-semibold"
                    onClick={() => setOpen(true)}
                  >
                    Detail Stock
                  </p>

                  <div className="flex flex-row items-center gap-3">
                    <p className="font-semibold">Label Produk</p>
                    <p className="bg-blue-700 text-white px-3 py-[6px] rounded-xl">
                      {data.label_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="items-end font-bold text-[30px] p-5">
              Rp.{isWarehouse ? data.hna : data.price}
            </p>
          </div>
          {/* {isWarehouse ? null : (
            <>
              {" "}
              <div className="flex flex-col shadow-md mt-3 rounded-lg">
                <div className="flex flex-wrap justify-center p-3 rounded-t-lg bg-green-300 font-bold">
                  <p>Harga Jual</p>
                </div>
                <div className="flex justify-between p-3">
                  <div className="bg-white font-bold m-3">
                    <p>Harga Akhir</p>
                    <p className="mt-2">Harga Jual Primer</p>
                    <p className="my-2">Harga Jual Sekunder</p>
                    <p>Harga Jual Tersier</p>
                  </div>
                  <div className="bg-white font-bold m-3">
                    <p>:</p>
                    <p className="mt-2">:</p>
                    <p className="my-2">:</p>
                    <p>:</p>
                  </div>
                  <div className="bg-white font-bold m-3">
                    <p>
                      {data.hna?.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                    <p className="mt-2">
                      {data.price?.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                    <p className="my-2">
                      {data.price_sekunder?.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                    <p>
                      {data.price_tersier?.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <h1 className="bg-green-300 text-black font-semibold py-[10px] text-center mt-4 rounded-t-lg">
                Harga Jual Platform
              </h1>
              <div className="flex flex-wrap justify-evenly p-2 rounded-lg font-semibold shadow-md">
                <div className="flex flex-col justify-center">
                  {data && data.platform_price !== null ? (
                    <>
                      {data.platform_price.map((p) => (
                        <div
                          className="flex flex-col justify-center"
                          key={p.platform_id}
                        >
                          <p key={p.platform_id}>
                            {p.name} : Rp. {p.price}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
            </>
          )} */}
          <div className="shadow-lg rounded-lg">
            <h1 className="text-[25px] font-medium mt-10 p-3">
              Deskripsi Produk
            </h1>
            <hr className="mt-2" />
            <div className="flex flex-col p-3">
              <div className="my-3">
                <p className="font-bold">Indikasi / Kegunaan</p>
                <p>{data.description.indication}</p>
              </div>
              <div className="my-3">
                <p className="font-bold">Kandungan / Komposisi</p>
                <p>{data.description.composition}</p>
              </div>
              <div className="my-3">
                <p className="font-bold">Dosis</p>
                <p>{data.description.dosage}</p>
              </div>
              <div className="my-3">
                <p className="font-bold">Cara Pemakaian</p>
                <p>{data.description.usage}</p>
              </div>
              <div className="my-3">
                <p className="font-bold">Kemasan</p>
                <p>
                  {data.packing.total_box} {data.packing.type_box}
                  {", "}
                  {data.packing.total_strip} {data.packing.type_strip}
                  {", "}
                  {data.packing.total_unit} {data.packing.type_unit}
                </p>
              </div>
              <div className="my-3">
                <p className="font-bold">Kategori Produk</p>
                <p>
                  {data.product_category_name
                    ? data.product_category_name[0]
                    : ""}
                </p>
              </div>
              <div className="my-3">
                <p className="font-bold">Perlu Resep</p>
                {data.description.require_prescription ? (
                  <p>Perlu</p>
                ) : (
                  <p>Tidak Perlu</p>
                )}
              </div>
              <div className="my-3">
                <p className="font-bold">
                  Kontraindikasi / Jangan digunakan oleh
                </p>
                <p>{data.description.contraindication}</p>
              </div>
              <div className="my-3">
                <p className="font-bold">Efek Samping</p>
                <p>{data.description.side_effect}</p>
              </div>
              <div className="my-3">
                <p className="font-bold">Cara Penyimpanan</p>
                <p>{data.description.storage_description}</p>
              </div>
              <div className="my-3">
                <p className="font-bold">Principal</p>
                <p>{data.product_principal_name}</p>
              </div>
              <div className="my-3">
                <p className="font-bold">Nomor Izin Edar (NIE)</p>
                <p>{data.nie_number}</p>
              </div>
              <div className="my-3">
                <p className="font-bold">Barcode</p>
                {data.barcode.length !== 0 ? <p>{data.barcode}</p> : <p>-</p>}
              </div>
              <div className="my-3">
                <p className="font-bold">Pengingat Product Habis</p>
                {data.status_reminder_stock ? (
                  <p>
                    {" "}
                    Pengingat aktif ketika stock mencapai {
                      data.reminder_stock
                    }{" "}
                    {reminderTypeChecker(data.reminder_uom)}
                  </p>
                ) : (
                  <p>Tidak Aktif</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>tidak ditemukan!</p>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle sx={{ display: "flex" }}>
          <p className="text-center w-full">Detail Stock</p>
          <button onClick={() => setOpen(false)}>
            <CloseIcon
              sx={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            />
          </button>
        </DialogTitle>
        <DialogContent>
          {data !== undefined && data.batch_details !== undefined ? (
            <TableContainer>
              <Table
                sx={{ width: "100%" }}
                aria-label="a dense table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Nomor Batch
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Tanggal Input
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Kadaluarsa
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Stock
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.batch_details.map((batch, index) => (
                    <TableRow key={index} sx={{ width: "100%" }}>
                      <TableCell component="th" scope="row" align="center">
                        {batch.number_batch}
                      </TableCell>
                      <TableCell align="center">
                        {batch.tanggal_input}
                      </TableCell>
                      <TableCell align="center">{batch.expired_date}</TableCell>
                      <TableCell align="center">
                        {batch.packaging_detail}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(reminderStockMessage)}
        autoHideDuration={5000}
        onClose={() => setReminderStockMessage("")}
        message={reminderStockMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") return;
                setReminderStockMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </>
  );
};

export default DetailProductHold;
