import { Box, CircularProgress, Modal, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { MdWarningAmber } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useEffect, useState } from "react";
import {
  setNewMarketplaceBlibli,
  setNewMarketplaceTokopedia,
} from "../../../../services/oms";
import LogoTokopedia from "../../../../assets/icons/logoTokopedia.svg";
import LogoBlibli from "../../../../assets/icons/logoBlibli.svg";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const MARKETPLACE = [
  { id: 1, name: "Tokopedia" },
  { id: 2, name: "Blibli" },
];

const INITIAL_TOKOPEDIA = { shop_id: 0 };

const INITIAL_BLIBLI = {
  store_code: "",
  username: "",
  api_seller_key: "",
};

const ModalAddMarketplace = ({ open, setOpen, refetch, blibliCode }) => {
  const [selectMarketplace, setSelectMarketplace] = useState(null);
  const [reqTokopedia, setReqTokopedia] = useState(INITIAL_TOKOPEDIA);
  const [reqBlibli, setReqBlibli] = useState({
    ...INITIAL_BLIBLI,
    partner_id: blibliCode,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = () => {
    if (selectMarketplace === 1) mutateTokopedia(reqTokopedia);
    if (selectMarketplace === 2) mutateBlibli(reqBlibli);
  };

  const { mutate: mutateTokopedia, isLoading: isLoadingTokopedia } =
    useMutation({
      mutationFn: setNewMarketplaceTokopedia,
      onSuccess: () => {
        setSuccessMessage("Berhasil menambah toko tokopedia");
        refetch();
        handleClose();
      },
      onError: (err) => {
        setErrorMessage(err.id);
      },
    });

  const { mutate: mutateBlibli, isLoading: isLoadingBlibli } = useMutation({
    mutationFn: setNewMarketplaceBlibli,
    onSuccess: () => {
      setSuccessMessage("Berhasil menambah toko tokopedia");
      refetch();
      handleClose();
    },
    onError: (err) => {
      setErrorMessage(err.id);
    },
  });

  useEffect(() => {
    if (open) {
      setReqBlibli({ ...INITIAL_BLIBLI, partner_id: blibliCode });
      setReqTokopedia(INITIAL_TOKOPEDIA);
      setErrorMessage("");
      setSuccessMessage("");
    }
  }, [open]);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/2"
        >
          <div className="px-8 py-4 ">
            <div className="mb-4 w-full flex items-center justify-between">
              <div
                className={`w-[56px] h-[56px] rounded-full p-[8px] flex items-center justify-center bg-blue-100`}
              >
                <div
                  className={`w-full h-full rounded-full p-[8px] flex items-center justify-center bg-blue-300`}
                >
                  <MdWarningAmber className={`w-full h-full text-blue-700`} />
                </div>
              </div>
              <RxCross1
                className="cursor-pointer hover:text-red-500 w-fit"
                onClick={handleClose}
              />
            </div>

            <div className="mb-4">
              <p className="mb-2 font-bold text-[18px]">
                {selectMarketplace
                  ? MARKETPLACE.find((item) => item.id === selectMarketplace)
                      .name + " Marketplace"
                  : "Tambah Partner"}
              </p>
              <p className="mb-4">
                {selectMarketplace
                  ? `Silahkan hubungkan Toko ${
                      MARKETPLACE.find((item) => item.id === selectMarketplace)
                        .name
                    } Anda dengan mengisi form yang dibutuhkan dibawah ini`
                  : "Silahkan pilih terlebih dahulu Partner yang ingin Anda tambahkan"}
              </p>
              <div>
                {selectMarketplace ? (
                  selectMarketplace === 1 ? (
                    <>
                      <TextField
                        fullWidth
                        placeholder="ID Toko"
                        value={
                          reqTokopedia.shop_id === 0 ? "" : reqTokopedia.shop_id
                        }
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value)))
                            setReqTokopedia({
                              shop_id: Number(e.target.value),
                            });
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        placeholder="Username"
                        sx={{ marginBottom: 2 }}
                        value={reqBlibli.username}
                        onChange={(e) =>
                          setReqBlibli((prevValue) => ({
                            ...prevValue,
                            username: e.target.value,
                          }))
                        }
                      />
                      <TextField
                        fullWidth
                        placeholder="Kode Toko"
                        sx={{ marginBottom: 2 }}
                        value={reqBlibli.store_code}
                        onChange={(e) =>
                          setReqBlibli((prevValue) => ({
                            ...prevValue,
                            store_code: e.target.value,
                          }))
                        }
                      />
                      <TextField
                        fullWidth
                        placeholder="Seller Key"
                        value={reqBlibli.api_seller_key}
                        onChange={(e) =>
                          setReqBlibli((prevValue) => ({
                            ...prevValue,
                            api_seller_key: e.target.value,
                          }))
                        }
                      />
                    </>
                  )
                ) : (
                  <div className="flex gap-2 justify-center">
                    <div
                      className="w-[100px] h-[100px] border-2 rounded-md p-3 cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        setSelectMarketplace(1);
                        setReqBlibli({
                          ...INITIAL_BLIBLI,
                          partner_id: blibliCode,
                        });
                        setReqTokopedia(INITIAL_TOKOPEDIA);
                      }}
                    >
                      <img src={LogoTokopedia} className="w-full h-full" />
                    </div>
                    <div
                      className="w-[100px] h-[100px] border-2 rounded-md p-3 cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        setSelectMarketplace(2);
                        setReqBlibli({
                          ...INITIAL_BLIBLI,
                          partner_id: blibliCode,
                        });
                        setReqTokopedia(INITIAL_TOKOPEDIA);
                      }}
                    >
                      <img src={LogoBlibli} className="w-full h-full" />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <p className="text-red-500 mb-4">{errorMessage}</p>

            {selectMarketplace ? (
              <div className="flex gap-2 items-center">
                <button
                  className="w-full p-2 rounded-md border-2 disabled:bg-gray-300 hover:bg-gray-100"
                  onClick={() => {
                    setSelectMarketplace(null);
                    setErrorMessage("");
                  }}
                  disabled={isLoadingTokopedia || isLoadingBlibli}
                >
                  Batal
                </button>
                <button
                  className={`w-full p-2 rounded-md border-none text-white disabled:bg-gray-300 bg-blue-500 hover:bg-gray-300`}
                  onClick={handleChangeStatus}
                  disabled={isLoadingTokopedia || isLoadingBlibli}
                >
                  {isLoadingTokopedia || isLoadingBlibli ? (
                    <CircularProgress size={10} />
                  ) : (
                    "Simpan"
                  )}
                </button>
              </div>
            ) : null}
          </div>
        </Box>
      </Modal>
      <ErrorPopup
        errorMessage={successMessage}
        setErrorMessage={setSuccessMessage}
      />
    </div>
  );
};

export default ModalAddMarketplace;
