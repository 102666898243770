import React from "react";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";
import BreadcrumbPurchaseOrder from "../../components/screen/purchaseOrder/BreadcrumbPurchaseOrder";
import AddPurchaseOrder from "../../components/screen/purchaseOrder/AddPurchaseOrder";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../components/constants/muiTheme";

const PurchaseOrder = ({ isEdit }) => {
  return (
    <div className="">
      <PageTemplate
        header={`${isEdit ? "Edit" : "Add"} Purchase Order Manual`}
        breadcrumb={<BreadcrumbPurchaseOrder />}
        mainComponent={
          <ThemeProvider theme={theme}>
            <AddPurchaseOrder isEdit={isEdit} />
          </ThemeProvider>
        }
        pageRole={9}
        noPadding={true}
      />
    </div>
  );
};

export default HOC(PurchaseOrder);
