import Cookies from "js-cookie";
import axios from "axios";

const token = () => Cookies.get("accessTokenOfficeOlin");

export const getOMSList = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ORDER}order-new`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getOMSDetail = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ORDER}order/detail?order_id=${id}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const cancelOrder = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ORDER}order/cancel`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const acceptOrder = async ({ id, partner }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_ORDER}order/accept-new?order_id=${id}&partner_code=${partner}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getCancelReason = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ORDER}order/cancel-reason`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const rejectOrder = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ORDER}order/cancel`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const sendOrder = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ORDER}order/deliver`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getOrderTree = async ({ id, partner }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ORDER}order/tree?order_id=${id}&partner_code=${partner}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const downloadShippingLabel = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "application/json",
      },
      responseType: "blob",
      method: "POST",
      url: `${process.env.REACT_APP_API_ORDER}order/shipping-label`,
      data: body,
    };
    const res = await axios(config);

    const blob = new Blob([res.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(blob);

    window.open(fileURL);
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getOMSSetting = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ORDER}order/setting`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const setMarketplaceStatus = async ({ activate, partnerCode }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_ORDER}order/settings/store?activate=${activate}&partner_code=${partnerCode}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const setNewMarketplaceBlibli = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ORDER}order/settings/blibli`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const setNewMarketplaceTokopedia = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ORDER}order/settings/tokopedia`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const setScheduleOms = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_ORDER}order/settings/schedule`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const deleteMarketplaceOms = async (partner) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "DELETE",
      url: `${process.env.REACT_APP_API_ORDER}order/settings/store?partner_code=${partner}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};
