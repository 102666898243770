import { Box, CircularProgress, Modal } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { MdWarningAmber } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { setMarketplaceStatus } from "../../../../services/oms";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const deactivateColor = {
  background: "#FFF1E8",
  foreground: "#FED2B5",
  primary: "#FF8D40",
};

const ModalChangeMarketplace = ({
  open,
  setOpen,
  marketplace,
  setMarketplace,
  refetch,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = () => {
    setMarketplace(null);
    setOpen(false);
  };

  const handleChangeStatus = () => {
    mutateStatus({
      activate: !marketplace.is_active,
      partnerCode: marketplace.partner_code,
    });
  };

  const { mutate: mutateStatus, isLoading } = useMutation({
    mutationFn: setMarketplaceStatus,
    onSuccess: () => {
      setSuccessMessage("Berhasil mengubah status toko");
      refetch();
      handleClose();
    },
    onError: (err) => setErrorMessage(err.id),
  });

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/2"
        >
          <div className="px-8 py-4 ">
            <div className="mb-4 w-full flex items-center justify-between">
              <div
                className={`w-[56px] h-[56px] rounded-full p-[8px] flex items-center justify-center ${
                  marketplace && marketplace.is_active
                    ? "bg-[" + deactivateColor.background + "]"
                    : "bg-blue-100"
                }`}
              >
                <div
                  className={`w-full h-full rounded-full p-[8px] flex items-center justify-center ${
                    marketplace && marketplace.is_active
                      ? "bg-[" + deactivateColor.foreground + "]"
                      : "bg-blue-300"
                  }`}
                >
                  <MdWarningAmber
                    className={`w-full h-full ${
                      marketplace && marketplace.is_active
                        ? "text-[" + deactivateColor.primary + "]"
                        : "text-blue-700"
                    }`}
                  />
                </div>
              </div>
              <RxCross1
                className="cursor-pointer hover:text-red-500 w-fit"
                onClick={handleClose}
              />
            </div>

            <div className="mb-4">
              <p className="mb-2 font-bold text-[18px]">
                {marketplace && marketplace.is_active
                  ? "Nonaktifkan Marketplace"
                  : "Aktifkan Marketplace Kembali"}
              </p>
              <p>
                Apakah anda yakin ingin{" "}
                {marketplace && marketplace.is_active
                  ? "menonaktifkan"
                  : "mengaktifkan kembali"}{" "}
                marketplace {marketplace?.name} {marketplace?.store_name}{" "}
              </p>
            </div>

            <p className="text-red-500 mb-4">{errorMessage}</p>

            <div className="flex gap-2 items-center">
              <button
                className="w-full p-2 rounded-md border-2 disabled:bg-gray-300 hover:bg-gray-100"
                onClick={handleClose}
                disabled={isLoading}
              >
                Batal
              </button>
              <button
                className={`w-full p-2 rounded-md border-none text-white disabled:bg-gray-300 ${
                  marketplace && marketplace.is_active
                    ? "bg-[" + deactivateColor.primary + "]"
                    : "bg-blue-500"
                } hover:bg-gray-300`}
                onClick={handleChangeStatus}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : marketplace?.is_active ? (
                  "Nonaktifkan"
                ) : (
                  "Aktifkan"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ErrorPopup
        errorMessage={successMessage}
        setErrorMessage={setSuccessMessage}
      />
    </div>
  );
};

export default ModalChangeMarketplace;
