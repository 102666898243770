import { Search } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { RxCross1 } from "react-icons/rx";
import {
  getProductForRetur,
  getProductForReturSell,
} from "../../../../services/billingAPI";
import { useEffect, useState } from "react";
import moment from "moment";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import { IGNORE_TYPE } from "../../../constants/constant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const ModalAddProductRetur = ({ open, setOpen, formik, isSell }) => {
  const [tempProductArr, setTempProductArr] = useState([]);
  const [reqBody, setReqBody] = useState({
    search: "",
    offset: 1,
    limit: 10,
  });

  const handleClose = () => {
    setReqBody((prevVal) => ({
      ...prevVal,
      search: "",
      offset: 1,
      limit: 10,
    }));
    setTempProductArr([]);
    setOpen(false);
  };

  const handleSave = () => {
    var filteredArr = tempProductArr.filter((item) => item.quantity > 0);
    if (!isSell)
      filteredArr = filteredArr.map((item) => ({
        ...item,
        dpp: getDppPrice(
          item.pharmacy_net_price_discount,
          item.pharmacy_net_price,
          item.pharmacy_net_price_ppn_value
        ),
      }));

    formik.setFieldValue("data_product", filteredArr);
    formik.setFieldValue(
      "total_price",
      filteredArr.reduce((acc, num) => acc + num.total_price, 0)
    );
    handleClose();
  };

  const findProduct = (item) => {
    return tempProductArr.find((prod) =>
      isSell
        ? prod.product_new_id === item.product_new_id
        : prod.product_new_id === item.product_new_id &&
          prod.iteration === item.iteration
    );
  };

  const handleDpp = (ppnVal) => {
    return ppnVal ? (ppnVal === 11 ? 11 : (11 / 12) * 12) : 0;
  };

  const getDppPrice = (discountPercent, pharmacyNetPriceReal, taxValue) => {
    if (!pharmacyNetPriceReal) return 0;
    let discount =
      pharmacyNetPriceReal * (discountPercent ? discountPercent / 100 : 0);
    return (pharmacyNetPriceReal - discount) * (taxValue === 12 ? 11 / 12 : 1);
  };

  const handleAdd = (product) => {
    const index = tempProductArr.findIndex(
      (item) => item.product_new_id === product.product_new_id
    );

    const appendItem = {
      invoice_number: product.invoice_number,
      tax_number: product.tax_number,
      product_name: product.product_name,
      pharmacy_net_price: product.pharmacy_net_price,
      sell_price: product.sell_price,
      sell_price_sekunder: product.sell_price_sekunder,
      sell_price_tersier: product.sell_price_tersier,
      batch_number: product.batch_number,
      expired_date: product.expired_date,
      pharmacy_net_price_discount: product.pharmacy_net_price_discount,
      pharmacy_net_price_ppn: product.pharmacy_net_price_ppn,
      pharmacy_net_price_ppn_value: product.pharmacy_net_price_ppn_value,
      pharmacy_net_price_real: product.pharmacy_net_price_real,
      product_id: product.product_detail_id,
      product_new_id: product.product_new_id,
      type_packaging: product.packaging.type_strip,
      type_uom: product.packaging.type_uom ? product.packaging.type_uom : 1,
      note_number: "",
      quantity: 0,
      retur_quantity: product.product_new_stock,
      total_price: 0,
    };

    setTempProductArr(
      index < 0
        ? [
            ...tempProductArr,
            isSell
              ? appendItem
              : { ...appendItem, iteration: product.iteration },
          ]
        : [
            ...tempProductArr.slice(0, index),
            ...tempProductArr.slice(index + 1),
          ]
    );
  };

  const handleChangeValue = (type, val, item) => {
    var tempArr = [...tempProductArr];
    const index = tempProductArr.findIndex((prod) =>
      isSell
        ? prod.product_new_id === item.product_new_id
        : prod.product_new_id === item.product_new_id &&
          prod.iteration === item.iteration
    );

    if (index >= 0) {
      tempArr[index][type] = val;
    }

    setTempProductArr(tempArr);
  };

  const findStock = (item, uom) => {
    return findProduct(item)
      ? findProduct(item).type_uom === 0 || (uom && uom === 0)
        ? item.product_new_stock /
          (item.packaging.total_strip *
            (IGNORE_TYPE.includes(item.packaging.type_unit)
              ? 1
              : item.packaging.total_unit))
        : findProduct(item).type_uom === 1 || (uom && uom === 1)
        ? IGNORE_TYPE.includes(item.packaging.type_unit)
          ? item.product_new_stock
          : item.product_new_stock / item.packaging.total_unit
        : item.product_new_stock
      : item.product_new_stock;
  };

  const findPricePerUnit = (item) => {
    return findProduct(item)
      ? findProduct(item).type_uom === 0
        ? 1
        : findProduct(item).type_uom === 1
        ? item.packaging.total_strip
        : item.packaging.total_unit * item.packaging.total_strip
      : IGNORE_TYPE.includes(item.packaging.type_unit)
      ? item.packaging.total_strip
      : item.packaging.total_unit * item.packaging.total_strip;
  };

  const getPrice = (item) => {
    return item
      ? isSell
        ? findProduct(item)
          ? findProduct(item).type_uom === 0
            ? item.sell_price
            : findProduct(item).type_uom === 1
            ? item.sell_price_sekunder
            : findProduct(item).type_uom === 2
            ? item.sell_price_tersier
            : 0
          : findProduct(item)
          ? findProduct(item).type_packaging.toUpperCase()
          : IGNORE_TYPE.includes(item.packaging.type_unit)
          ? item.sell_price_sekunder
          : item.sell_price_tersier
        : item.pharmacy_net_price / findPricePerUnit(item)
      : 0;
  };

  const {
    mutate: mutateProduct,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: isSell ? getProductForReturSell : getProductForRetur,
  });

  useEffect(() => {
    setReqBody((prevVal) => ({
      ...prevVal,
      distributor_id: formik.values.distributor_id,
    }));
  }, [formik.values.distributor_id]);

  useEffect(() => {
    if (open) {
      isSell
        ? mutateProduct({ ...reqBody, customer_id: formik.values.customer_id })
        : mutateProduct({
            ...reqBody,
            distributor_id: formik.values.distributor_id,
          });
    }
  }, [open, reqBody]);

  useEffect(() => {
    setTempProductArr(formik.values.data_product);
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "90%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "80vh",
          padding: 0,
        }}
        className="text-left text-[14px] w-full"
      >
        <div className="flex justify-between items-center px-8 py-4 text-center">
          <p className="text-lg font-bold w-full">Pilih Produk</p>
          <RxCross1
            className="cursor-pointer hover:text-red-500 w-fit"
            onClick={handleClose}
          />
        </div>

        <div className="px-8 mb-4">
          <div className="flex gap-2 mb-2">
            <TextField
              fullWidth
              placeholder="Cari data"
              value={reqBody.search}
              onChange={(e) =>
                setReqBody((prevVal) => ({
                  ...prevVal,
                  search: e.target.value,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <button
              type="button"
              className="w-fit bg-blue-500 text-white flex items-center justify-between px-4 py-2.5 gap-8 rounded-xl hover:bg-gray-300 disabled:bg-gray-300"
              onClick={handleSave}
            >
              Simpan
            </button>
          </div>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  //   checked={formik.values.role.includes(item.id)}
                  //   onChange={() => handleSelectRole(item.id)}
                  inputProps={{
                    "aria-label": "controlled",
                  }}
                />
              }
              sx={{ marginBottom: 2 }}
              label="Select all"
            />
          </FormGroup>

          <div className="flex w-full mb-8">
            <div className="w-full">
              <div className="block overflow-x-auto w-full rounded-md">
                <table className="w-full overflow-x-auto rounded-md border-collapse">
                  <thead className="bg-blue-500 text-white">
                    <th className="p-2 text-center"></th>
                    <th className="p-2 text-center">No</th>
                    <th className="p-2 text-center whitespace-nowrap">
                      No. Invoice
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      No. Faktur Pajak
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Produk
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">Batch</th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Expired Date
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Kemasan
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">Stock</th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Kuantitas
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      {isSell ? "Harga" : "HNA Satuan"}
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Diskon (%)
                    </th>
                    {isSell ? null : (
                      <th className="p-2 text-center whitespace-nowrap">
                        PPN (%)
                      </th>
                    )}
                    {isSell ? null : (
                      <th className="p-2 text-center whitespace-nowrap">DPP</th>
                    )}
                    <th className="p-2 text-center whitespace-nowrap">
                      Harga Akhir
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Subtotal
                    </th>
                  </thead>
                  <tbody className="border">
                    {dataList &&
                    !isLoading &&
                    dataList.data &&
                    dataList.count > 0 ? (
                      dataList.data.map((item, i) => (
                        <tr key={i} className={`${i === 0 ? "" : "border-t"}`}>
                          <td className="p-2 text-center">
                            <Checkbox
                              onChange={() => handleAdd(item)}
                              checked={findProduct(item)}
                            />
                          </td>
                          <td className="p-2 text-center">
                            {(reqBody.offset - 1) * reqBody.limit + (i + 1)}
                          </td>
                          <td className="p-2 text-center">
                            {item.invoice_number}
                          </td>
                          <td className="p-2 text-center">
                            {item.tax_number === "" ? "-" : item.tax_number}
                          </td>
                          <td className="p-2 text-center">
                            {item.product_name}
                          </td>
                          <td className="p-2 text-center">
                            {item.batch_number}
                          </td>
                          <td className="p-2 text-center">
                            {moment(item.expired_date).format("DD/MM/YYYY")}{" "}
                          </td>
                          <td className="p-2 text-center">
                            <Select
                              sx={{ width: "100%" }}
                              // defaultValue={1}
                              value={
                                findProduct(item)
                                  ? findProduct(item).type_uom
                                  : 1
                              }
                              onChange={(e) => {
                                handleChangeValue(
                                  "type_packaging",
                                  e.target.value === 0
                                    ? item.packaging.type_box
                                    : e.target.value === 1
                                    ? item.packaging.type_strip
                                    : item.packaging.type_unit,
                                  item
                                );
                                handleChangeValue(
                                  "type_uom",
                                  e.target.value,
                                  item
                                );
                                handleChangeValue("quantity", 0, item);
                                handleChangeValue("total_price", 0, item);
                              }}
                              disabled={
                                !tempProductArr
                                  .map((prod) => prod.product_new_id)
                                  .includes(item.product_new_id)
                              }
                            >
                              {
                                //item.type_uom === 0 &&
                                // isSell &&
                                item.product_new_stock /
                                  (item.packaging.total_strip *
                                    (IGNORE_TYPE.includes(
                                      item.packaging.type_unit
                                    )
                                      ? 1
                                      : item.packaging.total_unit)) <
                                1 ? null : (
                                  <MenuItem value={0}>
                                    {item.packaging.type_box}
                                  </MenuItem>
                                )
                              }
                              <MenuItem value={1}>
                                {item.packaging.type_strip}
                              </MenuItem>
                              {IGNORE_TYPE.includes(
                                item.packaging.type_unit
                              ) ? null : (
                                <MenuItem value={2}>
                                  {item.packaging.type_unit}
                                </MenuItem>
                              )}
                            </Select>
                          </td>
                          <td className="p-2 text-center">
                            {findStock(item)}{" "}
                            {findProduct(item)
                              ? findProduct(item).type_packaging.toUpperCase()
                              : IGNORE_TYPE.includes(item.packaging.type_unit)
                              ? item.packaging.type_strip.toUpperCase()
                              : item.packaging.type_unit.toUpperCase()}
                          </td>
                          <td className="p-2 text-center">
                            <TextField
                              fullWidth
                              value={
                                findProduct(item)
                                  ? findProduct(item).quantity
                                  : 0
                              }
                              onChange={(e) => {
                                if (!isNaN(Number(e.target.value))) {
                                  var stock =
                                    Number(e.target.value) > findStock(item)
                                      ? findStock(item)
                                      : Number(e.target.value);
                                  handleChangeValue("quantity", stock, item);
                                  handleChangeValue(
                                    "pharmacy_net_price",
                                    item.pharmacy_net_price /
                                      findPricePerUnit(item),
                                    item
                                  );
                                  handleChangeValue(
                                    "pharmacy_net_price_real",
                                    isSell
                                      ? (((item.pharmacy_net_price /
                                          findPricePerUnit(item)) *
                                          (100 -
                                            item.pharmacy_net_price_discount)) /
                                          100) *
                                          (isSell
                                            ? 1
                                            : 1 +
                                              handleDpp(
                                                item.pharmacy_net_price_ppn_value
                                              ) /
                                                100)
                                      : (item.pharmacy_net_price /
                                          findPricePerUnit(item)) *
                                          ((100 -
                                            item.pharmacy_net_price_discount) /
                                            100) +
                                          +getDppPrice(
                                            item.pharmacy_net_price_discount,
                                            item.pharmacy_net_price /
                                              findPricePerUnit(item),
                                            item.pharmacy_net_price_ppn_value
                                          ) *
                                            (item.pharmacy_net_price_ppn_value /
                                              100),
                                    item
                                  );
                                  handleChangeValue(
                                    "total_price",
                                    isSell
                                      ? (stock *
                                          (getPrice(item) *
                                            (100 -
                                              item.pharmacy_net_price_discount))) /
                                          100
                                      : stock *
                                          ((item.pharmacy_net_price /
                                            findPricePerUnit(item)) *
                                            ((100 -
                                              item.pharmacy_net_price_discount) /
                                              100) +
                                            getDppPrice(
                                              item.pharmacy_net_price_discount,
                                              item.pharmacy_net_price /
                                                findPricePerUnit(item),
                                              item.pharmacy_net_price_ppn_value
                                            ) *
                                              (item.pharmacy_net_price_ppn_value /
                                                100)),
                                    item
                                  );
                                }
                              }}
                              disabled={
                                !tempProductArr
                                  .map((prod) => prod.product_new_id)
                                  .includes(item.product_new_id)
                              }
                            />
                          </td>
                          <td className="p-2 text-center">
                            {toIDR(
                              isSell
                                ? findProduct(item)
                                  ? findProduct(item).type_uom === 0
                                    ? item.sell_price
                                    : findProduct(item).type_uom === 1
                                    ? item.sell_price_sekunder
                                    : findProduct(item).type_uom === 2
                                    ? item.sell_price_tersier
                                    : 0
                                  : findProduct(item)
                                  ? findProduct(
                                      item
                                    ).type_packaging.toUpperCase()
                                  : IGNORE_TYPE.includes(
                                      item.packaging.type_unit
                                    )
                                  ? item.sell_price_sekunder
                                  : item.sell_price_tersier
                                : item.pharmacy_net_price /
                                    findPricePerUnit(item)
                            )}
                          </td>
                          <td className="p-2 text-center">
                            {item.pharmacy_net_price_discount}
                          </td>

                          {isSell ? null : (
                            <td className="p-2 text-center">
                              {item.pharmacy_net_price_ppn_value}
                            </td>
                          )}
                          {isSell ? null : (
                            <td className="p-2 text-center">
                              {toIDR(
                                getDppPrice(
                                  item.pharmacy_net_price_discount,
                                  item.pharmacy_net_price /
                                    findPricePerUnit(item),
                                  item.pharmacy_net_price_ppn_value
                                )
                              )}
                            </td>
                          )}
                          {isSell ? null : (
                            <td className="p-2 text-center">
                              {toIDR(
                                (item.pharmacy_net_price /
                                  findPricePerUnit(item)) *
                                  ((100 - item.pharmacy_net_price_discount) /
                                    100) +
                                  getDppPrice(
                                    item.pharmacy_net_price_discount,
                                    item.pharmacy_net_price /
                                      findPricePerUnit(item),
                                    item.pharmacy_net_price_ppn_value
                                  ) *
                                    (item.pharmacy_net_price_ppn_value / 100)
                              )}
                            </td>
                          )}
                          <td className="p-2 text-center">
                            {findProduct(item)
                              ? toIDR(findProduct(item).total_price)
                              : 0}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-2 text-center" colSpan={15}>
                          {isLoading ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Tidak ada produk"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {dataList && dataList.count > 0 && dataList.data !== null ? (
            <div className="mb-4">
              <PagePagination
                offset={reqBody.offset}
                setOffset={(e) =>
                  setReqBody((prevVal) => ({ ...prevVal, offset: e }))
                }
                limit={reqBody.limit}
                setLimit={(e) =>
                  setReqBody((prevVal) => ({ ...prevVal, limit: e }))
                }
                total={dataList.count}
              />
            </div>
          ) : null}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalAddProductRetur;
