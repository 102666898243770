import { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  IconButton,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import {
  getSavedOperationalNames,
  updateOperationalName,
  deleteOperationalName,
} from "../../../../services/operationalCostApi";

import Trash from "../../../../assets/icons/trash.svg";
import useDebounce from "../../../hooks/useDebounce";

const OperationalCostSavedName = (props) => {
  const queryClient = useQueryClient();
  const { FETCH_LIMIT } = props;
  const [searchOpName, setSearchOpName] = useState("");
  const [opName, setOpName] = useState({
    modalOpen: false,
    isDeleteModal: false,
    id: 0,
    operational_name: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const debounce = useDebounce(searchOpName, 500);

  const { data: savedOperationalNamesData } = useQuery({
    queryKey: ["op-name", debounce],
    queryFn: () => getSavedOperationalNames(FETCH_LIMIT, 1, debounce),
  });

  const { mutate: mutateUpdateOperationalName } = useMutation({
    mutationFn: updateOperationalName,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["op-name"] });
      setOpName(false);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { mutate: mutateDeleteOperationalName } = useMutation({
    mutationFn: deleteOperationalName,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["op-name"] });
      setOpName(false);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  return (
    <div>
      <TextField
        sx={{ width: "100%" }}
        value={searchOpName}
        onChange={(e) => setSearchOpName(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {savedOperationalNamesData &&
      savedOperationalNamesData !== undefined &&
      savedOperationalNamesData?.data !== null
        ? savedOperationalNamesData.data.map((data, index) => (
            <div
              key={index}
              className="w-full flex justify-between border border-black p-3  mt-3 cursor-pointer"
            >
              <div className="flex">
                <div>
                  <p className="font-bold">Nama Operational</p>
                  <p>{data.operational_name}</p>
                </div>
              </div>
              <div className="flex">
                <button
                  className="bg-blue-500 p-2 text-white rounded-md mr-3"
                  onClick={() => {
                    setOpName((prevValue) => ({
                      ...prevValue,
                      modalOpen: true,
                      isDeleteModal: false,
                      id: data.id,
                      operational_name: data.operational_name,
                    }));
                  }}
                >
                  <CreateIcon />
                </button>
                <button
                  className="bg-red-500 p-2 text-white rounded-md"
                  onClick={() => {
                    setOpName((prevValue) => ({
                      ...prevValue,
                      modalOpen: true,
                      isDeleteModal: true,
                      id: data.id,
                      operational_name: data.operational_name,
                    }));
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))
        : null}
      <Dialog
        fullWidth
        maxWidth="md"
        open={opName.modalOpen}
        onClose={() =>
          setOpName((prevValue) => ({
            ...prevValue,
            modalOpen: false,
          }))
        }
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {opName.isDeleteModal ? (
            <div className="flex flex-row justify-between items-center">
              <span>
                <span className="animate-ping absolute"></span>
                <img src={Trash} alt="" className=" w-16 animate-pulse" />
              </span>
              <p className="text-gray-500 text-[20px] cursor-pointer">X</p>
            </div>
          ) : (
            "Edit Nama Operational"
          )}
        </DialogTitle>
        <DialogContent>
          {opName.isDeleteModal ? (
            <div>
              <p className="font-bold text-[20px]">Hapus Nama Operational</p>
              <p className="text-start font-medium text-[20px]">
                Apakah anda yakin akan menghapus nama ini?
              </p>
            </div>
          ) : (
            <div>
              <div>
                <p>Nama Operational</p>
                <TextField
                  sx={{ width: "100%" }}
                  value={opName.operational_name}
                  onChange={(e) =>
                    setOpName((prevValue) => ({
                      ...prevValue,
                      operational_name: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          )}
          <div className="flex mt-3">
            <button
              className="w-full rounded-md border border-black py-2"
              onClick={() =>
                setOpName((prevValue) => ({
                  ...prevValue,
                  modalOpen: false,
                }))
              }
            >
              Batal
            </button>
            <button
              disabled={!opName.isDeleteModal && opName.operational_name === ""}
              className={`w-full rounded-md border border-black py-2 ${
                opName.isDeleteModal ? "bg-red-500" : "bg-blue-500"
              } text-white font-bold ml-2 disabled:bg-gray-300`}
              onClick={() => {
                if (opName.isDeleteModal) {
                  mutateDeleteOperationalName(opName.id);
                } else {
                  mutateUpdateOperationalName({
                    id: opName.id,
                    operational_name: opName.operational_name,
                  });
                }
              }}
            >
              {opName.isDeleteModal ? "Oke" : "Simpan"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage("");
        }}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  setErrorMessage("");
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default OperationalCostSavedName;
