import axios from "axios";
import Cookies from "js-cookie";

import { GeneratePublicToken } from "../utils/GeneratePublicToken";

const token = () => Cookies.get("accessTokenOfficeOlin");

// export const fetchReportingData = async ({
//   period,
//   method,
//   start_date,
//   end_date,
// }) => {
//   try {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token()}`,
//       },
//       url: `${process.env.REACT_APP_API_REPORTING}report/sales`,
//       method: "POST",
//       data: {
//         periode_sales: period,
//         method_sales: method,
//         start_date,
//         end_date,
//       },
//     };
//
//     const res = await axios(config);
//     return res.data.detail;
//   } catch (err) {
//     throw err;
//   }
// };

export const fetchReportingData = async ({
  period,
  method,
  start_date,
  end_date,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/sales-new`,
      method: "POST",
      data: {
        periode_sales: period,
        method_sales: method,
        start_date,
        end_date,
      },
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getReportingTable = async ({
  period,
  method,
  limit,
  offset,
  start_date,
  end_date,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/sales/table`,
      method: "POST",

      data: {
        periode_sales: period,
        method_sales: method,
        limit,
        offset,
        start_date,
        end_date,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchReportingPurchaseData = async ({
  period,
  method,
  start_date,
  end_date,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/purchase`,
      method: "POST",
      data: {
        periode_purchase: period,
        method_purchase: method,
        start_date,
        end_date,
      },
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchReportingPurchaseDataNew = async ({
  period,
  method,
  start_date,
  end_date,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/purchase-new`,
      method: "POST",
      data: {
        periode_purchase: period,
        method_purchase: method,
        start_date,
        end_date,
      },
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getReportingTablePurchase = async ({
  period,
  method,
  limit,
  offset,
  start_date,
  end_date,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/purchase/table`,
      method: "POST",
      data: {
        periode_purchase: period,
        method_purchase: method,
        limit,
        offset,
        start_date,
        end_date,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchDataPrincipal = async ({ limit, offset, name }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/principals`,
      method: "POST",
      data: {
        limit,
        offset,
        name,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchAllPrincipal = async ({ limit, offset, name }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/principals`,
      method: "POST",
      data: {
        limit,
        offset,
        name,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchTableReportingInventory = async ({ limit, offset, id }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },

      url: `${process.env.REACT_APP_API_REPORTING}report/inven/table`,
      method: "POST",
      data: {
        limit,
        offset,
        id,
      },
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getReportInventory = async (principalId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },

      url: `${process.env.REACT_APP_API_REPORTING}report/inven?id=${principalId}`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getTableInventory = async ({
  periode_sales,
  method_sales,
  limit,
  offset,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },

      url: `${process.env.REACT_APP_API_REPORTING}report/sales`,
      method: "POST",
      data: { periode_sales, method_sales, limit, offset },
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchReportingShiftTable = async ({
  limit,
  offset,
  periode_sales,
  staff_id,
  start_date,
  end_date,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },

      url: `${process.env.REACT_APP_API_REPORTING}report/shift/table`,
      method: "POST",
      data: { limit, offset, periode_sales, staff_id, start_date, end_date },
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getListStaff = async (limit, offset) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/list/staff?limit=${limit}&offset=${offset}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDetailShift = async (session_id, staff_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/shift/detail?session_id=${session_id}&staff_id=${staff_id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDetailShiftTable = async (
  session_id,
  staff_id,
  limit,
  offset
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/shift/detail/table?session_id=${session_id}&staff_id=${staff_id}&limit=${limit}&offset=${offset}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const sipnapTable = async ({
  kategori_obat,
  start_date,
  end_date,
  limit,
  offset,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/sipnap/table`,
      data: {
        kategori_obat,
        start_date,
        end_date,
        limit,
        offset,
      },
      method: "POST",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchSipnap = async ({
  kategori_obat,
  start_date,
  end_date,
  limit,
  offset,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/sipnap`,
      data: {
        kategori_obat,
        start_date,
        end_date,
        limit,
        offset,
      },
      method: "POST",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getInvoice = async (trx_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}get/invoice?trx_id=${trx_id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPreviewInvoice = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_POS}pos/preview/invoice`,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const createInvoiceLabel = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_POS}create/invoice`,
      data: body,
    };

    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getHistoryLog = async (limit, offset) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_REPORTING}report/history-stock?offset=${offset}&limit=${limit}`,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const fetchReportingProduct = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_REPORTING}report/product`,
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getListProductReporting = async (param) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_REPORTING}report/get-product`,
      data: param,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getReportingNew = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_REPORTING}report/inven-new?id=${id}`,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const fetchReportingProfitnLoss = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_REPORTING}report/pnl`,
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};
