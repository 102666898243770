import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import moment from "moment";
import {
  FaChevronDown,
  FaChevronRight,
  FaRegClock,
  FaSortAlphaDown,
} from "react-icons/fa";
import { RiFileList2Line } from "react-icons/ri";
import { MdOutlineChat } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";
import { useMutation, useQuery } from "@tanstack/react-query";
import TabNavigationOms from "./TabNavigationOms";
import {
  downloadShippingLabel,
  getOMSList,
  getOMSSetting,
} from "../../../services/oms";
import PagePagination from "../../common/particles.jsx/PagePagination";
import ModalDetailOms from "./modal/ModalDetailOms";
import ModalStatusOms from "./modal/ModalStatusOms";
import ModalPriceOms from "./modal/ModalPriceOms";
import useDebounce from "../../hooks/useDebounce";
import PopupRangeCalendar from "../../common/particles.jsx/PopupRangeCalendar";
import { MARKETPLACE } from "../../constants/constant";
import OmsReceiveDrawer from "./OmsReceiveDrawer";
import ModalTrackingNumber from "./modal/ModalTrackingNumber";
import ModalDoctorConcoction from "./modal/ModalDoctorConcoction";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";
import ErrorPopup from "../../common/particles.jsx/ErrorPopup";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const OMSList = () => {
  const { filter } = useParams();
  const navigate = useNavigate();

  const [reqBody, setReqBody] = useState({
    limit: 10,
    offset: 1,
    search: "",
    partner: MARKETPLACE.map((item) => item.partner_code),
    start: "",
    end: "",
    sort: 1,
  });
  const [openModalDetail, setOpenModalDetail] = useState(null);
  const [openModalStatus, setOpenModalStatus] = useState(null);
  const [openModalPrice, setOpenModalPrice] = useState(null);
  const [openModalSend, setOpenModalSend] = useState(null);
  const [openConcoction, setOpenConcoction] = useState(null);
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElMarketplace, setAnchorElMarketplace] = useState(null);
  const [openDrawerReceive, setOpenDrawerReceive] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [printArr, setPrintArr] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const debounce = useDebounce(reqBody.search, 500);

  const getStatusString = (status) => {
    return status === 4
      ? "Pesanan Baru"
      : status === 6
      ? "Siap Dikirim"
      : status === 8
      ? "Selesai"
      : status === 9
      ? "Dibatalkan"
      : status === 10
      ? "Dalam Pengiriman"
      : "";
  };

  const resetList = () => {
    const initialReq = {
      limit: 10,
      offset: 1,
      search: "",
      partner: MARKETPLACE.map((item) => item.partner_code),
      start: "",
      end: "",
      sort: 1,
    };

    if (JSON.stringify(reqBody) !== JSON.stringify(initialReq))
      setReqBody(initialReq);
    if (hasActiveStore()) mutateList({ ...initialReq, status: Number(filter) });
  };

  const isLabelPrintDisabled = () => {
    var isDisabled = false;
    const validId = listLabel.map((item) => item.order_id);

    if (printArr.length === 0 || (dataList && dataList.total_record === 0))
      isDisabled = true;

    printArr.map((item) => {
      if (!validId.includes(item)) isDisabled = true;
    });

    return isDisabled;
  };

  const hasActiveStore = () => {
    return dataSetting
      ? dataSetting.store.length > 0 &&
          dataSetting.store.some((item) => item.is_active)
      : false;
  };

  const {
    data: dataList,
    isLoading,
    mutate: mutateList,
  } = useMutation({
    mutationFn: getOMSList,
    onSuccess: () => {
      setPrintArr([]);
    },
  });

  const { isLoading: isLoadingLabel, mutate: mutateLabel } = useMutation({
    mutationFn: downloadShippingLabel,
    onError: (err) => {
      setErrorMessage(
        err.id && err.id === "" ? "Gagal menyetak label" : err.id
      );
    },
  });

  const { data: dataSetting, isFetching: isFetchingSetting } = useQuery({
    queryKey: ["oms-setting"],
    queryFn: () => getOMSSetting(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (hasActiveStore())
      mutateList({
        ...reqBody,
        search: debounce,
        status: Number(filter),
      });
  }, [
    reqBody.sort,
    reqBody.limit,
    reqBody.offset,
    reqBody.partner,
    reqBody.end,
    debounce,
    filter,
    dataSetting,
  ]);

  const listLabel = useMemo(() => {
    return dataList && dataList.data
      ? dataList.data?.filter(
          (item) => item.status !== 4 && item.status !== 9 && item.status !== 10
        )
      : [];
  }, [dataList]);

  useEffect(() => {
    if (!filter) navigate(`/oms/0`);
    resetList();
  }, [filter]);

  useEffect(() => {
    if (!openDrawerReceive && !openModalSend) setSelectedOrder(null);
  }, [openDrawerReceive, openModalSend]);

  return (
    <div className="text-[12px]">
      <LoadingBackdrop isLoading={isLoadingLabel} />
      <TabNavigationOms />
      <div className="flex items-center gap-2 mb-2 overflow-x-auto">
        <TextField
          placeholder="Cari produk"
          value={reqBody.search}
          onChange={(e) =>
            setReqBody((prevVal) => ({
              ...prevVal,
              search: e.target.value,
              offset: 1,
            }))
          }
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
          sx={{ minWidth: "40vw" }}
          disabled={!hasActiveStore()}
        />
        <div
          className={`w-fit p-2.5 md:p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 ${
            hasActiveStore()
              ? "hover:bg-gray-100 cursor-pointer"
              : "cursor-default"
          }`}
          onClick={(e) => {
            if (hasActiveStore()) setAnchorElCal(e.currentTarget);
          }}
        >
          <p className="whitespace-nowrap hidden md:block">
            {(reqBody.start === "") & (reqBody.end === "")
              ? "Semua Tanggal"
              : moment(reqBody.start).format("DD MMM YYYY") +
                "-" +
                moment(reqBody.end).format("DD MMM YYYY")}
          </p>
          <IoCalendarOutline />
        </div>
        <div
          className={`w-fit p-2 rounded-md border-2 flex gap-4 items-center text-gray-500 ${
            hasActiveStore()
              ? "hover:bg-gray-100 cursor-pointer"
              : "cursor-default"
          }`}
          onClick={(e) => {
            if (hasActiveStore()) setAnchorElSort(e.currentTarget);
          }}
        >
          <div className="flex gap-2 items-center">
            <FaSortAlphaDown />
            <p>{reqBody.sort === 1 ? "Terbaru" : "Terlama"}</p>
          </div>
          <FaChevronDown />
        </div>
        <div
          className={`w-fit p-2 rounded-md border-2 flex gap-4 items-center text-gray-500 ${
            hasActiveStore()
              ? "hover:bg-gray-100 cursor-pointer"
              : "cursor-default"
          }`}
          onClick={(e) => {
            if (hasActiveStore()) setAnchorElMarketplace(e.currentTarget);
          }}
        >
          <p>
            {reqBody.partner.length === 1
              ? MARKETPLACE.find(
                  (partner) => partner.partner_code === reqBody.partner[0]
                )?.name
              : "Marketplace"}
          </p>
          <div className="flex gap-2 items-center">
            {reqBody.partner.length !== 1 ? (
              <div className="bg-gray-300 rounded-full p-[8px] w-[20px] h-[20px] flex justify-center items-center text-[8px]">
                {reqBody.partner.length}
              </div>
            ) : null}
            <FaChevronDown />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2 mb-4 overflow-x-auto whitespace-nowrap">
        <FormControlLabel
          control={
            <Checkbox
              disabled={
                isLoading ||
                (dataList && dataList.total_record === 0) ||
                !hasActiveStore()
              }
              checked={
                dataList && !isLoading && dataList.total_record > 0
                  ? dataList.data.length === printArr.length
                  : false
              }
              onChange={() => {
                setPrintArr(
                  dataList &&
                    !isLoading &&
                    dataList.data.length === printArr.length
                    ? []
                    : dataList.data.map((item) => item.order_id)
                );
              }}
            />
          }
          label="Pilih Semua"
        />
        <button
          type="button"
          className="py-1 px-8 border-2 hover:bg-gray-200 rounded-md disabled:bg-gray-300 disabled:text-white"
          onClick={() => mutateLabel({ order_id: printArr })}
          disabled={isLabelPrintDisabled() || !hasActiveStore()}
        >
          Cetak Label
        </button>
        {/* <button
          type="button"
          className="py-1 px-8 border-2 hover:bg-gray-200 rounded-md disabled:bg-gray-300 disabled:text-white"
          disabled={true || printArr.length === 0 || !hasActiveStore()}
        >
          Cetak Invoice
        </button> */}
        <button
          type="button"
          className="py-1 px-8 border-2 hover:bg-gray-200 rounded-md disabled:bg-gray-300 disabled:text-white"
          disabled={true || printArr.length === 0 || !hasActiveStore()}
        >
          Cetak Salinan Resep
        </button>
      </div>

      {hasActiveStore() &&
      dataList &&
      !isLoading &&
      !isFetchingSetting &&
      dataList.total_record > 0 ? (
        dataList.data?.map((item, i) => (
          <div
            className="border-2 rounded-md mb-4 w-full overflow-x-auto"
            key={i}
          >
            <div className="w-full p-2 grid grid-cols-1 md:grid-cols-5 items-center justify-between">
              <div className="flex gap-2 items-center md:col-span-4 flex-wrap">
                <Checkbox
                  disabled={isLoading}
                  checked={printArr.includes(item.order_id)}
                  onChange={() => {
                    const index = printArr.findIndex(
                      (id) => id === item.order_id
                    );
                    setPrintArr(
                      index < 0
                        ? [...printArr, item.order_id]
                        : [
                            ...printArr.slice(0, index),
                            ...printArr.slice(index + 1),
                          ]
                    );
                  }}
                />
                <div className="flex gap-2 items-center border-r-2 pr-2">
                  <p
                    className={`text-white flex items-center gap-2 py-1 px-2 rounded-lg whitespace-nowrap ${
                      item.status === 4 || item.status === 10
                        ? "bg-[#FFB30F]"
                        : item.status === 8
                        ? "bg-green-500"
                        : item.status === 9
                        ? "bg-red-500"
                        : "bg-blue-300"
                    }`}
                  >
                    {getStatusString(item.status)}
                  </p>
                  <p>/</p>
                  <p
                    className="text-blue-500 font-bold text-truncate max-w-[30vw] cursor-pointer hover:underline"
                    onClick={() => setOpenModalDetail(item.order_id)}
                  >
                    {item.order_code}
                  </p>
                  {/* <p>/</p> */}
                  {/* <p>{moment().format("DD MMMM YYYY, hh:mm")}</p> */}
                </div>
                <div className="flex gap-2 items-center">
                  <p className="text-blue-500">Via</p>
                  <div className="w-[32px] h-[32px]">
                    <img
                      src={item.platform_logo}
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>
              </div>
              {item.created_at &&
              item.created_at !== "" &&
              (item.status === 4) | (item.status === 6) ? (
                <div className="whitespace-nowrap md:justify-items-end">
                  <div className="w-fit">
                    <p className="text-left">Batas respon</p>
                    <div className="bg-[#FFB30F] text-white flex items-center gap-2 py-1 px-2 rounded-lg">
                      <FaRegClock />
                      {moment(item.created_at)
                        .add(24, "h")
                        .format("DD MMM YYYY HH:mm")}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <hr className="text-gray-400 border-t-[2px] border-solid w-full" />
            <div className="w-full p-2 grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="w-full md:col-span-2 md:border-r-2 p-2">
                <div className="border-2 rounded-md p-3  flex items-start gap-4 mb-2">
                  <div className="w-[50px] h-[50px] md:w-[100px] md:h-[100px]">
                    <img
                      src={item.products[0].image}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <div className="w-full">
                    <p className="text-[16px] font-semibold mb-2">
                      {item.products[0]?.product_name}
                    </p>
                    <p>Jumlah: {item.products[0]?.quantity}</p>
                    <p className="text-[14px] font-semibold mb-2">
                      {toIDR(item.products[0]?.price)}
                    </p>
                    {/* show button if one of the products require resep dokter */}
                    {item.products.find((item) => item.is_okt) ? (
                      <button
                        type="button"
                        className="py-1 px-4 border-2 border-blue-500 text-blue-500 rounded-md w-fit hover:bg-gray-100 text-ellipsis"
                        onClick={() => setOpenConcoction(item.order_id)}
                      >
                        Lihat Resep Dokter
                      </button>
                    ) : null}
                  </div>
                  {item.products[0].is_okt ? (
                    <div className="w-fit text-right font-bold text-red-500 text-[14px]">
                      OKT
                    </div>
                  ) : null}
                </div>
                <p
                  className="text-right text-blue-500 font-semibold cursor-pointer hover:underline"
                  onClick={() => setOpenModalDetail(item.order_id)}
                >
                  {item.products.length > 1
                    ? `+${item.products.length - 1} Produk Lagi`
                    : "Lihat Detail"}
                </p>
              </div>
              <div className="w-full md:border-r-2 p-2">
                <p className="text-[16px] font-semibold">Alamat</p>
                <p className="mb-2">{item.customer_name}</p>
                <p>{item.address}</p>
              </div>
              <div className="w-full p-2">
                <p className="text-[16px] font-semibold">Kurir</p>
                <p>{item.shipment_name}</p>
              </div>
            </div>
            <hr className="text-gray-400 border-t-[2px] border-solid" />
            <div className="p-2 flex items-center justify-between font-bold text-[14px]">
              <p className="px-2">Total Penjualan</p>
              <div
                className="flex gap-2 items-center cursor-pointer hover:text-gray-300"
                onClick={() => setOpenModalPrice(item.order_id)}
              >
                <p>{toIDR(item.total_price)}</p>
                <FaChevronRight />
              </div>
            </div>
            <hr className="text-gray-400 border-t-[2px] border-solid" />
            <div className="p-2 flex items-center justify-between">
              <div className="flex gap-3 items-center">
                <button
                  type="button"
                  className="pl-2 pr-3 border-r-2 text-left cursor-pointer hover:text-gray-300"
                  onClick={() => setOpenModalStatus(item)}
                >
                  <div className="flex items-center gap-2">
                    <RiFileList2Line className="text-[14px]" />
                    <p>Detail Status</p>
                  </div>
                </button>
                <button
                  type="button"
                  className="text-left hover:text-gray-300 disabled:text-gray-300"
                  disabled={true}
                >
                  <div className="flex items-center gap-2">
                    <MdOutlineChat className="text-[14px]" />
                    <p>Chat Pembeli</p>
                  </div>
                </button>
              </div>
              {item.status === 4 || item.status === 6 ? (
                <button
                  type="button"
                  className="bg-blue-500 text-white rounded-md hover:bg-gray-300 py-1 px-4"
                  onClick={() => {
                    setSelectedOrder(item);
                    item.status === 4
                      ? setOpenDrawerReceive(true)
                      : setOpenModalSend(item.order_id);
                  }}
                >
                  {item.status === 4 ? "Terima Pesanan" : "Kirim Pesanan"}
                </button>
              ) : null}
            </div>
          </div>
        ))
      ) : (
        <div className="flex items-center justify-center p-2 w-full">
          {isLoading || isFetchingSetting ? (
            <CircularProgress size={20} />
          ) : dataSetting && dataSetting.store.length === 0 ? (
            <div className="text-center">
              <p className="mb-2 font-bold text-[16px]">Toko Kosong</p>
              <p className="mb-4">
                Anda belum memiliki toko, silahkan tambahkan toko terlebih
                dahulu
              </p>
              <button
                type="button"
                className="w-fit py-2 px-4 bg-amber-500 text-white hover:bg-gray-300 rounded-md"
                onClick={() => navigate(`/oms/settings`)}
              >
                Tambahkan Toko
              </button>
            </div>
          ) : dataSetting &&
            dataSetting.store.every((store) => !store.is_active) ? (
            <div className="text-center">
              <p className="mb-2 font-bold text-[16px]">
                Anda Sedang Menutup Toko
              </p>
              <p className="mb-4">
                Buka toko yuk, biar order dari <br /> marketplace bisa masuk
              </p>
              <button
                type="button"
                className="w-fit py-2 px-4 bg-amber-500 text-white hover:bg-gray-300 rounded-md"
                onClick={() => navigate(`/oms/settings`)}
              >
                Buka Toko
              </button>
            </div>
          ) : (
            <div className="text-center">
              <p className="mb-2 font-bold text-[16px]">
                Belum Ada Order Terbaru
              </p>
              <p className="mb-4">
                Seluruh order baru akan bisa dilihat disini
              </p>
            </div>
          )}
        </div>
      )}

      {dataList &&
      !isLoading &&
      dataList.total_record > 0 &&
      hasActiveStore() ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={dataList.total_record}
        />
      ) : null}

      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start}
        endDate={reqBody.end}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end: e }));
        }}
        resetFunc={() =>
          setReqBody((prevValue) => ({
            ...prevValue,
            offset: 1,
            start: "",
            end: "",
          }))
        }
      />

      <Menu
        anchorEl={anchorElSort}
        open={Boolean(anchorElSort)}
        onClose={() => setAnchorElSort(null)}
      >
        <MenuItem
          onClick={() => setReqBody((prevVal) => ({ ...prevVal, sort: 1 }))}
          sx={{ backgroundColor: reqBody.sort === 1 ? "#e5e7eb" : "" }}
        >
          Terbaru
        </MenuItem>
        <MenuItem
          onClick={() => setReqBody((prevVal) => ({ ...prevVal, sort: 2 }))}
          sx={{ backgroundColor: reqBody.sort === 2 ? "#e5e7eb" : "" }}
        >
          Terlama
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorElMarketplace}
        open={Boolean(anchorElMarketplace)}
        onClose={() => setAnchorElMarketplace(null)}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={MARKETPLACE.length === reqBody.partner.length}
                onChange={(_, checked) =>
                  setReqBody((prevVal) => ({
                    ...prevVal,
                    partner: checked
                      ? MARKETPLACE.map((item) => item.partner_code)
                      : [],
                  }))
                }
              />
            }
            label="Semua"
          />
        </MenuItem>

        {MARKETPLACE.map((item) => (
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={reqBody.partner.find(
                    (partner) => partner === item.partner_code
                  )}
                  onChange={() => {
                    const index = reqBody.partner.findIndex(
                      (partner) => partner === item.partner_code
                    );

                    setReqBody((prevVal) => ({
                      ...prevVal,
                      partner:
                        index < 0
                          ? [...prevVal.partner, item.partner_code]
                          : [
                              ...prevVal.partner.slice(0, index),
                              ...prevVal.partner.slice(index + 1),
                            ],
                    }));
                  }}
                />
              }
              label={item.name}
            />
          </MenuItem>
        ))}
      </Menu>
      <OmsReceiveDrawer
        open={openDrawerReceive}
        setOpen={setOpenDrawerReceive}
        refetch={() => resetList()}
        order={selectedOrder}
      />
      <ModalTrackingNumber
        open={openModalSend}
        setOpen={setOpenModalSend}
        refetch={() => resetList()}
        order={selectedOrder}
      />
      <ModalDetailOms open={openModalDetail} setOpen={setOpenModalDetail} />
      <ModalStatusOms open={openModalStatus} setOpen={setOpenModalStatus} />
      <ModalPriceOms open={openModalPrice} setOpen={setOpenModalPrice} />
      <ModalDoctorConcoction
        open={openConcoction}
        setOpen={setOpenConcoction}
      />
      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default OMSList;
