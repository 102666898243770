import moment from "moment";

const PrintNotaPo = (props) => {
  const { dataList, printRef, dataLabel, title, tableContent, dataPdf } = props;

  return (
    <div ref={printRef} className="m-3 text-[11px]">
      <table className="w-full">
        <thead className="table-header-group">
          <tr>
            <td className="pb-3">
              <div>
                {dataLabel && dataLabel !== undefined ? (
                  <>
                    <div className="mb-4">
                      <p className="text-[20px] font-bold mb-2">{title}</p>
                      <div className="flex gap-2 items-center w-full">
                        <p className="w-1/4">Nomor SP</p>
                        <p>: {dataPdf?.sp_no}</p>
                      </div>
                      <div className="flex gap-2 items-center w-full">
                        <p className="w-1/4">Tanggal SP</p>
                        <p>
                          : {moment(dataPdf?.created_at).format("D MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="w-full">
                  <p className="font-bold">Mengajukan pesanan kepada:</p>
                  <div className="flex gap-2 items-center w-full">
                    <p className="w-1/4">Nama Supplier</p>
                    <p>: {dataPdf?.distributor_name}</p>
                  </div>
                  <div className="flex gap-2 items-center w-full">
                    <p className="w-1/4">Alamat Supplier</p>
                    <p>: {dataPdf?.distributor_address}</p>
                  </div>
                  <div className="flex gap-2 items-center w-full">
                    <p className="w-1/4">No Telepon</p>
                    <p>: {dataPdf?.distributor_phone}</p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {dataPdf ? (
            <div>
              {dataList && dataList !== undefined ? (
                <div className="rounded-md w-full">
                  <table className="w-full border-separate border-spacing-0 text-[11px] ">
                    <thead className="table-header-group">
                      <tr className="text-center bg-blue-500 text-white">
                        {tableContent.map((content, i) => (
                          <th
                            className={`p-2  ${
                              ["INDEX", "QTYPO"].includes(content.key)
                                ? "text-center"
                                : "text-left"
                            }${
                              i === 0
                                ? "rounded-tl-md"
                                : i === tableContent.length - 1
                                ? "rounded-tr-md"
                                : ""
                            }`}
                          >
                            {content.header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {dataList.map((data, index) => (
                      <tbody key={index} className="mb-[150px]">
                        <tr>
                          {tableContent.map((content, i) => (
                            <td
                              className={`p-2 border ${
                                ["INDEX", "QTYPO", "product_quantity"].includes(
                                  content.key
                                )
                                  ? "text-center"
                                  : "text-left"
                              } ${
                                index === dataList.length - 1
                                  ? i === 0
                                    ? "rounded-bl-md"
                                    : i === tableContent.length - 1
                                    ? "rounded-br-md"
                                    : ""
                                  : ""
                              }`}
                            >
                              {content && data
                                ? content.key === "INDEX"
                                  ? index + 1
                                  : content.key === "QTYRETUR"
                                  ? data.quantity +
                                    " " +
                                    (data.type_packaging
                                      ? data.type_packaging
                                      : data.packaging)
                                  : content.key === "QTYPO"
                                  ? data.product_quantity +
                                    " " +
                                    data.product_unit
                                  : content.type && content.type === "currency"
                                  ? data[content.key]?.toLocaleString("id-ID", {
                                      style: "currency",
                                      currency: "IDR",
                                    })
                                  : data[content.key]
                                : ""}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    ))}
                    <tfoot className="table-footer-group">
                      <tr>
                        <td colSpan={4} className="pt-5 pb-3">
                          <div className="w-full">
                            <p className="font-bold">
                              Produk tersebut digunakan untuk:
                            </p>
                            <div className="flex gap-2 items-center w-full">
                              <p className="w-1/4">Nama Apotek</p>
                              <p>: {dataPdf?.pharmacy.pharmacy_name}</p>
                            </div>
                            <div className="flex gap-2 items-center w-full">
                              <p className="w-1/4">Alamat</p>
                              <p>: {dataPdf?.pharmacy.pharmacy_address}</p>
                            </div>
                            <div className="flex gap-2 items-center w-full">
                              <p className="w-1/4">No Surat Izin</p>
                              <p>: {dataPdf?.pharmacy.pharmacy_sia_number}</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              ) : null}

              <div className="keep-together">
                <div className="w-full flex justify-end">
                  <div className="w-1/3">
                    <p className="text-center">{dataPdf?.district.city}</p>
                    <p className="text-center">
                      {moment(dataPdf?.created_at).format("D MMMM YYYY")}
                    </p>
                    <hr className="w-full mt-[3cm] mb-2" />
                    <p>Nama: {dataPdf?.pic.pic_name}</p>
                    <p>SIPA: {dataPdf?.pic.pic_sipa_number}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default PrintNotaPo;
