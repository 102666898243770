import {
  Box,
  CircularProgress,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { getBillingPaymentMethod } from "../../../../services/billingAPI";
import { useQuery } from "@tanstack/react-query";
import { Search } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalBankBilling = ({ open, setOpen, selectBank }) => {
  const [searchPayment, setSearchPayment] = useState("");

  const handleClose = () => {
    selectBank(null);
    setSearchPayment("");
    setOpen(false);
  };

  const { data: dataBank, isFetching } = useQuery({
    queryKey: ["payment-method", searchPayment],
    queryFn: () => getBillingPaymentMethod(searchPayment),
    refetchOnWindowFocus: false,
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "60vh",
          padding: 0,
        }}
        className="text-left text-[12px] w-3/4 lg:w-1/3"
      >
        <div className="flex items-center px-8 py-4">
          <p className="font-bold text-lg text-center">Pilih Bank</p>
        </div>

        <hr className="mb-4" />

        <div className="px-8 mb-4">
          <TextField
            fullWidth
            placeholder="Cari data"
            value={searchPayment}
            onChange={(e) => setSearchPayment(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ marginBottom: 2 }}
          />
          {dataBank && !isFetching && dataBank.data ? (
            dataBank.data.map((item) => (
              <div
                className="p-3 border-b cursor-pointer hover:bg-gray-100 flex gap-4 items-center"
                onClick={() => {
                  selectBank(item);
                  setSearchPayment("");
                  setOpen(false);
                }}
              >
                <img
                  src={item.bank_image}
                  className="w-[48px] object-contain"
                />
                <p> {item.bank_name}</p>
              </div>
            ))
          ) : isFetching ? (
            <CircularProgress size={20} />
          ) : (
            <p>Tidak ada data</p>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalBankBilling;
