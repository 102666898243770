import { Box, CircularProgress, Modal } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FaRegClock } from "react-icons/fa";
import { RiFileList2Line } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import { getOrderTree } from "../../../../services/oms";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalStatusOms = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(null); // set ID back to null
  };

  const {
    data: dataStatus,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["status-oms", open],
    queryFn: () =>
      getOrderTree({ id: open?.order_id, partner: open?.platform_code }),
    refetchOnWindowFocus: false,
    enabled: open !== undefined && open !== null,
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "80vh",
          padding: 0,
        }}
        className="text-left text-[12px] w-3/4 lg:w-1/2"
      >
        <div className="px-8 py-4 w-full">
          <div className="flex items-center justify-between mb-2">
            <p className="text-[20px] font-bold">Status Pesanan</p>
            <RxCross1
              className="cursor-pointer hover:text-red-500 w-fit"
              onClick={handleClose}
            />
          </div>
          {open ? (
            <>
              <div className="flex items-center gap-2">
                <RiFileList2Line />
                <p className="text-blue-500">{open.order_code}</p>
                <p>/</p>
                <p>{open.customer_name}</p>
              </div>
              <div className="flex items-center gap-2">
                <FaRegClock />
                <p>{moment(open.created_at).format("DD MMMM YYYY, HH:mm")}</p>
              </div>
            </>
          ) : null}
        </div>

        <hr className="text-gray-400 border-t-[2px] border-solid" />
        <div className="px-8 py-4 w-full">
          {dataStatus && !isFetching ? (
            <>
              {dataStatus.map((item, i) => (
                <div>
                  <div className="flex gap-4 items-center">
                    <div className="w-1/3 text-right">
                      <p>{moment(item.timestamp).format("DD MMMM YYYY")}</p>
                      <p>{moment(item.timestamp).format("HH:mm")}</p>
                    </div>
                    <div className="flex w-[64px] items-center justify-center">
                      <div
                        className={`w-[20px] h-[20px] rounded-full ${
                          i === 0 ? "bg-blue-500" : "bg-gray-300"
                        }`}
                      />
                    </div>
                    <div className="w-full">
                      <p className="font-bold">{item.status}</p>
                      <p>
                        {item.description && item.description !== ""
                          ? item.description
                          : "-"}
                      </p>
                    </div>
                  </div>
                  {i !== dataStatus.length - 1 ? (
                    <div className="flex gap-4 items-center">
                      <div className="w-1/3" />
                      <div className="flex w-[64px] items-center justify-center">
                        <div className="h-[50px] bg-gray-300 w-[2px]" />
                      </div>
                      <div className="w-full" />
                    </div>
                  ) : null}
                </div>
              ))}
            </>
          ) : (
            <div className="flex justify-center items-center">
              {isFetching ? (
                <CircularProgress size={20} />
              ) : (
                "Tidak ada data status pesanan"
              )}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalStatusOms;
