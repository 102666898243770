import { Box, CircularProgress, Modal } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { RxCross1 } from "react-icons/rx";
import { getOMSDetail } from "../../../../services/oms";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const ModalPriceOms = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(null); // set ID back to null
  };

  const {
    data: dataDetail,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["oms-detail", open],
    queryFn: () => getOMSDetail(open),
    refetchOnWindowFocus: false,
    enabled: open !== undefined && open !== null,
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "80vh",
          padding: 0,
        }}
        className="text-left text-[12px] w-3/4 lg:w-1/2"
      >
        <div className="px-8 py-4 w-full flex items-center justify-between">
          <p className="text-[20px] font-bold">Rincian Pembayaran</p>
          <RxCross1
            className="cursor-pointer hover:text-red-500 w-fit"
            onClick={handleClose}
          />
        </div>

        <hr className="text-gray-400 border-t-[2px] border-solid" />

        <div className="px-8 py-4 w-full">
          {dataDetail && !isFetching ? (
            <>
              {" "}
              <div className="mb-2">
                <div className="flex justify-between items-center">
                  <p>Metode Pembayaran</p>
                  <p>
                    {(dataDetail && dataDetail.payment.payment_name === "") ||
                    !dataDetail
                      ? "-"
                      : dataDetail.payment.payment_name}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p>Subtotal produk</p>
                  <p>
                    {toIDR(
                      dataDetail ? dataDetail.payment.product_sub_total : 0
                    )}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p>Ongkos Kirim</p>
                  <p>
                    {toIDR(dataDetail ? dataDetail.payment.shipment_fee : 0)}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p>Diskon</p>
                  <p>-{toIDR(dataDetail ? dataDetail.payment.discount : 0)}</p>
                </div>
              </div>
              <div className="flex justify-between items-center text-[14px] font-bold">
                <p>Total Pembayaran</p>
                <p>
                  {toIDR(dataDetail ? dataDetail.payment.total_payment : 0)}
                </p>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center">
              {isFetching ? (
                <CircularProgress size={20} />
              ) : error ? (
                error.id
              ) : (
                "Tidak ada rincian pembayaran"
              )}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalPriceOms;
