import React from "react";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../components/constants/muiTheme";
import OMSList from "../../components/screen/oms/OMSList";

const OrderManagementSystemPage = () => {
  return (
    <PageTemplate
      header="Order Management System"
      mainComponent={
        <ThemeProvider theme={theme}>
          <OMSList />
        </ThemeProvider>
      }
      pageRole={2}
    />
  );
};

export default HOC(OrderManagementSystemPage);
