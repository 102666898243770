import { Box, CircularProgress, Modal, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { sendOrder } from "../../../../services/oms";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalTrackingNumber = ({ open, setOpen, refetch, order }) => {
  const [resi, setResi] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = () => {
    setErrorMessage("");
    setResi("");
    setOpen(null); //set ID to null
  };

  const { mutate: mutateSend, isLoading } = useMutation({
    mutationFn: sendOrder,
    onSuccess: () => {
      setSuccessMessage("Pesanan berhasil dikirim");
      refetch();
      handleClose();
    },
    onError: (err) => setErrorMessage(err.id),
  });

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/2"
        >
          <div className="px-8 py-4">
            <div className="w-full flex items-center justify-between">
              <p className="font-bold text-[24px]">Masukan No. Resi</p>
              <RxCross1
                className="cursor-pointer hover:text-red-500 w-fit"
                onClick={handleClose}
              />
            </div>
          </div>

          <hr className="text-gray-400 border-t-[2px] border-solid" />

          <div className="px-8 py-4">
            <TextField
              fullWidth
              placeholder="Nomor Resi"
              value={resi}
              onChange={(e) => setResi(e.target.value)}
            />
            {errorMessage !== "" ? (
              <p className="text-red-500 mt-4">{errorMessage}</p>
            ) : null}
          </div>

          <hr className="text-gray-400 border-t-[2px] border-solid" />

          <div className="px-8 py-3">
            <button
              type="button"
              className="w-full p-2 bg-blue-500 text-white hover:bg-gray-300 rounded-md disabled:bg-gray-300"
              onClick={() => {
                mutateSend({
                  order_id: order.order_id,
                  partner_code: order?.platform_code,
                  tracking_number: resi,
                });
              }}
              disabled={isLoading || resi === ""}
            >
              {isLoading ? <CircularProgress size={15} /> : "Konfirmasi"}
            </button>
          </div>
        </Box>
      </Modal>
      <ErrorPopup
        errorMessage={successMessage}
        setErrorMessage={setSuccessMessage}
      />
    </div>
  );
};

export default ModalTrackingNumber;
