import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, CircularProgress, TextField } from "@mui/material";
import moment from "moment";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getDetailReturPOS, setReturPOS } from "../../../services/billingAPI";
import ErrorPopup from "../../common/particles.jsx/ErrorPopup";
import ModalCancelCreatePOS from "./modal/ModalCancelCreatePOS";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const CreateReturSellPOS = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [productArr, setProductArr] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheck = (item) => {
    const index = productArr.findIndex(
      (prod) => prod.product_id === item.product_id
    );

    setProductArr(
      index < 0
        ? [
            ...productArr.slice(0, index),
            {
              ...item,
              quantity_retur: 1,
              total_retur: item.unit_price,
            },
            ...productArr.slice(index + 1),
          ]
        : [...productArr.slice(0, index), ...productArr.slice(index + 1)]
    );
  };

  const handleChangeReturQty = (item, qty) => {
    const index = productArr.findIndex(
      (prod) => prod.product_id === item.product_id
    );

    setProductArr(
      index < 0
        ? [
            ...productArr,
            {
              ...item,
              quantity_retur: qty,
              total_retur: qty * item.unit_price,
            },
          ]
        : qty > 0
        ? [
            ...productArr.slice(0, index),
            {
              ...productArr[index],
              quantity_retur: qty,
              total_retur: qty * productArr[index].unit_price,
            },
            ...productArr.slice(index + 1),
          ]
        : [...productArr.slice(0, index), ...productArr.slice(index + 1)]
    );
  };

  const { data, isFetching } = useQuery({
    queryKey: ["detail-retur-pos", id],
    queryFn: () => getDetailReturPOS(id),
    refetchOnWindowFocus: false,
  });

  const formik = useFormik({
    initialValues: {
      id: id ? Number(id) : null,
      transaction_number: data && !isFetching ? data.transaction_number : "",
      member_name: data && !isFetching ? data.member_name : "",
      sales_name: data && !isFetching ? data.sales_name : "",
      payment_method: data && !isFetching ? data.payment_method : "",
      sub_total: data && !isFetching ? data.sub_total : 0,
      other_cost: data && !isFetching ? data.other_cost : 0,
      discount: data && !isFetching ? data.discount : 0,
      total_payment: data && !isFetching ? data.total_payment : 0,
      total_retur: 0,
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (data && !isFetching && (data.discount > 0 || data.other_cost > 0)) {
      setProductArr(
        data.product.map((item) => ({
          ...item,
          quantity_retur: item.quantity,
          total_retur: item.total_price,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    formik.setFieldValue(
      "total_retur",
      productArr?.reduce(
        (acc, num) => acc + num.unit_price * num.quantity_retur,
        0
      ) +
        (data && !isFetching && (data.discount > 0 || data.other_cost > 0)
          ? data.other_cost - data.discount
          : 0)
    );
  }, [productArr]);

  return (
    <div className="text-[12px]">
      <div className="w-full flex items-start gap-4 font-semibold mb-4">
        <div className="w-full">
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">No. Transaksi</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                data.transaction_number
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">Tanggal Transaksi</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                moment(data.created_at).format("DD MMMM YYYY")
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">Member</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                data.member_name === "" ? (
                  "-"
                ) : (
                  data.member_name
                )
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
        </div>
        <div className="w-full">
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">Sales</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                data.sales_name === "" ? (
                  "-"
                ) : (
                  data.sales_name
                )
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">Metode Pembayaran</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                data.payment_method
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
        </div>
        <div className="w-1/2" />
      </div>
      <div className="flex w-full mb-4">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center"></th>
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">Produk</th>
                <th className="p-2 text-center whitespace-nowrap">Batch No</th>
                <th className="p-2 text-center whitespace-nowrap">Kemasan</th>
                <th className="p-2 text-center whitespace-nowrap">Qty</th>
                <th className="p-2 text-center whitespace-nowrap">Qty Retur</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Harga Satuan
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Total Harga
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Total Retur
                </th>
              </thead>
              <tbody className="border">
                {data &&
                !isFetching &&
                data.product &&
                data.product.length > 0 ? (
                  data.product.map((item, i) => (
                    <tr className="border" key={i}>
                      <td className="p-2 text-center">
                        <Checkbox
                          disabled={data.discount > 0 || data.other_cost > 0}
                          checked={productArr
                            .map((prod) => prod.product_id)
                            .includes(item.product_id)}
                          onChange={() => handleCheck(item)}
                        />
                      </td>
                      <td className="p-2 text-center">{i + 1}</td>
                      <td className="p-2 text-center">{item.product_name}</td>
                      <td className="p-2 text-center">{item.batch_number}</td>
                      <td className="p-2 text-center">{item.packaging}</td>
                      <td className="p-2 text-center">{item.quantity}</td>
                      <td className="p-2 text-center">
                        <TextField
                          fullWidth
                          disabled={data.discount > 0 || data.other_cost > 0}
                          value={
                            productArr.find(
                              (prod) => prod.product_id === item.product_id
                            )
                              ? productArr.find(
                                  (prod) => prod.product_id === item.product_id
                                ).quantity_retur
                              : 0
                          }
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                          onChange={(e) => {
                            if (!isNaN(Number(e.target.value)))
                              handleChangeReturQty(
                                item,
                                Number(e.target.value) > item.quantity
                                  ? item.quantity
                                  : Number(e.target.value)
                              );
                          }}
                        />
                      </td>
                      <td className="p-2 text-center">
                        {toIDR(item.unit_price)}
                      </td>
                      <td className="p-2 text-center">
                        {toIDR(item.total_price)}
                      </td>
                      <td className="p-2 text-center">
                        {toIDR(
                          productArr.find(
                            (prod) => prod.product_id === item.product_id
                          )
                            ? productArr.find(
                                (prod) => prod.product_id === item.product_id
                              ).total_retur
                            : 0
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border">
                    <td className="p-2 text-center border" colSpan={10}>
                      {isFetching ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada produk"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {data && !isFetching ? (
        <div className="w-full flex justify-end mb-8 items-end gap-4">
          <div className="w-1/4">
            <div className="mb-2">
              <div className="flex items-center gap-2">
                <p className="w-1/2">Subtotal</p>
                <p>:</p>
                <p>{toIDR(data.sub_total)}</p>
              </div>
            </div>
            <div className="mb-2">
              <div className="flex items-center gap-2">
                <p className="w-1/2">Biaya Lain-lain</p>
                <p>:</p>
                <p>{toIDR(data.other_cost)}</p>
              </div>
            </div>
            <div className="mb-2">
              <div className="flex items-center gap-2">
                <p className="w-1/2">Diskon</p>
                <p>:</p>
                <p>{toIDR(data.discount)}</p>
              </div>
            </div>
            <div className="mb-2 text-blue-500 bg-gray-100 rounded-md p-2 font-bold">
              <div className="flex items-center gap-2">
                <p className="w-1/2">Total Pembayaran</p>
                <p>:</p>
                <p>{toIDR(data.total_payment)}</p>
              </div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="mb-2 text-amber-500 bg-gray-100 rounded-md p-2 font-bold">
              <div className="flex items-center gap-2">
                <p className="w-1/2">Total Retur</p>
                <p>:</p>
                <p>{toIDR(formik.values.total_retur)}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="w-full flex justify-end mb-8 items-end gap-4">
        <div className="w-1/3 flex items-center gap-2">
          <button
            type="button"
            className="w-full rounded-md p-2 hover:bg-gray-100 border-2"
            onClick={() => {
              setIsCreate(false);
              setOpenModal(true);
            }}
          >
            Batal
          </button>
          <button
            type="button"
            onClick={() => {
              formik.setFieldValue("product", productArr);
              setIsCreate(true);
              setOpenModal(true);
            }}
            disabled={
              productArr.length === 0 ||
              !productArr.every((prod) => prod.quantity_retur > 0)
            }
            className="w-full rounded-md p-2 disabled:bg-gray-300 text-white bg-blue-500 hover:bg-blue-700"
          >
            Retur
          </button>
        </div>
      </div>

      <ModalCancelCreatePOS
        open={openModal}
        setOpen={setOpenModal}
        formik={formik}
        isCreate={isCreate}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default CreateReturSellPOS;
