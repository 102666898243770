import { Box, CircularProgress, Modal } from "@mui/material";
import { BsDownload, BsPrinter } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalDoctorConcoction = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(null); //set ID to null
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/2"
        >
          <div className="px-8 py-4">
            <div className="w-full flex items-center justify-between">
              <p className="font-bold text-[24px]">Resep Dokter</p>
              <RxCross1
                className="cursor-pointer hover:text-red-500 w-fit"
                onClick={handleClose}
              />
            </div>
          </div>

          <hr className="text-gray-400 border-t-[2px] border-solid" />

          <div className="px-8 py-4">
            <div className="mb-4">Tidak ada resep dokter</div>
            <div className="flex items-center justify-end gap-2">
              <button
                type="button"
                className="w-[32px] h-[32px] bg-blue-500 text-white rounded-md p-1.5 disabled:bg-gray-300"
                disabled={true}
              >
                <BsPrinter className="w-full h-full" />
              </button>
              <button
                type="button"
                className="w-[32px] h-[32px] bg-blue-500 text-white rounded-md p-1.5 disabled:bg-gray-300"
                disabled={true}
              >
                <BsDownload className="w-full h-full" />
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalDoctorConcoction;
