import { useState, useEffect, useMemo, useRef } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  MenuItem,
  InputAdornment,
  Menu,
  Checkbox,
  Popover,
} from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment";
import { LuFilePlus2 } from "react-icons/lu";

import {
  exportXlsxCardStock,
  fetchCardStockNew,
  getPrintCardstock,
} from "../../../../services/InventoryAPI";

import { inventoryStatus } from "../../../constants/constant";
import useDebounce from "../../../hooks/useDebounce";
import { Search } from "@mui/icons-material";
import { FaChevronDown, FaPrint, FaSortAlphaDown } from "react-icons/fa";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import BreadcrumbInventory from "../BreadcrumbInventory";
import PrintCardStock from "./PrintCardStock";
import { useReactToPrint } from "react-to-print";
import { getPreviewInvoice } from "../../../../services/ReportingApi";

const toDate = (date) => moment(date).format("DD MMM YYYY");
const toApiDate = (date) => moment(date).format("YYYY-MM-DD");
const PERIOD = [
  { value: 0, name: "Semua" },
  { value: 1, name: "Minggu Berjalan" },
  { value: 2, name: "Minggu Lalu" },
  { value: 3, name: "Bulan Berjalan" },
  { value: 4, name: "Bulan Lalu" },
  { value: 5, name: "Tahun Berjalan" },
  { value: 6, name: "Tahun Lalu" },
  { value: 7, name: "Pilih Tanggal" },
];

const CardStockNew = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const printRef = useRef();

  const companyId = localStorage.getItem("company_id");

  const [selectedStock, setSelectedStock] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [resBody, setResBody] = useState({
    warehouse: isWarehouse ? isWarehouse : false,
    product_name: "",
    start_date: "",
    end_date: "",
    period: 0,
    sort: 1,
    limit: 10,
    offset: 1,
  });
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElExport, setAnchorElExport] = useState(null);
  const [anchorElPrint, setAnchorElPrint] = useState(null);
  const [isPrint, setIsPrint] = useState(false);

  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
    isOpen: false,
    period: 3,
  });

  const debounce = useDebounce(resBody.product_name, 500);

  const handleExport = () => {
    setAnchorElExport(null);
    mutateXlsx({
      id: selectedStock,
      sort: resBody.period,
      start_date: resBody.start_date ? toApiDate(resBody.start_date) : "",
      end_date: resBody.end_date ? toApiDate(resBody.end_date) : "",
      warehouse: isWarehouse ? isWarehouse : false,
    });
  };

  const handleExportAll = () => {
    setAnchorElExport(null);
    mutateXlsx({
      sort: resBody.period,
      start_date: resBody.start_date ? toApiDate(resBody.start_date) : "",
      end_date: resBody.end_date ? toApiDate(resBody.end_date) : "",
      warehouse: isWarehouse ? isWarehouse : false,
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setSelectedStock([]);
      setAnchorElPrint(null);
      setIsPrint(false);
    },
    documentTitle: "Kartu Stok",
  });

  const getPeriodName = () => {
    const period = PERIOD.find((item) => item.value === resBody.period);
    return period
      ? period.value === 7
        ? toDate(resBody.start_date) + " - " + toDate(resBody.end_date)
        : period.name
      : "";
  };

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "card-stock-history.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { mutate: mutateXlsx, isLoading: isLoadingXlsx } = useMutation({
    mutationFn: exportXlsxCardStock,
    onSuccess: (data) => handleXlsxData(data),
    onError: (err) =>
      setErrorMessage(err.message.id ? err.message.id : err.message),
  });

  const {
    mutate: mutateCardStockData,
    data: cardStockData,
    isLoading: isCardStockDataLoading,
  } = useMutation({
    mutationFn: fetchCardStockNew,
  });

  const {
    mutate: mutatePrint,
    data: dataPrint,
    isLoading: isLoadingPrint,
  } = useMutation({
    mutationFn: getPrintCardstock,
    onSuccess: () => {
      setTimeout(() => {
        handlePrint();
      }, 500);
    },
    onError: (err) => {
      setAnchorElPrint(null);
      setErrorMessage(err.message.id ? err.message.id : err.message);
      setIsPrint(false);
    },
  });

  const { data: dataLabel } = useQuery({
    queryKey: ["label-print"],
    queryFn: getPreviewInvoice,
  });

  useEffect(() => {
    mutateCardStockData({
      ...resBody,
      product_name: debounce,
      offset: resBody.offset,
      limit: resBody.limit,
      period: resBody.period,
    });
  }, [
    resBody.sort,
    resBody.period,
    resBody.offset,
    resBody.limit,
    debounce,
    mutateCardStockData,
    location.pathname,
  ]);

  return (
    <div>
      <LoadingBackdrop isLoading={isLoadingXlsx || isPrint} />

      <BreadcrumbInventory isWarehouse={isWarehouse} />
      <div className="flex items-center gap-2 mb-4">
        <TextField
          fullWidth
          placeholder="Search"
          value={resBody.product_name}
          onChange={(e) =>
            setResBody((prevValue) => ({
              ...prevValue,
              product_name: e.target.value,
            }))
          }
          inputProps={{ style: { padding: 9 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer"
          aria-controls={Boolean(anchorElSort) ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorElSort) ? "true" : undefined}
          onClick={(e) => setAnchorElSort(e.currentTarget)}
        >
          <FaSortAlphaDown />
          <p className="whitespace-nowrap">{getPeriodName()}</p>
          <FaChevronDown />
        </div>
        <button
          className="bg-blue-100 w-fit p-2 rounded-md border-2 border-blue-500 text-blue-500 flex gap-8 items-center hover:bg-blue-200"
          onClick={(e) => setAnchorElPrint(e.currentTarget)}
        >
          <p>Print</p>
          <div className="p-1 border-md text-white rounded-md w-[24px] h-[24px] rounded-md bg-blue-500 text-white">
            <FaPrint />
          </div>
        </button>
        <button
          className="bg-[#AFECC6] w-fit p-2 rounded-md border-2 border-[#15803D] text-[#15803D] flex gap-8 items-center hover:bg-[#91c4a4]"
          onClick={(e) => setAnchorElExport(e.currentTarget)}
        >
          <p>Export</p>
          <div className="p-1 border-md text-white rounded-md w-[24px] h-[24px] rounded-md bg-[#15803D]">
            <LuFilePlus2 />
          </div>
        </button>
      </div>

      <TableContainer sx={{ width: "100%", marginBottom: 2 }}>
        <Table aria-label="simple table">
          <TableHead
            sx={{
              backgroundColor: "#007AF1",
            }}
          >
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>
                <Checkbox
                  disabled={isCardStockDataLoading || !cardStockData}
                  checked={
                    cardStockData &&
                    cardStockData.data &&
                    selectedStock.length > 0
                      ? selectedStock.length === cardStockData.data.length
                      : false
                  }
                  onChange={(e, checked) => {
                    setSelectedStock(
                      checked
                        ? cardStockData &&
                          !isCardStockDataLoading &&
                          cardStockData.data
                          ? cardStockData.data.map((p) => p.product_id)
                          : []
                        : []
                    );
                  }}
                />
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                // colSpan={2}
                align="center"
              >
                Nama Produk
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Beginning Stock
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Stock In
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Stock Out
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Final Stock
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Status Stock
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Last Update
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isCardStockDataLoading &&
            cardStockData &&
            cardStockData.data &&
            cardStockData.data !== undefined ? (
              cardStockData.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    sx={{
                      border: 1,
                      borderColor: "#000",
                      padding: 1,
                      margin: 0,
                    }}
                  >
                    <Checkbox
                      checked={selectedStock.includes(data.product_id)}
                      onChange={() => {
                        const index = selectedStock.findIndex(
                          (stock) => stock === data.product_id
                        );
                        setSelectedStock(
                          index < 0
                            ? [...selectedStock, data.product_id]
                            : [
                                ...selectedStock.slice(0, index),
                                ...selectedStock.slice(index + 1),
                              ]
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#007AF1",
                      border: 1,
                      borderColor: "#000",
                      padding: 1,
                      margin: 0,
                    }}
                  >
                    <p
                      className="hover:cursor-pointer hover:underline"
                      onClick={() => {
                        navigate(
                          `${
                            isWarehouse ? "/central-warehouse" : ""
                          }/inventory/card-stock/card-stock-detail-new/${
                            data.product_id
                          }`
                        );
                      }}
                    >
                      {data.product_name}
                    </p>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: 1,
                      borderColor: "#000",
                      padding: 1,
                      margin: 0,
                    }}
                  >
                    {data.stock_current}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: 1,
                      borderColor: "#000",
                      padding: 1,
                      margin: 0,
                    }}
                  >
                    {data.stock_in}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: 1,
                      borderColor: "#000",
                      padding: 1,
                      margin: 0,
                    }}
                  >
                    {data.stock_out}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: 1,
                      borderColor: "#000",
                      padding: 1,
                      margin: 0,
                    }}
                  >
                    {data.final_stock}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: 1,
                      borderColor: "#000",
                      padding: 1,
                      margin: 0,
                    }}
                  >
                    {inventoryStatus[data.status]}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: 1,
                      borderColor: "#000",
                      padding: 1,
                      margin: 0,
                    }}
                  >
                    {toDate(data.created_at)}
                  </TableCell>
                </TableRow>
              ))
            ) : isCardStockDataLoading ? (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ border: 1, borderColor: "#000", padding: 1, margin: 0 }}
                  colSpan={8}
                >
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ border: 1, borderColor: "#000", padding: 1, margin: 0 }}
                  colSpan={8}
                >
                  Tidak ada produk
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!isCardStockDataLoading && cardStockData && cardStockData.count > 0 ? (
        <PagePagination
          offset={resBody.offset}
          setOffset={(e) => {
            setSelectedStock([]);
            setResBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={resBody.limit}
          setLimit={(e) => {
            setSelectedStock([]);
            setResBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={cardStockData.count}
        />
      ) : null}

      <Dialog
        open={date.isOpen}
        onClose={() => {
          setDate((prevValue) => ({
            ...prevValue,
            isOpen: false,
            startDate: null,
            endDate: null,
          }));
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              onChange={(dates) => {
                const [start, end] = dates;
                setDate((prevValue) => ({
                  ...prevValue,
                  startDate: start,
                  endDate: end,
                }));
              }}
              startDate={date.startDate}
              endDate={date.endDate}
              selectsRange={true}
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold disabled:bg-gray-300"
            disabled={!date.startDate || !date.endDate}
            onClick={() => {
              setDate((prevValue) => ({
                ...prevValue,
                isOpen: false,
                period: 8,
              }));
              setResBody((prevValue) => ({
                ...prevValue,
                period: 7,
                start_date: toApiDate(date.startDate),
                end_date: toApiDate(date.endDate),
              }));
              mutateCardStockData({
                ...resBody,
                start_date: toApiDate(date.startDate),
                end_date: toApiDate(date.endDate),
                period: 7,
              });
            }}
          >
            ok
          </button>
        </DialogContent>
      </Dialog>

      <Menu
        anchorEl={anchorElSort}
        open={Boolean(anchorElSort)}
        onClose={() => setAnchorElSort(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onChange={(e) => {
          if (e.target.value === 7) {
            setDate((prevValue) => ({
              ...prevValue,
              period: e.target.value,
              isOpen: true,
            }));
            return;
          }
          setDate((prevValue) => ({
            ...prevValue,
            period: e.target.value,
          }));
          setResBody((prevValue) => ({
            ...prevValue,
            period: e.target.value,
          }));
        }}
      >
        {PERIOD.map((item) => (
          <MenuItem
            onClick={() => {
              setSelectedStock([]);
              if (item.value === 7) {
                setDate((prevValue) => ({
                  ...prevValue,
                  period: item.value,
                  isOpen: true,
                }));
                setAnchorElSort(null);
                return;
              }
              setDate((prevValue) => ({
                ...prevValue,
                period: item.value,
                startDate: null,
                endDate: null,
              }));
              setResBody((prevValue) => ({
                ...prevValue,
                period: item.value,
              }));
              setAnchorElSort(null);
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>

      <Popover
        open={Boolean(anchorElExport)}
        anchorEl={anchorElExport}
        onClose={() => setAnchorElExport(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="w-full">
          <p
            className={`px-8 py-2 border-b-2  ${
              selectedStock.length === 0
                ? "bg-gray-300 text-white cursor-default"
                : "cursor-pointer hover:bg-gray-100"
            }`}
            onClick={() => {
              if (selectedStock.length > 0) handleExport();
            }}
          >
            Stok Terpilih
          </p>
          <p
            className="px-8 py-2  cursor-pointer hover:bg-gray-100"
            onClick={handleExportAll}
          >
            Semua Stok
          </p>
        </div>
      </Popover>

      <Popover
        open={Boolean(anchorElPrint)}
        anchorEl={anchorElPrint}
        onClose={() => setAnchorElPrint(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="w-full">
          <p
            className={`px-8 py-2 border-b-2 ${
              selectedStock.length === 0
                ? "cursor-default bg-gray-300 text-white"
                : "cursor-pointer hover:bg-gray-100"
            }`}
            onClick={() => {
              if (selectedStock.length > 0) {
                setAnchorElPrint(null);
                setIsPrint(true);
                mutatePrint({
                  product_id: selectedStock,
                  is_warehouse: isWarehouse ? isWarehouse : false,
                });
              }
            }}
          >
            Stok Terpilih
          </p>
          <p
            className="px-8 py-2  cursor-pointer hover:bg-gray-100"
            onClick={() => {
              setAnchorElPrint(null);
              setIsPrint(true);
              mutatePrint({
                product_id: [],
                is_warehouse: isWarehouse ? isWarehouse : false,
              });
            }}
          >
            Semua Stok
          </p>
        </div>
      </Popover>

      <div className="hidden">
        <PrintCardStock
          printRef={printRef}
          dataArr={dataPrint && !isLoadingPrint ? dataPrint : [""]}
          dataLabel={dataLabel ? dataLabel : null}
        />
      </div>

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default CardStockNew;
