import { MenuItem, Select, TextField } from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import useDebounce from "../../hooks/useDebounce";
import { useEffect, useState } from "react";

const PagePagination = ({
  offset,
  setOffset,
  setOffsetMemory,
  limit,
  setLimit,
  total,
}) => {
  const [pageOffset, setPageOffset] = useState(offset);
  const debounce = useDebounce(pageOffset, 1000);

  useEffect(() => {
    if (setOffset && offset !== pageOffset)
      setOffset(pageOffset === 0 ? 1 : pageOffset);
    if (setOffsetMemory && offset !== pageOffset)
      setOffsetMemory(limit, pageOffset === 0 ? 1 : pageOffset);
  }, [debounce]);

  return (
    <div className="w-full flex justify-between text-[12px]">
      <div className="flex gap-2 items-center w-full">
        <p>Rows per page</p>
        <Select
          value={limit}
          onChange={(e) => {
            setLimit(e.target.value);
            if (setOffset) setOffset(1);
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
        <p className="italic">
          Showing {(offset - 1) * limit + 1}-
          {total < offset * limit ? total : offset * limit} of {total} Entries
        </p>
      </div>

      <div className="flex gap-2 items-center text-right w-full justify-end">
        <TextField
          sx={{
            width: "48px",
          }}
          inputProps={{
            style: {
              paddingTop: 14,
              paddingBottom: 14,
            },
          }}
          value={pageOffset}
          onChange={(e) => {
            if (!isNaN(Number(e.target.value))) {
              const newOffset =
                Number(e.target.value) > Math.ceil(total / limit)
                  ? Math.ceil(total / limit)
                  : Number(e.target.value);
              setPageOffset(newOffset);
            }
          }}
        />
        <p>of {Math.ceil(total / limit)}</p>
        <button
          type="button"
          disabled={offset === 1}
          className="rounded-md p-2 border-2 w-[36px] h-[36px] border-gray-300 cursor-pointer hover:bg-gray-100 disabled:bg-gray-300 disabled:cursor-default disabled:text-white"
          onClick={() =>
            setOffsetMemory
              ? setOffsetMemory(limit, offset - 1)
              : setOffset(offset - 1)
          }
        >
          <FaChevronLeft className="w-full h-full" />
        </button>

        <button
          type="button"
          disabled={offset === Math.ceil(total / limit)}
          className="rounded-md p-2 border-2 w-[36px] h-[36px] border-gray-300 cursor-pointer hover:bg-gray-100 disabled:bg-gray-300 disabled:cursor-default disabled:text-white"
          onClick={() =>
            setOffsetMemory
              ? setOffsetMemory(limit, offset + 1)
              : setOffset(offset + 1)
          }
        >
          <FaChevronRight className="w-full h-full" />
        </button>
      </div>
    </div>
  );
};

export default PagePagination;
