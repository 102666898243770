import { Box, CircularProgress, Modal } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { RxCross1 } from "react-icons/rx";
import moment from "moment";
import { useState } from "react";
import { getOMSDetail } from "../../../../services/oms";
import ModalDoctorConcoction from "./ModalDoctorConcoction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const ModalDetailOms = ({ open, setOpen }) => {
  const [openConcoction, setOpenConcoction] = useState(null);

  const handleClose = () => {
    setOpen(null); // set ID back to null
  };

  const getStatusString = (status) => {
    return status === 4
      ? "Pesanan Baru"
      : status === 6
      ? "Siap Dikirim"
      : status === 8
      ? "Selesai"
      : status === 9
      ? "Dibatalkan"
      : status === 10
      ? "Dalam Pengiriman"
      : "";
  };

  const {
    data: dataDetail,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["oms-detail", open],
    queryFn: () => getOMSDetail(open),
    refetchOnWindowFocus: false,
    enabled: open !== undefined && open !== null,
  });

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/2"
        >
          <div className="flex items-center px-8 py-4 w-full justify-between">
            <p className="text-[20px] font-bold">Detail Pesanan</p>
            <RxCross1
              className="cursor-pointer hover:text-red-500 w-fit"
              onClick={handleClose}
            />
          </div>
          <hr className="text-gray-400 border-t-[2px] border-solid" />
          <>
            {dataDetail && !isFetching ? (
              <>
                <div className="px-8 py-4 w-full">
                  <p
                    className={`text-white py-1 px-4 font-bold rounded-md mb-2 w-fit ${
                      dataDetail.status === 4 || dataDetail.status === 10
                        ? "bg-[#FFB30F]"
                        : dataDetail.status === 8
                        ? "bg-green-500"
                        : dataDetail.status === 9
                        ? "bg-red-500"
                        : "bg-blue-300"
                    }`}
                  >
                    {getStatusString(dataDetail.status)}
                  </p>
                  <div className="flex justify-between items-center">
                    <p>No. Invoice </p>
                    <p className="text-blue-500">{dataDetail.invoice_number}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Nama Pelanggan </p>
                    <p>
                      {dataDetail.buyer_name} Via{" "}
                      <span className="text-amber-500">
                        {dataDetail.platform}
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Tanggal Pembelian</p>
                    <p>
                      {moment(dataDetail.purchase_date).format("DD MMMM YYYY")}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Nomor Handphone</p>
                    <p>{dataDetail.phone_number}</p>
                  </div>
                </div>
                <hr className="text-gray-400 border-t-[2px] border-solid" />
                <div className="px-8 py-4 w-full">
                  <p className="font-bold text-[14px] mb-2">Detail Produk</p>
                  {dataDetail.product?.map((item, i) => (
                    <div
                      className="flex gap-4 border-2 rounded-md p-2 mb-4 items-center"
                      key={i}
                    >
                      <div className="w-[120px] h-[120px] pl-4">
                        <img
                          src={item.image[0]}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className="w-full border-r-2">
                        <p className="text-[16px] font-semibold mb-2">
                          {item.product_name}
                        </p>
                        <p>Jumlah: {item.quantity}</p>
                        <p className="text-[14px] font-semibold mb-2">
                          {toIDR(item.price)}
                        </p>
                        {item.is_okt ? (
                          <button
                            type="button"
                            className="py-1 px-4 border-2 border-blue-500 text-blue-500 rounded-md w-fit hover:bg-gray-100"
                            onClick={() => setOpenConcoction(open)}
                          >
                            Lihat Resep Dokter
                          </button>
                        ) : null}
                      </div>
                      <div className="w-fit whitespace-nowrap text-center pr-4">
                        <p>Total Belanja</p>
                        <p className="font-bold text-[14px]">
                          {toIDR(item.total_price)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="text-gray-400 border-t-[2px] border-solid" />
                <div className="px-8 py-4 w-full">
                  <p className="font-bold text-[14px] mb-2">Info Pengiriman</p>
                  <div className="flex items-center gap-4 w-full">
                    <p className="w-1/3">No. Resi</p>
                    <p className="w-fit">:</p>
                    <p className="w-full">
                      {dataDetail.shipment.tracking_number === ""
                        ? "-"
                        : dataDetail.shipment.tracking_number}
                    </p>
                  </div>
                  <div className="flex items-center gap-4 w-full">
                    <p className="w-1/3">Kurir</p>
                    <p className="w-fit">:</p>
                    <p className="w-full">{dataDetail.shipment.courier}</p>
                  </div>
                  <div className="flex items-center gap-4 w-full">
                    <p className="w-1/3">Alamat</p>
                    <p className="w-fit">:</p>
                    <p className="text-wrap w-full">
                      {dataDetail.shipment.shipment_address}
                    </p>
                  </div>
                  <div className="flex items-center gap-4 w-full">
                    <p className="w-1/3">Nama Penerima</p>
                    <p className="w-fit">:</p>
                    <p className="text-wrap w-full">
                      {dataDetail.shipment.receipt_name}
                    </p>
                  </div>
                </div>
                <hr className="text-gray-400 border-t-[2px] border-solid" />
                <div className="px-8 py-4 w-full">
                  <p className="font-bold text-[14px] mb-2">
                    Rincian Pembayaran
                  </p>
                  <div className="flex justify-between items-center">
                    <p>Metode Pembayaran</p>
                    <p>
                      {dataDetail.payment.payment_name === ""
                        ? "-"
                        : dataDetail.payment.payment_name}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Subtotal untuk produk</p>
                    <p>{toIDR(dataDetail.payment.product_sub_total)}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Ongkos Kirim</p>
                    <p>{toIDR(dataDetail.payment.shipment_fee)}</p>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <p>Diskon</p>
                    <p>-{toIDR(dataDetail.payment.discount)}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="font-bold text-[14px] mb-2">
                      Total Pembayaran
                    </p>
                    <p className="font-bold text-[#FFB30F]">
                      {toIDR(dataDetail.payment.total_payment)}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className="w-full px-8 py-4 flex justify-center items-center text-red-500">
                {isFetching ? (
                  <CircularProgress size={20} />
                ) : error ? (
                  error.id
                ) : (
                  "Tidak ada data"
                )}
              </div>
            )}
          </>
        </Box>
      </Modal>
      <ModalDoctorConcoction
        open={openConcoction}
        setOpen={setOpenConcoction}
      />
    </div>
  );
};

export default ModalDetailOms;
