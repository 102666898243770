import { useQuery } from "@tanstack/react-query";
import { getDetailReturPOS } from "../../../services/billingAPI";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import moment from "moment";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const ReturDetailSellPOS = () => {
  const { id } = useParams();

  const { data, isFetching } = useQuery({
    queryKey: ["detail-retur-pos", id],
    queryFn: () => getDetailReturPOS(id),
    refetchOnWindowFocus: false,
  });

  return (
    <div className="text-[12px]">
      <div className="w-full flex items-start gap-4 font-semibold mb-4">
        <div className="w-full">
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">No. Transaksi</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                data.transaction_number
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">Tanggal Transaksi</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                moment(data.created_at).format("DD MMMM YYYY")
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">Member</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                data.member_name === "" ? (
                  "-"
                ) : (
                  data.member_name
                )
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
        </div>
        <div className="w-full">
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">Sales</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                data.sales_name === "" ? (
                  "-"
                ) : (
                  data.sales_name
                )
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="w-1/2">Metode Pembayaran</p>
            <p>: </p>
            <p>
              {data && !isFetching ? (
                data.payment_method
              ) : isFetching ? (
                <CircularProgress size={20} />
              ) : null}
            </p>
          </div>
        </div>
        <div className="w-1/2" />
      </div>

      <div className="flex w-full mb-4">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">Produk</th>
                <th className="p-2 text-center whitespace-nowrap">Batch No</th>
                <th className="p-2 text-center whitespace-nowrap">Kemasan</th>
                <th className="p-2 text-center whitespace-nowrap">Qty</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Harga Satuan
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Total Harga
                </th>
              </thead>
              <tbody className="border">
                {data &&
                !isFetching &&
                data.product &&
                data.product.length > 0 ? (
                  data.product.map((item, i) => (
                    <tr className="border" key={i}>
                      <td className="p-2 text-center">{i + 1}</td>
                      <td className="p-2 text-center">{item.product_name}</td>
                      <td className="p-2 text-center">{item.batch_number}</td>
                      <td className="p-2 text-center">{item.packaging}</td>
                      <td className="p-2 text-center">{item.quantity}</td>
                      <td className="p-2 text-center">
                        {toIDR(item.unit_price)}
                      </td>
                      <td className="p-2 text-center">
                        {toIDR(item.total_price)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border">
                    <td className="p-2 text-center border" colSpan={7}>
                      {isFetching ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada produk"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {data && !isFetching ? (
        <div className="w-full flex justify-end mb-8">
          <div className="w-1/3">
            <div className="mb-2">
              <div className="flex items-center gap-2">
                <p className="w-1/3">Subtotal</p>
                <p>:</p>
                <p>{toIDR(data.sub_total)}</p>
              </div>
            </div>
            <div className="mb-2">
              <div className="flex items-center gap-2">
                <p className="w-1/3">Biaya Lain-lain</p>
                <p>:</p>
                <p>{toIDR(data.other_cost)}</p>
              </div>
            </div>
            <div className="mb-2">
              <div className="flex items-center gap-2">
                <p className="w-1/3">Diskon</p>
                <p>:</p>
                <p>{toIDR(data.discount)}</p>
              </div>
            </div>
            <div className="mb-2 text-blue-500 bg-gray-100 rounded-md p-2 font-bold">
              <div className="flex items-center gap-2">
                <p className="w-1/3">Total Pembayaran</p>
                <p>:</p>
                <p>{toIDR(data.total_payment)}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {data && data.status === 2 ? (
        <>
          <p className="font-bold text-[14px] mb-4">Barang Retur</p>

          <div className="flex w-full mb-4">
            <div className="w-full">
              <div className="block overflow-x-auto w-full rounded-md">
                <table className="w-full overflow-x-auto rounded-md border-collapse">
                  <thead className="bg-blue-500 text-white">
                    <th className="p-2 text-center">No</th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Produk
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Kemasan
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">Qty</th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Harga Satuan
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Total Retur
                    </th>
                  </thead>
                  <tbody className="border">
                    {data &&
                    !isFetching &&
                    data.product_retur &&
                    data.product_retur.length > 0 ? (
                      data.product_retur.map((item, i) => (
                        <tr className="border" key={i}>
                          <td className="p-2 text-center">{i + 1}</td>
                          <td className="p-2 text-center">
                            {item.product_name}
                          </td>
                          <td className="p-2 text-center">{item.packaging}</td>
                          <td className="p-2 text-center">{item.quantity}</td>
                          <td className="p-2 text-center">
                            {toIDR(item.unit_price)}
                          </td>
                          <td className="p-2 text-center">
                            {toIDR(item.total_price)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td className="p-2 text-center border" colSpan={7}>
                          {isFetching ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Tidak ada barang retur"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {data &&
          !isFetching &&
          data.product_retur &&
          data.product_retur.length > 0 ? (
            <div className="w-full flex justify-end mb-8">
              <div className="w-1/3">
                <div className="mb-2 text-amber-500 bg-gray-100 rounded-md p-2 font-bold">
                  <div className="flex items-center gap-2">
                    <p className="w-1/3">Total Retur</p>
                    <p>:</p>
                    <p>{toIDR(data.total_retur)}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default ReturDetailSellPOS;
