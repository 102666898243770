import { Box, CircularProgress, MenuItem, Modal, Select } from "@mui/material";
import { useState } from "react";
import { MdWarningAmber } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCancelReason, rejectOrder } from "../../../../services/oms";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalRejectOrder = ({ open, setOpen, refetch, body, closeParent }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [cancelReason, setCancelReason] = useState(0);

  const handleClose = () => {
    setCancelReason(0);
    setErrorMessage("");
    setOpen(false);
  };

  const { data: dataReason, isFetching } = useQuery({
    queryKey: ["cancel-reason"],
    queryFn: () => getCancelReason(),
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateReject, isLoading } = useMutation({
    mutationFn: rejectOrder,
    onSuccess: () => {
      setSuccessMessage("Pesanan berhasil ditolak");
      refetch();
      handleClose();
      closeParent();
    },
    onError: (err) => setErrorMessage(err.id),
  });

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/2"
        >
          <div className="px-8 py-4">
            <div className="w-full flex items-center justify-between mb-4">
              <div
                className={`w-[56px] h-[56px] rounded-full p-[8px] flex items-center justify-center bg-red-100`}
              >
                <div
                  className={`w-full h-full rounded-full p-[8px] flex items-center justify-center bg-red-300`}
                >
                  <MdWarningAmber className={`w-full h-full text-red-700`} />
                </div>
              </div>
              <RxCross1
                className="cursor-pointer hover:text-red-500 w-fit"
                onClick={handleClose}
              />
            </div>
            <div className="mb-4">
              <p className="mb-2 font-bold text-[18px]">Tolak Pesanan</p>
              <p className="mb-4">Apa anda yakin ingin menolak pesanan ini?</p>

              <div className="flex items-center gap-4 mb-2">
                <p className="whitespace-nowrap font-bold">Alasan Penolakan</p>
                <Select
                  sx={{ width: "100%" }}
                  value={cancelReason}
                  onChange={(e) => setCancelReason(e.target.value)}
                >
                  <MenuItem value={0} disabled sx={{ display: "none" }}>
                    Pilih alasan penolakan
                  </MenuItem>
                  {dataReason && !isFetching && body
                    ? dataReason
                        .filter(
                          (item) => item.partner_code === body.partner_code
                        )
                        .map((item, i) => (
                          <MenuItem value={item.cancellation_id}>
                            {item.description}
                          </MenuItem>
                        ))
                    : null}
                </Select>
              </div>
            </div>
            <p className="text-red-500 mb-4">{errorMessage}</p>
            <button
              type="button"
              className="w-full p-2 bg-red-500 text-white hover:bg-gray-300 rounded-md disabled:bg-gray-300"
              onClick={() => {
                mutateReject({ ...body, cancel_reason_id: cancelReason });
              }}
              disabled={isLoading || cancelReason === 0}
            >
              {isLoading ? <CircularProgress size={15} /> : "Tolak"}
            </button>
          </div>
        </Box>
      </Modal>
      <ErrorPopup
        errorMessage={successMessage}
        setErrorMessage={setSuccessMessage}
      />
    </div>
  );
};

export default ModalRejectOrder;
