import React from "react";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../components/constants/muiTheme";
import OMSSettings from "../../components/screen/oms/OMSSettings";

const OrderManagementSystemSettingsPage = () => {
  return (
    <PageTemplate
      header="Order Management System Settings"
      mainComponent={
        <ThemeProvider theme={theme}>
          <OMSSettings />
        </ThemeProvider>
      }
      pageRole={2}
    />
  );
};

export default HOC(OrderManagementSystemSettingsPage);
