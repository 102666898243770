import { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Pagination,
  CircularProgress,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { AiOutlineExport } from "react-icons/ai";
import moment from "moment";

import {
  fetchReportingProduct,
  getListProductReporting,
} from "../../../../services/ReportingApi";
import { getMultipleOutletOption } from "../../../../services/importProductApi";
import ReportingProductModal from "./ReportingProductModal";
import useDebounce from "../../../hooks/useDebounce";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const toDate = (date) => moment(date).format("YYYY-MM-DD");

const ReportingProductPurchase = (props) => {
  const { FETCH_LIMIT, period } = props;
  const [nameBuffer, setNameBuffer] = useState("Pilih Produk");
  const [reqBody, setReqBody] = useState({
    limit: FETCH_LIMIT,
    offset: 1,
    company_id: [],
    payment_method: 0,
    period: -1,
    start_date: "",
    end_date: "",
    product_name: "",
    type: false,
  });
  const [param, setParam] = useState({
    limit: 20,
    offset: 1,
    name: "",
    type: false,
  });

  const [isModalListProductOpen, setIsModalListProductOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [periodBuffer, setPeriodBuffer] = useState(-1);

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "reporting_product_penjualan.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const {
    mutate: mutateReportingProduct,
    data: reportingProduct,
    isLoading: isReportingProductLoading,
  } = useMutation({
    mutationFn: fetchReportingProduct,
    onError: (err) => setErrorMessage(err.message.id),
  });

  const {
    mutate: mutateExportReportingProduct,
    isLoading: isExportingExcelLoading,
  } = useMutation({
    mutationFn: fetchReportingProduct,
    onSuccess: (data) => handleXlsxData(data.excel_url),
  });

  const debounce = useDebounce(param.name, 500);

  const {
    data: listProductReporting,
    isLoading: isListProductReportingLoading,
    mutate: mutateGetListProduct,
  } = useMutation({
    mutationFn: getListProductReporting,
  });

  const { data: multipleOutletOption } = useQuery({
    queryKey: ["outlet-option", param],
    queryFn: () => getMultipleOutletOption(),
    refetchOnWindowFocus: false,
  });

  const pageCount = useMemo(() => {
    if (reportingProduct && reportingProduct !== undefined) {
      return Math.ceil(reportingProduct.count / FETCH_LIMIT);
    } else {
      return 0;
    }
  }, [reportingProduct]);

  useEffect(() => {
    if (
      reqBody.payment_method !== -1 &&
      reqBody.company_id.length > 0 &&
      periodBuffer >= 0 &&
      reqBody.product_name.length !== 0
    ) {
      mutateReportingProduct({
        ...reqBody,
        company_id: reqBody.company_id.map((data) => data.company_id),
        start_date:
          toDate(reqBody.start_date) !== "Invalid date"
            ? toDate(reqBody.start_date)
            : "",
        end_date:
          toDate(reqBody.end_date) !== "Invalid date"
            ? toDate(reqBody.end_date)
            : "",
      });
    }
  }, [reqBody, mutateReportingProduct]);

  useEffect(() => {
    if (reqBody.company_id.length > 0 && isModalListProductOpen) {
      mutateGetListProduct({
        ...param,
        company_id: reqBody.company_id.map((data) => data.company_id),
      });
    }
  }, [
    param,
    reqBody.company_id,
    isModalListProductOpen,
    debounce,
    mutateGetListProduct,
  ]);

  return (
    <div>
      <LoadingBackdrop isLoading={isExportingExcelLoading} />
      <div className="flex justify-end">
        <button
          className="bg-green-700 disabled:bg-gray-500 rounded-md px-5 py-3 text-white font-bold mt-3 flex items-center"
          disabled={
            reportingProduct === undefined || reportingProduct?.count === 0
          }
          onClick={() => {
            if (
              reqBody.payment_method !== -1 &&
              reqBody.company_id.length > 0 &&
              reqBody.period >= 0 &&
              reqBody.product_name.length !== 0
            ) {
              mutateExportReportingProduct({
                ...reqBody,
                company_id: reqBody.company_id.map((data) => data.company_id),
                limit: 0,
                offset: 0,
              });
            }
          }}
        >
          <AiOutlineExport className="text-[30px] mr-3" />
          <p>Export Laporan</p>
        </button>
      </div>
      <div className="mb-3 flex mt-3">
        <FormControl sx={{ marginRight: 3, width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Pilih Outlet</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Pilih Outlet"
            value={reqBody.company_id}
            onChange={(e) => {
              if (e.target.value === 0) {
                setReqBody((prevValue) => ({
                  ...prevValue,
                  company_id: multipleOutletOption?.map((data) => ({
                    company_id: data.company_id,
                    company_name: data.company_name,
                  })),
                }));
              } else {
                setReqBody((prevValue) => {
                  if (
                    prevValue.company_id
                      .map((data) => data.company_id)
                      .some((data) => data === e.target.value.company_id)
                  ) {
                    return {
                      ...prevValue,
                      company_id: prevValue.company_id.filter(
                        (data) => data.company_id === e.target.value.company_id
                      ),
                    };
                  } else {
                    return {
                      ...prevValue,
                      company_id: [...prevValue.company_id, e.target.value],
                    };
                  }
                });
              }
            }}
            renderValue={(selected) =>
              selected.map((data) => data.company_name).join(", ")
            }
          >
            <MenuItem value={[]} sx={{ display: "none" }}></MenuItem>
            <MenuItem value={0}>Semua Outlet</MenuItem>
            {multipleOutletOption && multipleOutletOption !== undefined ? (
              multipleOutletOption.map((data, index) => (
                <MenuItem
                  key={index}
                  value={{
                    company_id: data.company_id,
                    company_name: data.company_name,
                  }}
                >
                  {data.company_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={0} sx={{ display: "none" }} disabled></MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl sx={{ marginRight: 3, width: "100%" }}>
          <InputLabel id="demo-simple-select-label">
            Pilih Metode Penjualan
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Pilih Metode Penjualan"
            value={reqBody.payment_method}
            onChange={(e) => {
              setReqBody((prevValue) => ({
                ...prevValue,
                payment_method: e.target.value,
              }));
            }}
          >
            <MenuItem value={-1} sx={{ display: "none" }}></MenuItem>
            <MenuItem value={0}>Semua</MenuItem>
            <MenuItem value={1}>Purchase</MenuItem>
            <MenuItem value={2}>Purchase Manual</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ marginRight: 3, width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Pilih Periode</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Pilih Periode"
            value={periodBuffer}
            onChange={(e) => {
              if (e.target.value === 10 || e.target.value === 9) {
                return;
              }
              setPeriodBuffer(e.target.value);
              setReqBody((prevValue) => ({
                ...prevValue,
                period: e.target.value,
                start_date: "",
                end_date: "",
              }));
            }}
          >
            <MenuItem value={-1} sx={{ display: "none" }}></MenuItem>
            <MenuItem value={10} disabled sx={{ display: "none" }}>
              {toDate(reqBody.start_date) + " - " + toDate(reqBody.end_date)}
            </MenuItem>

            {period.map((data, index) => (
              <MenuItem
                value={data.value}
                key={index}
                onClick={() => {
                  if (data.value === 9) {
                    setIsDateModalOpen(true);
                  }
                }}
              >
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <button
          className="rounded-md border-[1.5px] border-black p-3 text-center w-full"
          onClick={() => setIsModalListProductOpen(true)}
          disabled={reqBody.company_id.length === 0}
          onMouseOver={() => {
            setNameBuffer("Pilih Produk");
          }}
          onMouseLeave={() => {
            if (reqBody.product_name.length > 0) {
              setNameBuffer(reqBody.product_name);
            }
          }}
        >
          {nameBuffer}
        </button>
      </div>

      {isReportingProductLoading ? (
        <div className="flex justify-center mt-3">
          <CircularProgress />
        </div>
      ) : (
        <>
          {reportingProduct && reportingProduct !== undefined ? (
            <>
              {reportingProduct.data !== null ? (
                <TableContainer component={Paper}>
                  <Table sx={{ width: "100%" }} aria-label="simple-table">
                    <TableHead className="text-[10px]">
                      <TableRow
                        sx={{
                          backgroundColor: "#007AF1",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Tanggal Penjualan
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Outlet
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Nama Produk
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Kategori
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Metode Penjualan
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Kuantitas
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Total Penjualan
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportingProduct.data.map((data, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {data.date}
                          </TableCell>
                          <TableCell align="center">{data.outlet}</TableCell>
                          <TableCell align="center">
                            {data.name_product}
                          </TableCell>
                          <TableCell align="center">{data.category}</TableCell>
                          <TableCell align="center">{data.method}</TableCell>
                          <TableCell align="center">{data.quantity}</TableCell>
                          <TableCell align="center">
                            {data.total.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="flex justify-center items-center mt-5">
                  <p>Tidak ada Produk!</p>
                </div>
              )}
              {reportingProduct.data !== null ? (
                <div className="flex justify-center mt-[10px]">
                  <Pagination
                    count={pageCount}
                    page={reqBody.offset}
                    onChange={(_, value) =>
                      setReqBody((prevValue) => ({
                        ...prevValue,
                        offset: value,
                      }))
                    }
                    shape="rounded"
                    size="large"
                    color="primary"
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </>
      )}
      <Dialog
        open={isModalListProductOpen}
        onClose={() => setIsModalListProductOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Pilih Produk</DialogTitle>
        <DialogContent>
          <ReportingProductModal
            param={param}
            setParam={setParam}
            isModalListProductOpen={isModalListProductOpen}
            setIsModalListProductOpen={setIsModalListProductOpen}
            setNameBuffer={setNameBuffer}
            setReqBody={setReqBody}
            listProductReporting={listProductReporting}
            isListProductReportingLoading={isListProductReportingLoading}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDateModalOpen}
        onClose={() => {
          setIsDateModalOpen(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              selected={reqBody.start_date}
              onChange={(dates) => {
                const [start, end] = dates;
                setReqBody((prevValue) => ({
                  ...prevValue,
                  start_date: start,
                  end_date: end,
                }));
              }}
              startDate={reqBody.start_date}
              endDate={reqBody.end_date}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold"
            onClick={() => {
              setIsDateModalOpen(false);
              setPeriodBuffer(10);
              setReqBody((prevValue) => ({
                ...prevValue,
                period: 9,
                start_date: reqBody.start_date,
                end_date: reqBody.end_date,
              }));
            }}
          >
            ok
          </button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

export default ReportingProductPurchase;
