import { Box, CircularProgress, Modal } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { MdWarningAmber } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { acceptOrder } from "../../../../services/oms";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalReceiveOrder = ({ open, setOpen, refetch, body, closeParent }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = () => {
    setErrorMessage("");
    setOpen(false);
  };

  const { mutate: mutateAccept, isLoading } = useMutation({
    mutationFn: acceptOrder,
    onSuccess: () => {
      setSuccessMessage("Pesanan berhasil diterima");
      refetch();
      handleClose();
      closeParent();
    },
    onError: (err) => setErrorMessage(err.id),
  });

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/2"
        >
          <div className="px-8 py-4">
            <div className="w-full flex items-center justify-between mb-4">
              <div
                className={`w-[56px] h-[56px] rounded-full p-[8px] flex items-center justify-center bg-blue-100`}
              >
                <div
                  className={`w-full h-full rounded-full p-[8px] flex items-center justify-center bg-blue-300`}
                >
                  <MdWarningAmber className={`w-full h-full text-blue-700`} />
                </div>
              </div>
              <RxCross1
                className="cursor-pointer hover:text-red-500 w-fit"
                onClick={handleClose}
              />
            </div>
            <div className="mb-4">
              <p className="mb-2 font-bold text-[18px]">Terima Pesanan</p>
              <p>Apa anda yakin ingin menerima pesanan ini?</p>
            </div>

            <p className="text-red-500 mb-4">{errorMessage}</p>

            <button
              type="button"
              className="w-full p-2 bg-blue-500 text-white hover:bg-gray-300 rounded-md disabled:bg-gray-300"
              onClick={() => {
                mutateAccept(body);
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={15} /> : "Terima"}
            </button>
          </div>
        </Box>
      </Modal>
      <ErrorPopup
        errorMessage={successMessage}
        setErrorMessage={setSuccessMessage}
      />
    </div>
  );
};

export default ModalReceiveOrder;
