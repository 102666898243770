import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Pagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import moment from "moment";

import {
  exportXlsxCardInventorySum,
  fetchSummaryInventories,
} from "../../../../services/InventoryAPI";
import { getOutletList } from "../../../../services/outletApi";
import useDebounce from "../../../hooks/useDebounce";
import { IGNORE_TYPE } from "../../../constants/constant";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import DOICard from "./DoiCard";
import BreadcrumbInventory from "../BreadcrumbInventory";
import { Search } from "@mui/icons-material";

const FETCH_LIMIT = 10;

const toDate = (date) => moment(date).format("DD-MM-YYYY");
const toApiDate = (date) => moment(date).format("YYYY-MM-DD");

const InventorySummary = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const companyId = localStorage.getItem("company_id");
  const titleId = localStorage.getItem("title");

  const [resBody, setResBody] = useState({
    warehouse: isWarehouse ? isWarehouse : false,
    company_id: parseInt(companyId, 10),
    offset: 1,
    limit: FETCH_LIMIT,
    start_date: "",
    end_date: "",
    sort: 1,
    period: 3,
    search: "",
  });
  const [doi, setDoi] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
    isOpen: false,
    period: 3,
  });

  const debounce = useDebounce(doi, 500);
  const debounceSearch = useDebounce(resBody.search, 500);

  const handleExport = () => {
    mutateXlsx({
      company_id: resBody.company_id,
      period: resBody.period,
      start_date: resBody.start_date ? toApiDate(resBody.start_date) : "",
      end_date: resBody.end_date ? toApiDate(resBody.end_date) : "",
      doi: doi,
      warehouse: isWarehouse ? isWarehouse : false,
    });
  };

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "inventory-summary.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { mutate: mutateXlsx, isLoading: isLoadingXlsx } = useMutation({
    mutationFn: exportXlsxCardInventorySum,
    onSuccess: (data) => handleXlsxData(data),
    onError: (err) =>
      setErrorMessage(err.message.id ? err.message.id : err.message),
  });

  const { data: dataOutlet } = useQuery({
    queryFn: () => getOutletList(),
    queryKey: ["outlets"],
  });

  const {
    mutate: mutateSummaryInventories,
    data: summaryInventoriesData,
    isLoading: isSummaryInventoriesLoading,
  } = useMutation({
    mutationFn: fetchSummaryInventories,
  });

  const {
    mutate: mutateSummaryInventoryDoi,
    data: summaryInventoriesDoi,
    isLoading: isSummaryInventoriesDoiLoading,
  } = useMutation({
    mutationFn: fetchSummaryInventories,
  });

  const pageCount = useMemo(() => {
    if (summaryInventoriesData === undefined) return 0;
    else if (summaryInventoriesData.count === undefined) return 0;
    else return Math.ceil(summaryInventoriesData.count / FETCH_LIMIT);
  }, [summaryInventoriesData]);

  useEffect(() => {
    mutateSummaryInventories({
      ...resBody,
      doi: debounce,
    });
  }, [
    resBody.company_id,
    resBody.end_date,
    resBody.sort,
    resBody.period,
    resBody.offset,
    resBody.limit,
    debounce,
    debounceSearch,
    location.pathname,
  ]);

  useEffect(() => {
    mutateSummaryInventoryDoi({
      ...resBody,
      limit: 0,
      offset: 0,
      doi: debounce,
    });
  }, [
    resBody.company_id,
    resBody.end_date,
    resBody.sort,
    resBody.period,
    debounce,
    location.pathname,
  ]);

  const calcDoi30Days = useMemo(() => {
    return summaryInventoriesDoi?.data
      ?.filter((data) => data.doi >= 0 && data.doi < 30)
      ?.reduce((acc, curr) => {
        return acc + curr.inventory_value;
      }, 0);
  }, [summaryInventoriesDoi]);

  const calcDoi90Days = useMemo(() => {
    return summaryInventoriesDoi?.data
      ?.filter((data) => data.doi > 31 && data.doi < 90)
      ?.reduce((acc, curr) => {
        return acc + curr.inventory_value;
      }, 0);
  }, [summaryInventoriesDoi]);

  const calcDoi150Days = useMemo(() => {
    return summaryInventoriesDoi?.data
      ?.filter((data) => data.doi > 91 && data.doi < 150)
      ?.reduce((acc, curr) => {
        return acc + curr.inventory_value;
      }, 0);
  }, [summaryInventoriesDoi]);

  const calcDoi150Plus = useMemo(() => {
    return summaryInventoriesDoi?.data
      ?.filter((data) => data.doi > 150)
      ?.reduce((acc, curr) => {
        return acc + curr.inventory_value;
      }, 0);
  }, [summaryInventoriesDoi]);

  return (
    <div>
      <LoadingBackdrop isLoading={isLoadingXlsx} />
      <BreadcrumbInventory isWarehouse={isWarehouse} />
      <div className="mt-3 flex gap-3">
        <FormControl fullWidth>
          <InputLabel>Company</InputLabel>
          <Select
            sx={{ width: "100%" }}
            value={resBody.company_id}
            label="Company"
            disabled={!titleId || (titleId && Number(titleId) !== 1)}
            onChange={(e) => {
              setResBody((prevValue) => ({
                ...prevValue,
                company_id: parseInt(e.target.value, 10),
              }));
            }}
            renderValue={(selected) => (
              <p>
                {dataOutlet
                  ? dataOutlet.find((outlet) => outlet.company_id === selected)
                      .company_name
                  : ""}
              </p>
            )}
          >
            {dataOutlet
              ? dataOutlet.map((data) => (
                  <MenuItem
                    value={data.company_id}
                    key={data.company_id}
                    sx={{
                      display: "block",
                    }}
                  >
                    <p>{data.company_name}</p>
                    <p className="text-[15px]">{data.company_address}</p>
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Waktu</InputLabel>
          <Select
            sx={{ width: "100%" }}
            value={date.period}
            label="Waktu"
            onChange={(e) => {
              if (e.target.value === 7) {
                setDate((prevValue) => ({
                  ...prevValue,
                  period: e.target.value,
                  isOpen: true,
                }));
                return;
              }
              setDate((prevValue) => ({
                ...prevValue,
                period: e.target.value,
              }));
              setResBody((prevValue) => ({
                ...prevValue,
                period: e.target.value,
                start_date: "",
                end_date: "",
                offset: 1,
              }));
            }}
          >
            <MenuItem value={1}>Minggu Berjalan</MenuItem>
            <MenuItem value={2}>Minggu Lalu</MenuItem>
            <MenuItem value={3}>Bulan Berjalan</MenuItem>
            <MenuItem value={4}>Bulan Lalu</MenuItem>
            <MenuItem value={5}>Tahun Berjalan</MenuItem>
            <MenuItem value={6}>Tahun Lalu</MenuItem>
            <MenuItem value={7}>Pilih Tanggal</MenuItem>
            <MenuItem value={8} disabled sx={{ display: "none" }}>
              {toDate(resBody.start_date) + " - " + toDate(resBody.end_date)}
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Standard DOI"
          value={doi}
          onChange={(e) => {
            let numericValue = e.target.value;
            if (numericValue === "") {
              setDoi(0);
              return;
            }
            setDoi(parseInt(numericValue.replace(/[^0-9]/g, 0), 10));
          }}
        />
        <button
          className="rounded-md bg-green-400 w-full disabled:text-white disabled:bg-gray-300"
          disabled={isLoadingXlsx || isSummaryInventoriesLoading}
          onClick={handleExport}
        >
          Export
        </button>
      </div>
      <div className="flex gap-3 my-3">
        <DOICard
          interval={"0-30"}
          value={calcDoi30Days}
          data={summaryInventoriesDoi}
          isLoading={isSummaryInventoriesDoiLoading}
        />
        <DOICard
          interval={"31-90"}
          value={calcDoi90Days}
          data={summaryInventoriesDoi}
          isLoading={isSummaryInventoriesDoiLoading}
        />
        <DOICard
          interval={"91-150"}
          value={calcDoi150Days}
          data={summaryInventoriesDoi}
          isLoading={isSummaryInventoriesDoiLoading}
        />
        <DOICard
          interval={"> 150"}
          value={calcDoi150Plus}
          data={summaryInventoriesDoi}
          isLoading={isSummaryInventoriesDoiLoading}
        />
      </div>

      <div className="w-full my-4">
        <TextField
          fullWidth
          placeholder="Cari nama produk atau kategori"
          value={resBody.search}
          onChange={(e) =>
            setResBody((prevValue) => ({
              ...prevValue,
              search: e.target.value,
              offset: 1,
            }))
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div>
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }}>
            <TableHead
              sx={{
                backgroundColor: "#007AF1",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  SKU Name
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Beginning Stock
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Purchase/Restock
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Sales
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Transfer
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Adjustment
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Stock On Hands
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Average Sold/Day
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  DOI
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setResBody((prevValue) => ({
                      ...prevValue,
                      sort: resBody.sort === 1 ? 2 : 1,
                      offset: 1,
                    }));
                  }}
                >
                  <div className="w-full flex gap-2 items-center">
                    <p className="whitespace-nowrap text-nowrap">
                      Inventory Value
                    </p>
                    {resBody.sort === 1 ? (
                      <FaChevronUp className="text-[20px]" />
                    ) : (
                      <FaChevronDown className="text-[20px]" />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Forecast Plan
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summaryInventoriesData &&
              !isSummaryInventoriesLoading &&
              summaryInventoriesData.data ? (
                summaryInventoriesData.data.map((data) => (
                  <TableRow
                    key={data.product_id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      textAlign: "center",
                    }}
                  >
                    <TableCell component="th" scope="category">
                      <p
                        className="text-blue-500 hover:underline cursor-pointer"
                        onClick={() => {
                          navigate(
                            `${
                              isWarehouse ? "/central-warehouse" : ""
                            }/inventory/summary/${data.product_id}/${
                              resBody.company_id
                            }`
                          );
                        }}
                      >
                        {data.product_name}
                      </p>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {data.category_names}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {data.beginning_stock}{" "}
                      {IGNORE_TYPE.includes(data.type_unit)
                        ? data.type_strip
                        : data.type_unit}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {data.purchase_stock}{" "}
                      {IGNORE_TYPE.includes(data.type_unit)
                        ? data.type_strip
                        : data.type_unit}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {data.sales}{" "}
                      {IGNORE_TYPE.includes(data.type_unit)
                        ? data.type_strip
                        : data.type_unit}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>0</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {data.adjustment_plus - data.adjustment_minus}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {data.beginning_stock +
                        data.purchase_stock -
                        data.sales +
                        (data.adjustment_plus - data.adjustment_minus)}{" "}
                      {IGNORE_TYPE.includes(data.type_unit)
                        ? data.type_strip
                        : data.type_unit}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {data.average_sold.toFixed(2)} sold/day
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                    >
                      {data.doi.toFixed(2)} days
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {data.inventory_value.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {data.forecast_plan.toFixed(2)}{" "}
                      {IGNORE_TYPE.includes(data.type_unit)
                        ? data.type_strip
                        : data.type_unit}
                    </TableCell>
                  </TableRow>
                ))
              ) : isSummaryInventoriesLoading ? (
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }} colSpan={12}>
                    <CircularProgress size={20} />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }} colSpan={12}>
                    Tidak ada summary
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {summaryInventoriesData && summaryInventoriesData !== undefined ? (
        <div className="flex justify-center mt-[10px]">
          <Pagination
            count={pageCount}
            page={resBody.offset}
            onChange={(_, value) => {
              setResBody((prevValue) => ({
                ...prevValue,
                offset: value,
              }));
            }}
            shape="rounded"
            size="large"
            color="primary"
          />
        </div>
      ) : null}
      <Dialog
        open={date.isOpen}
        onClose={() => {
          setDate((prevValue) => ({
            ...prevValue,
            isOpen: false,
            startDate: null,
            endDate: null,
          }));
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              onChange={(dates) => {
                const [start, end] = dates;
                setDate((prevValue) => ({
                  ...prevValue,
                  startDate: start,
                  endDate: end,
                }));
              }}
              startDate={date.startDate}
              endDate={date.endDate}
              filterDate={(dateCompare) => {
                if (!date.startDate) {
                  return true;
                }

                const dayAfterStartDate = new Date(date.startDate);
                dayAfterStartDate.setDate(date.startDate.getDate() + 1);

                const twoDaysAfterStartDate = new Date(date.startDate);
                twoDaysAfterStartDate.setDate(date.startDate.getDate() + 2);

                return (
                  dateCompare < dayAfterStartDate ||
                  dateCompare > twoDaysAfterStartDate
                );
              }}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold"
            onClick={() => {
              setDate((prevValue) => ({
                ...prevValue,
                isOpen: false,
                period: 8,
              }));
              setResBody((prevValue) => ({
                ...prevValue,
                period: 7,
                start_date: toApiDate(date.startDate),
                end_date: toApiDate(date.endDate),
              }));
            }}
          >
            ok
          </button>
        </DialogContent>
      </Dialog>

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default InventorySummary;
